<template>
    <div class="wrapper" @click="(e) => e.stopPropagation()">
        <div class="exclude-products-modal">
            <h3 class="head">Search and Add Products</h3>
            <div class="close" @click="closeModal">
                <img :src="closeImg" />
            </div>
            <div class="search">
                <NitrozenInput class="search-input" placeholder="Search By Product Name or Item Code" :value="searchText"
                    :showSearchIcon=true @input="searchProducts" />
            </div>
            <loader v-if="loader"> </loader>
            <div v-else class="body">
                <div v-for="product in products" :key="product.uid" class="product">
                    <div class="checkbox">
                        <NitrozenCheckBox :checkboxValue="product.uid.toString()" :value="tempValues ?? []" @change="onChange" />
                    </div>
                    <div class="details">
                        <div class="left">
                            <img :src="product.media[0]?.url" />
                        </div>
                        <div class="right">
                            <p class="name">{{ product.name }}</p>
                            <a :href="productLink(product.slug)" target="_blank" class="link ">
                                <span> {{
                                    productLink(product.slug).length
                                    <= 40 ? productLink(product.slug) : productLink(product.slug).slice(0, 40) + '...  ' }}
                                        </span><img :src="linkImg" />
                            </a>
                            <p class="item-code">Item Code: {{ product.item_code }}</p>
                        </div>
                    </div>
                </div>
                <NitrozenPagination class="pagination" v-model="paginationConfig" name="Products"
                @change="getProducts" :pageSizeOptions="pageSizeOptions"></NitrozenPagination>
            </div>
            <div class="foot">
                <NitrozenButton v-flatBtn size="small" theme="secondary" @click="onClickSave">Save</NitrozenButton>
            </div>
        </div>
    </div>
</template>
<script>
import { NitrozenButton, NitrozenCheckBox, NitrozenInput, NitrozenPagination, flatBtn } from '@gofynd/nitrozen-vue';
import close from "../../../assets/svgs/close.svg";
import link from "../../../assets/svgs/link.svg";
import { mapState } from 'vuex';

export default {
    name: "exclude-products-modal",
    components: {
        NitrozenInput,
        NitrozenButton,
        NitrozenPagination,
        NitrozenCheckBox
    },
    directives: {
        flatBtn
    },
    props: {
        values: {
            type: Array,
            required: true,
        },
        closeModal: {
            type: Function,
            required: true
        },
        getItems: {
            type: String
        },
        onSave: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            searchText: '',
            paginationConfig: {
                limit: 10,
                current: 0,
                total: 0,
            },
            pageSizeOptions: [10, 20, 50],
            products: [],
            tempValues: this.values ?? [],
            loader: false,
            timeOut: null,
        }
    },
    computed: {
        closeImg() {
            return close;
        },
        ...mapState(["domain"]),
        linkImg() {
            return link;
        }
    },
    methods: {
        async getProducts() {
            this.loader = true;
            const { data } = await this.getItems({
                pageSize: this.paginationConfig.limit,
                pageNo: this.paginationConfig.current,
                searchText: this.searchText,
            })
            this.products = data.items;
            this.paginationConfig.current = parseInt(data.page.current);
            this.paginationConfig.total = parseInt(data.page.item_total);
            this.loader = false;
        },
        //Debouncing
        searchProducts(value) {
            this.searchText = value;
            this.paginationConfig.current = 1;
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(() => {
                this.getProducts();
            }, 1500);

        },

        onChange(event) {
            this.tempValues = event;
        },
        onClickSave(e) {
            try {
                this.onSave(this.tempValues);
                this.closeModal(e);
                this.$snackbar.global.showSuccess("Saved Products To be Excluded");
            }
            catch (er) {
                this.$snackbar.global.showError("Issue While Saving");
            }
        },
        productLink(slug) {
            return `https://${this.domain}/product/${slug}`;
        }
    },
    mounted() {
        this.getProducts();
    },
}
</script>
<style scoped lang="less">
@import '../../../helper/colors.less';
@import '../../../helper/utils.less';

.wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.70);
    z-index: 32;

    h3 {
        color: @textPrimary;
        font-family: Inter;
        .pxToRem(font-size, 18);
        font-style: normal;
        font-weight: 600;
        .pxToRem(line-height, 24);
    }

    .exclude-products-modal {
        width: 35%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        background: white;
        .pxToRem(padding, 24);
        overflow: scroll;

        .head {}

        .close {
            position: absolute;
            .pxToRem(top, 25);
            .pxToRem(right, 20);
            cursor: pointer;
        }

        .search {
            margin: 24px -24px 0 -24px;
            .pxToRem(padding, 24);
            border-top: 1px solid #E1E1E1;

            .search-input {
                .pxToRem(padding, 12);
                background: #F3F3F3;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            gap: 12px;
            .product {
                border: 1px solid #E5E5E5;
                .pxToRem(border-radius, 4px);
                .pxToRem(padding, 12);
                display: flex;
                align-items: center;

                .checkbox {
                    width: 10%;
                    .pxToRem(margin-top, -18);
                    .pxToRem(margin-left, 16);
                    .pxToRem(margin-right, -16);
                }

                .details {
                    width: 90%;
                    display: flex;
                    .pxToRem(gap, 12);

                    .left {
                        .pxToRem(height, 60);
                        .pxToRem(width, 60);
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                            object-fit: scale-down;
                        }
                    }

                    .right {
                        .name {
                            color: black;
                            font-family: Inter;
                            .pxToRem(font-size, 14);
                            font-style: normal;
                            font-weight: 400;
                            .pxToRem(line-height, 18);
                        }

                        .link {
                            font-family: Inter;
                            color: #2E31BE;
                            .pxToRem(font-size, 12);
                            .pxToRem(line-height, 16);
                            align-items: center;
                            .pxToRem(gap, 4);
                            text-decoration: none;


                            img {
                                .pxToRem(height, 10);
                            }

                        }

                        .link:hover {
                            text-decoration: underline;
                        }

                        .item-code {
                            color: rgba(102, 102, 102, 0.90);
                            font-family: Inter;
                            .pxToRem(font-size, 12);
                            font-style: normal;
                            font-weight: 400;
                            .pxToRem(line-height, 16);
                        }
                    }
                }
            }
        }

        .pagination {
            .pxToRem(margin-top, 16);
        }

        .foot {
            display: flex;
            position: fixed;
            background: white;
            box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.05);
            flex-direction: row-reverse;
            // .pxToRem(bottom, 0);
            .pxToRem(padding, 16);
            .pxToRem(padding-right, 24);
            .pxToRem(padding-left, 24);
            // .pxToRem(margin-left, -24);
            // .pxToRem(margin-right, -24);
            bottom: 0;
            right: 0;
            width: 35%;
        }
    }
}
</style>
