<template>
  <div>
    <loader v-if="loading" :helperText="'Verifying subscription'"></loader>
  </div>
  
</template>
<script>
import MainService from "@/services/main-service";
import loader from "@/components/common/loader.vue";
import { setCompany } from "@/helper/utils";
export default {
  name: "subscription-status",
  components: {
    loader,
  },
  data() {
    return {
      loading: false,
      approved: false,
      subscription_id: null,
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (to.params.company_id) {
      setCompany(to.params.company_id);
    }
    next();
  },
  beforeMount() {
    this.approved = this.$route.query.approved;
    this.subscription_id = this.$route.query.subscription_id;
    this.checkChargStatus();
  },
  methods: {

    async checkChargStatus() {
      this.loading = true;
      try {
        await MainService.updateSubscriptionStatus(this.subscription_id)
          .then(({ data }) => {
            if (data.status === "active") {
              this.$router.push(`/company/${this.$route.params.company_id}`);
              this.$snackbar.global.showSuccess("Charge approved");
            } else {
              this.$snackbar.global.showError("Charge was declined");
              this.$router.push(
                `/company/${this.$route.params.company_id}/pricing`
              );
            }
          })
          .catch(() => {
            this.$router.push(`/company/${this.$route.params.company_id}/pricing`);
            // this.$snackbar.global.showError(
            //   "Failed to verify subscription status",
            //   3000
            // );
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>