<template>
  <div class="right">
    <div class="top">
      <div class="icons">
        <span class="icon" @click="selectResponsiveIcons('laptop')" :class="{ 'active': deviceType == 'laptop' }">
          <inline-svg class="arrow" :src="deviceType == 'laptop' ? 'laptop-blue' : 'laptop'"></inline-svg>
        </span>
        <span class="icon" @click="selectResponsiveIcons('mobile')" :class="{ 'active': deviceType == 'mobile' }">
          <inline-svg class="arrow" :src="deviceType == 'mobile' ? 'mobile-blue' : 'mobile'"></inline-svg>
        </span>
        <span class="pointer" @click="selectResponsiveIcons('tablet')" :class="{ 'active': deviceType == 'tablet' }">
          <inline-svg class="arrow" :src="deviceType == 'tablet' ? 'tablet-blue' : 'tablet'"></inline-svg>
        </span>
      </div>
      <div class="live-preview">
        <span class="live-preview">Live Preview</span>
      </div>
    </div>
    <div class="wrapper" :class="[deviceType]">
      <div id="html-render" v-html="htmlData"></div>
      <div class="unsubscribe">
      </div>
    </div>
  </div>
</template>

<script>
import inlineSvg from "@/components/common/inline-svg.vue";
import nunjucks from 'nunjucks';

export default {
  name: 'email-template-preview',
  props: ["limitedStock", "backStock"],

  components: {
    "inline-svg": inlineSvg,
  },
  data() {
    return {
      alertEmail: "<hey@gofynd.com>",
      switchHorizontalLayout: false,
      deviceType: "laptop",
      product: {
        media: [{
          // Use a URL instead of require as EMAIL troubling with ngrok domain.
          url: "https://cdn.tirabeauty.com/v2/billowing-snowflake-434234/tira-p/wrkr/products/pictures/item/free/original/1113506/0f1VG-YTUu-6902395472636_1.jpg?dpr=1",
        }],
        brand: { name: 'Maybelline New York' },
        name: "Maybelline New York Color Sensational Creamy Matte Lipstick",
        description: 'Choose to look bold with the Maybelline New York Color Sensational Creamy Matte Lipstick - 612 Cherry Chic (3.9g',
        quantity: "5",
        size: "Red",
        url: "https://www.tirabeauty.com/product/maybelline-new-york-color-sensational-creamy-matte-lipstick---612-cherry-chic-39g-vyquocckwkg",
      },
      emailData: {
        email_template: this.backStock ? this.backStock.email_template : this.limitedStock.email_template,
        app_contacts: this.backStock ? this.backStock.app_contacts : this.limitedStock.app_contacts,
        app_details: this.backStock ? this.backStock.app_details : this.limitedStock.app_details,
        support_info: this.backStock ? this.backStock.support_info : this.limitedStock.support_info,
      },
      user: {
        name: 'John Doe'
      },
      htmlData: ''
    };
  },
  created() {
    this.swapLayout();
  },
  mounted() {
    this.populateHtml();
  },
  methods: {
    swapLayout() {
      this.$root.$on("switchHorizontalLayout", (switchlayout) => {
        if (this.deviceType == "laptop") {
          this.switchHorizontalLayout = switchlayout;
        } else {
          this.switchHorizontalLayout = false;
        }
      });
    },
    selectResponsiveIcons(value) {
      this.deviceType = value;
    },
    populateHtml() {
      try {
        const html = this.backStock ? this.backStock.email_template.emailHtml : this.limitedStock.email_template.emailHtml;
        this.htmlData = nunjucks.renderString(html, { ...this.emailData, product: this.product, user: this.user });
        this.htmlData = nunjucks.renderString(this.htmlData, { ...this.emailData, product: this.product, user: this.user });
      } catch (e) {
        this.$snackbar.global.showError("Error While loading HTML, Give valid HTML");
      }
    },
  },

  watch: {
    backStock :{
      handler : function (){
        this.populateHtml();
      },
      deep:true
    },
    limitedStock :{
      handler : function (){
        this.populateHtml();
      },
      deep:true
    }
  }
};
</script>

<style lang="less" scoped>
@import '../helper/utils.less';

.right {
  border: 1px solid #e0e0e0;
  .pxToRem(border-radius, 8);
}

.top {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.icon {
  margin-right: 10px;
  cursor: pointer;
}

.live-preview {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000;
}

.pointer {
  cursor: pointer;
}

.wrapper {
  width: 100%;
  border-radius: 4px;
}

.customer-wrapper {
  background-color: #ffffff;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 10px;
}

.hello-customer {
  padding: 20px;
  background-color: #ffffff;
}

.hello-customer p {
  color: #4f4f4f;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  word-break: break-word;
}

.hello-customer h3 {
  color: #4f4f4f;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 10px;
  word-break: break-word;
}

.donot-miss {
  color: #4f4f4f;
  opacity: 0.5;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.image-wrapper {
  margin: 10px 20px;
  border-radius: 4px;
  display: flex;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
}

.image-text {
  width: 77%;
  padding: 20px;
  box-sizing: border-box;
}

.image-text h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4f4f4f;
  margin-top: 8px;
  margin-bottom: 15px;
}

.image-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
}

.image-text .n-button {
  margin-top: 10px;
  max-width: 100%;
}

::v-deep .n-button-content {
  display: block;
  line-height: 39px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unsubscribe {
  padding: 10px;
}

.unsubscribe p {
  color: #41434c;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding: 15px;
  text-align: center;
}

.mobile {
  width: 68%;
  margin: auto;

  .image-text {
    margin-left: 4px;
    width: 55%;
    box-sizing: border-box;

    .absolute {
      width: 100%;

      button {
        max-width: 100%;
      }
    }

    h5 {
      font-size: 14px;
    }
  }
}

.tablet {
  width: 80%;
  margin: auto;

  .image-text {
    margin-left: 4px;
    width: 55%;

    .absolute {
      max-width: 100%;

      button {
        max-width: 100%;
      }
    }

    h5 {
      font-size: 16px;
    }
  }
}

.switch {
  .customer-wrapper {
    display: flex;
    margin-top: 15px;
    position: relative;

    .hello-customer {
      width: 30%;
    }

    .image-wrapper {
      width: 65%;
      margin: 20px 20px 10px 0;
    }

    .absolute {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, 0%);
      max-width: 100%;
    }

    .image-text {
      h5 {
        font-size: 16px;
      }
    }
  }

  .unsubscribe {
    margin-top: 40px;

    p {
      text-align: left;
    }
  }
}

#html-render {
  .pxToRem(margin-top, 20px)
}
</style>
