import { render, staticRenderFns } from "./back-in-stock.vue?vue&type=template&id=563dd302&scoped=true"
import script from "./back-in-stock.vue?vue&type=script&lang=js"
export * from "./back-in-stock.vue?vue&type=script&lang=js"
import style0 from "./back-in-stock.vue?vue&type=style&index=0&id=563dd302&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563dd302",
  null
  
)

export default component.exports