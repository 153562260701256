<template>
    <div class="webhooks">
        <div class="header">
            <div class="content">
                <h3>Webhook Integration</h3>
                <p>Webhook is a mechanism to get real-time notifications whenever a certain event occurs on Fynd Platform.
                    For example, you'll receive a notification whenever you or your staff creates a product, or when your
                    customers place orders.</p>
                <nitrozen-button v-flatBtn theme="secondary" class="nit-button" size="small"
                    @click="onClickCreateWehbooks">Create
                    Webhook</nitrozen-button>
            </div>
            <div class="image">
                <img src="../../assets/pngs/illustration.png" alt="wehbook content banner" />
            </div>
        </div>
        <div v-if="subscribers && subscribers.length">
            <div class="wehbook-list" v-for="subscriber in subscribers" :key="subscriber._id">
                <webhook-card :subscriber=subscriber />
            </div>
            <NitrozenPagination class="pagination" v-model="paginationConfig" name="Subscriber"
                @change="getWebhookSubscribers" :pageSizeOptions="pageSizeOptions" />
        </div>
        <EmptyState v-else text="Webhooks Not Found" style="margin-top: 50px;"/>
    </div>
</template>
<script>
import { NitrozenButton, NitrozenPagination, flatBtn } from '@gofynd/nitrozen-vue';
import webhookCard from '../../components/webhooks/molecules/webhook-card.vue';
import MainService from '../../services/main-service';
import EmptyState from '@/components/analytics/molecules/empty-state.vue';

export default {
    name: "webhooks-page",
    components: {
    NitrozenButton,
    webhookCard,
    NitrozenPagination,
    EmptyState
},
    directives: {
        flatBtn,
    },
    data() {
        return {
            paginationConfig: {
                limit: 5,
                current: 0,
                total: 0,
            },
            pageSizeOptions: [5, 10, 15, 20],
            subscribers: null,
        }
    },
    computed: {},
    methods: {
        onClickCreateWehbooks() {
            this.$router.push(
                `/company/${this.$route.params.company_id}/${this.$route.params.application_id}/webhooks/details?type=create`
            )
        },
        async getWebhookSubscribers() {
            const { data } = await MainService.getAllWebhookSubscribers({
                pageSize: this.paginationConfig.limit,
                pageNo: this.paginationConfig.current
            })

            this.paginationConfig.current = parseInt(data.page);
            this.paginationConfig.total = parseInt(data.total);
            this.subscribers = data.docs;
        }
    },
    mounted() {
        this.getWebhookSubscribers();
    },
    destroyed() {
        // @ts-check

    }
}
</script>
<style scoped lang="less">
@import '../../helper/colors.less';
@import '../../helper/utils.less';

.webhooks {
    background: white;
    .pxToRem(margin, 24);
    .pxToRem(border-radius, 12);
    .pxToRem(padding, 24);
    overflow: scroll;

    .header {
        display: flex;
        .pxToRem(padding, 24);
        border: 1px solid @gray-5;

        .content {
            flex: 0.65;

            h3 {
                .pxToRem(padding-top, 16);
                .pxToRem(padding-bottom, 16);
                color: @textPrimary;
                font-weight: bold;
                .pxToRem(font-size, 24);
                font-size: 24px;
                text-align: left;
            }

            p {
                .pxToRem(font-size, 16);
                .pxToRem(line-height, 26);
                color: @textPrimary;
            }

            .nit-button {
                .pxToRem(margin-top, 24);
            }
        }

        .image {
            flex: 0.35;

            img {
                .pxToRem(width, 400);
                .pxToRem(height, 175);
                float: right;
            }
        }
    }

    .pagination {
        .pxToRem(margin-top, 30);
    }
}
</style>