<template>
    <div class="items-bought-vs-notified">
        <div class="title">
            <h6>Products Bought Vs To be Bought</h6>
            <p>
                This distribution shows the percentage of items bought against
                notified items in the cart
            </p>
        </div>
        <div class="content">
            <div v-if="itemsNotified" class="pie">
                <div class="pie-chart">
                    <canvas id="myPie" height="250"> </canvas>
                </div>
                <div class="pie-labels">
                    <pie-chart-label label-name="Items Bought"
                        :label-percentage="Math.round(itemsBought / (itemsNotified) * 100)" color="#2E31BE" />
                    <pie-chart-label label-name="Items to be Bought"
                        :label-percentage="Math.round((ItemsToBeBought / itemsNotified) * 100)" color="#2E31BE" />
                </div>
            </div>
            <empty-state v-else text="No items bought vs notified found" />
        </div>
    </div>
</template>
<script>
import { Chart } from 'chart.js';
import pieChartLabel from './molecules/pie-chart-label.vue';
import EmptyState from './molecules/empty-state.vue';
import MainService from '../../services/main-service';
export default {
    name: "items-bought-vs-notified",
    components: {
        pieChartLabel,
        EmptyState
    },
    data() {
        return {
            itemsBought: {
                type: Number,
                required: true,
            },
            itemsNotified: {
                type: Number,
                required: true,
            },
            ItemsToBeBought: {
                type: Number,
                required: true
            },
            pieInstance: null
        }
    },
    mounted() {
        // setTimeout(() => {
        this.itemsBoughtVsNotified()
        // }, 1000);

    },
    methods: {
        async itemsBoughtVsNotified() {
            const { data } = await MainService.getItemsBoughtVsNotified();
            this.itemsBought = data.itemsBought;
            this.itemsNotified = data.itemsNotified;
            this.ItemsToBeBought = this.itemsNotified - this.itemsBought;
            this.pieOne();
        },
        pieOne() {
            if (this.pieInstance) this.pieInstance.destroy();
            if (!document.getElementById('myPie')) return;
            const ctx = document.getElementById('myPie').getContext('2d');
            this.pieInstance = new Chart(ctx, {
                type: "pie",
                data: {
                    labels: ["Items to be Bought", "Items Bought"],
                    datasets: [
                        {
                            data: [this.ItemsToBeBought, this.itemsBought],
                            backgroundColor: ["#2E31BE", "#A0A1E3",],
                        },
                    ],
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    cutout: '75%',
                    spacing: 2,
                    plugins: {
                        legend: {
                            display: false
                        },
                    }
                }
            });
            this.pieInstance.render()
        },
    },
    watch: {
        itemsBought: function () {
            this.pieOne();
        },
        itemsNotified: function () {
            this.pieOne();
        }
    }
}
</script>
<style scoped lang="less">
@import '../../helper/utils.less';
@import '../../helper/colors.less';

.items-bought-vs-notified {
    border: 1px solid #E5E5E5;
    background-color: #fff;
    .pxToRem(padding, 24);
    .pxToRem(border-radius, 12);
    display: grid;
    .pxToRem(gap, 24px);

    .content {
        display: grid;
        place-items: stretch;
        align-self: stretch;
    }

    h6 {
        color: @textPrimary;
        font-family: Inter;
        .pxToRem(font-size, 18);
        font-style: normal;
        font-weight: 600;
        .pxToRem(line-height, 24);
    }

    p {
        color: rgba(102, 102, 102, 0.80);
        font-family: Inter;
        .pxToRem(font-size, 14);
        font-style: normal;
        font-weight: 400;
    }

    .pie {
        min-height: 320px;
        position: relative;
        display: flex;
        .pxToRem(gap, 24);
        align-items: center;

        .pie-chart {
            .pxToRem('height', 190);
        }
    }
}
</style>
