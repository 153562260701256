
import { isNode } from 'browser-or-node';
let applications = null;
import { setCompany, getCompany, setApplication } from '../helper/utils';
import store from '../store/index.js';
export const routeGuard = (
    to,
    from,
    next) => {
    if (isNode) {
        return next();
    }
    const { params } = to;
    if (params.company_id) {
        setCompany(params.company_id);
    }
    
    if (params.application_id) {
        setApplication(params.application_id);
    }
    const apidata = {
        page_no:1,
        page_size:1000,
        company_id: params.company_id,
    };
    import('../services/main-service').then(service => {
        if (params.application_id) {
            service.default.getAllApplications(apidata)
                .then(({ data }) => {
                    applications = data.items;
                    const filterApplications = applications.filter((app_name) => {
                        return (
                            app_name._id.match(params.application_id)
                        );
                    })
                    store.commit("setapplicationname", filterApplications[0].name);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
        let subscriptionDetail = store.getters.subscription;
        if (subscriptionDetail && subscriptionDetail.status === 'active') {
            next();
            return;
        }
        service.default.getSubscription()
            .then(({ data }) => {
                store.commit('setSubscriptionData', data);
                subscriptionDetail = data;
                if (subscriptionDetail && subscriptionDetail.status === 'active') {
                    next();
                }
                else {
                    next(`/company/${getCompany()}/pricing`);
                }
            })
            .catch(err => {
                console.error(err);
                next(`/company/${getCompany()}/pricing`);
            })
    });
}
