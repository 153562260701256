<template>
    <div class="card-wrapper">
        <div class="header">
            <h4>{{ data.head }}</h4>
            <div class="toggle">
                <p>
                    {{ data.enabled ? 'Enabled' : 'Disabled' }}
                </p>
                <NitrozenToggleBtn @input=data.toggleOnChange :value=data.enabled />
            </div>
        </div>
        <h5>{{ data.description }}</h5>
        <div class="input-group">
            <div v-for="inputField in data.inputFields" :key="inputField.label" class="custom-input">
                <NitrozenInput v-if="inputField.type === 'Number' || inputField.type === 'text' || inputField.type === 'email'" :label=inputField.label
                    :placeholder="inputField.placeholder" :value=inputField.value @input=inputField.onChange
                    :showTooltip=true :tooltipText=inputField.toolTip :type=inputField.type :disabled ="!data.enabled" max="999999"/>
                <NitrozenDropdown v-if="inputField.type === 'dropdown'" :label=inputField.label
                    :multiple="inputField.multiple" :placeholder="inputField.placeholder" :items="inputField.items"
                    :value=inputField.value @input=inputField.onChange :tooltip=inputField.toolTip :disabled ="!data.enabled"/>
                <DropdownModal v-if="inputField.type === 'modal-dropdown'" :inputField=inputField :disabled ="!data.enabled"/>
                <EmailError v-if="inputField.showError" />
            </div>
        </div>

    </div>
</template>
<script>
import { NitrozenDropdown, NitrozenInput, NitrozenToggleBtn } from '@gofynd/nitrozen-vue';
import DropdownModal from './molecules/dropdown-modal.vue';
import errorImg from './../../assets/svgs/error_validator.svg'
import EmailError from '../molecules/email-error.vue';

export default {
    name: "card-component",
    components: {
        NitrozenToggleBtn,
        NitrozenInput,
        NitrozenDropdown,
        DropdownModal,
        EmailError
    },
    props: {
        data: {
            type: {},
            required: true,
        },
    },
    computed: {
        errorImg() {
            return errorImg;
        }
    }
}
</script>
<style scoped lang="less">
@import '../../helper/colors.less';
@import '../../helper/utils.less';

.card-wrapper {
    .pxToRem(border-radius, 8);
    border: 1px solid #E0E0E0;
    .pxToRem(padding, 24);
    .pxToRem(padding-top, 18);

    h4 {
        color: @textPrimary;
        font-family: Inter;
        .pxToRem(font-size, 16);
        font-style: normal;
        font-weight: 600;
        .pxToRem(line-height, 24);
    }

    h5 {
        color: rgba(102, 102, 102, 0.80);
        ;
        font-family: Inter;
        .pxToRem(font-size, 14);
        font-style: normal;
        font-weight: 400;
        .pxToRem(line-height, 17);
    }

    .header {
        display: flex;
        justify-content: space-between;

        .toggle {
            display: flex;
            align-items: center;

            p {
                color: #2E31BE;
                font-family: Inter;
                .pxToRem(font-size, 12);
                font-style: normal;
                font-weight: 700;
                .pxToRem(line-height, 16);
            }
        }
    }

    .input-group {
        .pxToRem(margin-top, 16);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .pxToRem(column-gap, 16);
        .pxToRem(row-gap, 12);

        .custom-input:nth-child(3):nth-last-child(1) {
            grid-column: span 2;
        }
    }
}
</style>