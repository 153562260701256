<template>
    <form name="limited-stock-settings"
        @submit.prevent="save()"
        class="limited-stock-settings-wrapper">
        <div>
            <NitrozenDropdown
                label="Feature to appear on?"
                placeholder="select page"
                tooltip="Which pages should limited stock text show up"
                id="feature-to-appear"
                :items="supportedPages"
                v-model="selectedPages"
                :multiple="true"
                :disabled="saving">
    
            </NitrozenDropdown>
            <nitrozen-chips
                v-for="selectedPage in selectedPages"
                :key="selectedPage"
                :isRounded="false"
                :disable="saving"
            >
                {{selectedPage}}
                <NitrozenInline
                    :icon="'cross'"
                    class="nitrozen-icon"
                    v-on:click="removePage(selectedPage)"
                ></NitrozenInline>
            </nitrozen-chips>
            <NitrozenError v-if="!selectedPages.length">Select at least one Page</NitrozenError>
        </div>

        <NitrozenDropdown
            label="Send Email At"
            tooltip="The time at which the customers should receive the email"
            id="send-email-time"
            :items="hoursOfDay"
            v-model="send_email_at"
            :disabled="saving">
        </NitrozenDropdown>

        <div>
            <nitrozen-input
                label="Quantity Threshold"
                :showTooltip = "true"
                tooltipText="Lower limit after which a product is said to be limited"
                id="threshold"
                type="number"
                :min="1"
                :max="99999"
                v-model="quantity_threshold"
                :disabled="saving"
            />
            <nitrozen-error
                v-if="quantity_threshold !== undefined &&
                        quantity_threshold < 1">
                Threshold should not be less than 1
            </nitrozen-error>
        </div>
        <DropdownModal
            :inputField="dropdownModalInputField" 
            :disabled="saving"/>
        <div>
            <nitrozen-button
                type="submit"
                :disabled="saving || !selectedPages.length"
                :showProgress="saving"
                v-flatBtn
                size="small"
                theme="secondary">
                Save
            </nitrozen-button>
        </div>
        
    </form>
</template>
<script>

import {
    NitrozenDropdown,
    NitrozenChips,
    NitrozenInline,
    NitrozenInput,
    NitrozenButton,
    NitrozenError,
    flatBtn,
} from "@gofynd/nitrozen-vue";
import DropdownModal from '../components/settings/molecules/dropdown-modal.vue';
import { generateHoursOfDay } from "../helper/utils";
import MainService from "../services/main-service";

export default {
    name: "limited-stock-settings",
    components: {
        NitrozenDropdown,
        NitrozenChips,
        NitrozenInline,
        NitrozenInput,
        NitrozenButton,
        NitrozenError,

        DropdownModal,
    },
    directives: {
        flatBtn
    },
    props: {
        limited_stock: {
            type: Object,
            required: true,
        },
        saving: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            quantity_threshold: 5,
            /**
             * @typedef {{
             *  hours: number,
             *  mins: number,
             * }} Time
             * @type {Time}
             */
            send_email_at: {},
            /**
             * @typedef {{text: string, value: string}} 
             * @type {Array<string>}
             */
            selectedPages: [],
            supportedPages: [{
                text: "PDP",
                value: "PDP"
            }],
            /**
             * @type {{
             *      text: string;
             *      value: Time;
             *  }[]
             * }
             */
            hoursOfDay: [],
            dropdownModalInputField: {
                label: 'Exclude Specific Products',
                value: [],
                toolTip: 'Customers will not receive email for these products',
                onChange: () => {},
                getItems: () => {},
            }
        }
    },
    computed: {},
    methods: {
        /**
         * 
         * @param {string} page 
         */
        removePage(page) {
            // @ts-check
            const index = this.selectedPages
                                .findIndex(
                                    selectedPage => selectedPage === page);
            if (index === -1) {
                return;
            }
            this.selectedPages.splice(index, 1);
        },
        onChangeExcludeProduct(value) {
            this.dropdownModalInputField.value = value;
        },
        save() {

            this.$emit('save', {
                enabled: this.limited_stock.enabled,
                quantity_threshold: this.quantity_threshold,
                cart_pages: false,
                pdp_pages: !!this.selectedPages.find(selectedPage => selectedPage === 'PDP'),
                send_email_at: this.send_email_at,
                excluded_products: this.dropdownModalInputField.value,
            })
        
        }
    },
    mounted() {
        // @ts-check
        this.hoursOfDay = generateHoursOfDay();
        this.dropdownModalInputField = {
            ...this.dropdownModalInputField, 
            onChange: this.onChangeExcludeProduct,
            getItems: MainService.getProductsList,
            value: this.limited_stock.excluded_products
        };
        this.quantity_threshold = this.limited_stock.quantity_threshold;
        if (this.limited_stock.pdp_pages) {
            this.selectedPages
                .push(this.supportedPages
                            .find(page => page.value === 'PDP')?.value)
        }
        /**
         * @todo add for cart pages
         */
        if (this.limited_stock.send_email_at) {
            this.send_email_at = this.hoursOfDay
                                    .find(time =>
                                        time.value.hours === this.limited_stock
                                                                .send_email_at?.
                                                                hours)?.value;
        }
    },
    destroyed() {
        // @ts-check
        
    }
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';
.limited-stock-settings-wrapper {
    display: grid;
    .pxToRem(gap, 12);
    .pxToRem(max-width, 400);
    &::v-deep .nitrozen-chip {
        .pxToRem(border-radius, 4);
        .pxToRem(margin-top, 4);
        .pxToRem(margin-right, 4);
    }
}
</style>
