<template>
  <form class="button-customization"
        name="button-customization"
        @submit.prevent="save()">
    <div class="group">
      <h5>Notify Widget Customisation</h5>
      <div class="title-text">
        <div class="button-text button-text-para">
          <div class="note">
            <nitrozen-input
              type="textarea"
              label="Title Text"
              required
              v-model="backStock.storefront_appearance.title_text"
              @change="
                removeSpace('backStock', 'storefront_appearance', 'title_text')
              "
              @input="checkEmpty('storefront_appearance', 'title_text')"
              placeholder="Type title text here"
            ></nitrozen-input>
            <nitrozen-error v-if="errors['title_text']"
              >Field is required</nitrozen-error
            >
          </div>
        </div>
      </div>
      <div class="title-text">
        <div class="button-text button-text-para">
          <nitrozen-input
            label="Email Placeholder Text"
            class="note"
            placeholder="Type placeholder text here"
            onkeypress="return /^[a-zA-Z ]*$/i.test(event.key)"
            @change="
              removeSpace('backStock', 'storefront_appearance', 'placeholder')
            "
            v-model="backStock.storefront_appearance.placeholder"
          ></nitrozen-input>
        </div>
      </div>
    </div>
    <div class="group">
      <h5>Button Customisation</h5>
      <div class="button-text button-text-para">
        <!-- <p>Button Text*</p> -->
        <div class="note">
          <nitrozen-input
            label="Button Text"
            required
            v-model="backStock.storefront_appearance.button_text"
            onkeypress="return /^[a-zA-Z ]*$/i.test(event.key)"
            @change="
              removeSpace('backStock', 'storefront_appearance', 'button_text')
            "
            @input="checkEmpty('storefront_appearance', 'button_text')"
            placeholder="Type button text here"
          ></nitrozen-input>
          <nitrozen-error v-if="errors['storefront_button_text']"
            >Field is required</nitrozen-error
          >
        </div>
      </div>
      <div class="color button-text-para">
        <p>Button Colour</p>
        <div class="d-flex">
          <div class="color-code">
            <span class="hash">#</span>
            <span>{{
              this.backStock.storefront_appearance.button_color.replace("#", "")
            }}</span>
          </div>
          <span class="color-width">
            <label
              ><input
                class="input-color"
                v-model="backStock.storefront_appearance.button_color"
                type="color"
            /></label>
          </span>
        </div>
      </div>
    </div>
    <div class="group">
      <h5>Thank you Text</h5>
      <div class="message-text">
        <div class="button-text button-text-para">
          <div class="note">
            <nitrozen-input
              type="textarea"
              label="Message Text"
              required
              v-model="backStock.storefront_appearance.message_text"
              @change="
                removeSpace('backStock', 'storefront_appearance', 'message_text')
              "
              @input="checkEmpty('storefront_appearance', 'message_text')"
              placeholder="Type message text here"
            ></nitrozen-input>
            <nitrozen-error v-if="errors['message_text']"
              >Field is required</nitrozen-error>
          </div>
        </div>
      </div>
    </div>
    <nitrozen-button
        type="submit"
        :disabled="saving"
        :showProgress="saving"
        v-flatBtn
        size="small"
        theme="secondary"
      >
        Save
      </nitrozen-button>
  </form>
</template>

<script>
import {
  NitrozenInput,
  NitrozenError,
  NitrozenButton,
  flatBtn
} from "@gofynd/nitrozen-vue";
export default {
  name: 'back-in-stock-storefront-customization',
  props: ["backStock", "errors", "checkEmpty"],
  components: {
    NitrozenInput,
    NitrozenError,
    NitrozenButton,
  },
  directives: {
    flatBtn
  },
  methods: {
    removeSpace(i, p, s) {
      this[i][p][s] = this[i][p][s].trim();
    },
    save() {
      this.$emit('save');
    }
  },
};
</script>

<style lang="less" scoped>
.group {
  display: grid;
  gap: 12px;
  margin-bottom: 20px;
}

h5 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.button-text-para p {
  color: #9b9b9b;
  font-family: Inter,sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
}

.d-flex {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
}
.color-code {
  padding: 9px;
  width: 100%;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #828282;
  }
}
.note {
  // width: 65%;
  .para {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #828282;
  }
}
.hash {
  border-right: 1px solid;
  padding-right: 8px;
  margin-right: 8px;
}
.input-color {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 48px;
}
.input-color::-webkit-color-swatch {
  border-radius: 2px;
  border: none;
}
.color-width {
  margin-left: 10px;
  width: 20%;
  border-radius: 2px;
}
.color-width input {
  height: 48px;
}
</style>
