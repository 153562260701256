<template>
    <div class="webhook-card"
        @click="() => this.$router.push(`/company/${this.$route.params.company_id}/${this.$route.params.application_id}/webhooks/details?type=edit&id=${subscriber._id}`)">
        <div class="icon">
            <img src="../../../assets/svgs/webhooks_icon.svg" alt="webhook Icon" />
        </div>
        <div class="content">
            <h5>{{ subscriber.name }}</h5>
            <div class="content-body">
                <div class="events">
                    <p class="title"> Events </p>
                    <!-- For now considering only events under products-->
                    <p class="value" v-for="(event, idx) in subscriber.eventGroup.sales_channel?.eventType.product?.events"
                        :key="event.event">
                        {{ event.enabled ? `${event.event} ` : '' }}
                    </p>
                </div>
                <div class="modified-by">
                    <p class="title"> Modified By</p>
                    <p class="value"> {{ subscriber.modified_by ?? '-' }}</p>
                </div>
                <div class="modified-on">
                    <p class="title"> Modified On</p>
                    <p class="value"> {{ getFormattedDate(subscriber.updated_at) }}</p>
                </div>
            </div>
        </div>
        <NitrozenBadge :state="subscriber.enabled ? 'success' : 'default'">
            {{ subscriber.enabled ? 'ACTIVE' : 'INACTIVE' }}
        </NitrozenBadge>
    </div>
</template>
<script>
import { NitrozenBadge } from '@gofynd/nitrozen-vue';
import { formatUTCtoISTDate } from '../../../../common/helpers/dateTime';
export default {
    name: "webhook-card",
    components: {
        NitrozenBadge
    },
    props: {
        subscriber: {
            required: true,
        },
    },
    data() {
        return {
            webhook: {
                name: 'Hello ',
                modified_by: 'Sai Teja',
                updated_at: '2023-09-28T11:54:44.992+00:00',
                status: true
            }
        }
    },
    methods: {
        getFormattedDate(date) {
            return formatUTCtoISTDate(date);
        }
    }
}
</script>
<style scoped lang="less">
@import '../../../helper/utils.less';
@import '../../../helper/colors.less';

.webhook-card {
    display: flex;
    align-items: center;
    border: 1px solid @gray-5;
    .pxToRem(border-radius, 3);
    .pxToRem(margin-top, 30);
    .pxToRem(padding, 24);
    .pxToRem(height, 70);
    cursor: pointer;

    .icon {
        position: relative;
        .pxToRem(height, 70);
        .pxToRem(width, 70);
        margin-right: 1rem;
        background-color: #f8f8f8;
        border-radius: 50%;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    h5 {
        color: @textPrimary;
        font-weight: 600;
        .pxToRem(font-size, 16);
        .pxToRem(line-height, 22);
    }

    .content {
        flex: 1;

        .content-body {
            display: flex;
            .pxToRem(gap, 24);
            width: 100%;
            .pxToRem(line-height, 22);
            .pxToRem(font-size, 12);
            font-family: Inter;
            font-style: normal;
            font-weight: normal;

            .title {
                color: #9B9B9B;
            }

            .value {
                color: #666666;
            }
        }
    }
}
</style>