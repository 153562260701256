<template>
  <div class="application white-box">
    <div class="section-heading">
      <h3>Sales Channel</h3>
      <p>
        Here’s a list of sales channels for which the extension is either active or inactive
      </p>
    </div>
    <div class="search">
      <NitrozenInput
        v-model="search"
        type="search"
        :search="trueData"
        :showSearchIcon="trueData"
        placeholder="Search Sales Channel By Name"
        class="float-left"
      />
    </div>
    <div class="card-main columns-4" v-if="this.applications.items">
      <div
        class="card-innerbox"
        v-for="(item, i) in this.filteredApplications"
        :key="i"
      >
        <div class="card-box">
          <div class="card-logo">
            <img
              v-if="item.logo && item.logo.secure_url"
              :src="item.logo.secure_url"
              alt="image"
            />
          </div>
          <div class="card-content">
            <h4 v-if="item.name">{{ item.name }}</h4>
            <p v-if="item.domain.name">{{ item.domain.name }}</p>
          </div>
          <div class="card-content-margin">
            <div class="status">
              <nitrozen-badge
                state="success"
                v-if="
                  setAsActive[item._id] &&
                  setAsActive[item._id].enabled
                "
                >Active</nitrozen-badge
              >
              <nitrozen-badge state="error" v-else>Inactive</nitrozen-badge>
            </div>
            <div
              class="button-container"
              @click="onClickButton(item)"
            >
              <div class="select-widget float-right" id="subscribe">
                <inline-svg class="arrow" :src="'big-arrow'"></inline-svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader :helperText="helperText" v-if="this.loader"></loader>
    <loaderFullScreen v-if="loaderFullScreen"> </loaderFullScreen>
  </div>
</template>
<script>
import { NitrozenInput, NitrozenBadge } from "@gofynd/nitrozen-vue";
import MainService from "../services/main-service";
import loader from "../components/common/loader.vue";
import loaderFullScreen from "@/components/common/loaderFullScreen";
import inlineSvg from "@/components/common/inline-svg.vue";
export default {
  name: "application-list",
  components: {
    loader,
    loaderFullScreen,
    NitrozenBadge,
    NitrozenInput,
    // NitrozenPagination,
    "inline-svg": inlineSvg,
  },
  data() {
    return {
      setAsActive: {},
      trueData: true,
      filters: [{ text: "All", value: "all" }],
      loader: true,
      loaderFullScreen: false,
      data: [],
      search: "",
      selectedFilter: "all",
      helperText: "Loading...",
      applications: {
        items: [],
      },
      PaginationConfig: {
        limit: 1200,
        total: 1,
        current: 1,
      },
      pageSizeOptions: [12, 24, 48, 96],
    };
  },
  computed: {
    filteredApplications() {
      return this.applications.items.filter(
        (c) => c.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },
  },
  mounted() {
    this.getApplications();
    this.postApplication();
  },
  methods: {
    postApplication() {
      const apidata = {
        company_id: this.$route.params.company_id,
      };
      MainService.postApplication(apidata)
        .then(({ data }) => {
          const active = data.reduce(
            (obj, cur) => ({ ...obj, [cur.application_id]: cur }),
            {}
          );
          this.setAsActive = active;
        })
        .catch((e) => {
          this.$snackbar.global.showError("Something went wrong! please try again");
        });
    },
    async getApplications() {
      const params = {
        page_no: this.PaginationConfig.current,
        page_size: this.PaginationConfig.limit,
        company_id: this.$route.params.company_id,
      };
      MainService.getAllApplications(params)
        .then(({ data }) => {
          this.PaginationConfig.current = data.page.current;
          this.PaginationConfig.total = data.page.item_total;
          this.applications = data;
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
          this.$snackbar.global.showError("Something went wrong! please try again");
        });
    },
    onClickButton(item) {
      const app_id = item._id
      this.$store.state.application_name = item.name;
      this.$store.state.domain = item.domain.name;
      this.$router.push(
        `/company/${this.$route.params.company_id}/${app_id}/analyticsFeatures`
      );
    },
  },
};
</script>
<style lang="less" scoped>
.img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-screen-image p {
  display: flex;
  justify-content: center;
  margin-right: 30px;
  font-size: 15px;
  color: rgb(102, 102, 102);
}

.white-box {
  margin: 24px;
  border-radius: 10px;
  padding: 24px;
  background-color: #fff;
}
.section-heading h3 {
  margin-top: 0;
  font-style: normal;
  line-height: 28px;
  font-weight: 600;
  font-size: 20px;
  color: #41434c;
  margin-bottom: 15px;
}
.section-heading p {
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  color: #666666;
}
.search {
  margin-bottom: 25px !important;
  float: left;
  width: 100%;
}
.nitrozen-form-input {
  width: 49%;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.filter-box {
  width: 310px;
  display: table-footer-group;
}
.filter-box .nitrozen-dropdown-label {
  position: relative;
  top: 8px;
}
.filter-box .nitrozen-select-wrapper {
  width: 250px;
  float: right;
}
.columns-4 {
  grid-template-columns: 25% 25% 25% 25%;
  width: calc(100% - 54px);
}
.card-main {
  display: grid;
  grid-column-gap: 18px;
}
.card-innerbox {
  margin-bottom: 24px;
}
.card-box {
  border: 1px solid #e4e5e6;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  background-color: #fff;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}
.card-logo {
  min-height: 60px;
}
.card-logo img {
  max-width: 52px;
}
.select-widget {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.select-widget .inline-svg {
  float: right;
  height: 35px;
  width: 35px;
  font-size: 15px;
  padding: 6px 6px;
  border-radius: 20%;
  border: 1px solid #dedede;
  box-sizing: border-box;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.card-content {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}
.card-content h4 {
  margin: 0;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
  color: #41434c;
}
.card-content p {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  color: #828282;
  font-family: 'Inter', sans-serif;
}
.inactive_button {
  position: relative;
  border: 1px solid #eb5757;
  background-color: transparent;
  color: #eb5757;
  width: 74px;
  text-transform: uppercase;
  height: 22px;
  top: 7px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.card-innerbox a {
  display: block;
  width: 100%;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}
.button-container {
  width: 100%;
  float: left;
}
.card-content-margin {
  margin-top: 40px;
}
.status {
  padding-bottom: 4px;
}
</style>