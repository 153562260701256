<template>
    <div class="html-editor-wrapper">
        <div class="html-editor">
            <CloseImg @close="$emit('closeModal')" />
            <h3>Edit Email Html</h3>
            <div class="horizontal-div"></div>
            <div class="monaco-editor" ref="editorContainer"></div>
        </div>
        <div class="footer">
            <!--@TODO We have to disable save when html is invalid-->
            <nitrozen-button v-flatBtn class="nit-button2" size="small" theme="secondary"
                @click="onSave">Save</nitrozen-button>
            <nitrozen-button v-flatBtn class="nit-button" size="small" theme="secondary" @click="$emit('getDefault')">Reset
                to
                Default</nitrozen-button>
        </div>
    </div>
</template>
<script>
import * as monaco from "monaco-editor";
import CloseImg from '../atoms/close-img.vue';
import { NitrozenButton, flatBtn } from "@gofynd/nitrozen-vue";

export default {
    name: "email-editor",
    components: {
        CloseImg,
        NitrozenButton
    },
    directives: {
        flatBtn,
    },
    props: {
        htmlData: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            options: {
                autoIndent: 'advanced',
                cursorBlinking: 'blink',
                autoClosingQuotes: 'always',
                matchBrackets: true,
            },
            editor: null,
        }
    },
    methods: {
        onSave() {
            this.$emit('saveHtml', this.editor.getValue());
            this.$emit('closeModal');
        },
        closeEmailModal() {
            this.$emit('closeModal');
        }
    },
    async mounted() {
        this.editor = monaco.editor.create(this.$refs.editorContainer, {
            value: this.htmlData,
            language: "html",
            theme: "vs-dark",
            automaticLayout: true,
        });

        this.editor.onDidChangeModelContent((event) => {
            // Handle content changes here, getValue gets value upto the event, manually adding the current event
            const newHtml = `${this.editor.getValue()}`;
            this.$emit('change', newHtml);
        });
    },
    beforeDestroy() {
        if (this.editor) {
            this.editor.dispose();
        }
    },
    watch: {
        htmlData(newHtml) {
            if (newHtml != this.editor.getValue()) {
                this.editor.setValue(newHtml);
            }
        }
    }
}
</script>
<style scoped lang="less">
@import '../../helper/colors.less';
@import '../../helper/utils.less';

.html-editor-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.70);

    .close {
        position: absolute;
        .pxToRem(top, 25);
        .pxToRem(right, 20);
        cursor: pointer;
    }

    .html-editor {
        width: 75%;
        height: 100%;
        position: fixed;
        top: 0;
        right: 0;
        background: @white;
        .pxToRem(padding, 24);

        h3 {
            color: @textPrimary;
            font-family: Inter;
            .pxToRem(font-size, 24);
            font-style: normal;
            font-weight: 700;
            .pxToRem(margin-bottom, 24);
        }

        .horizontal-div {
            .pxToRem(height, 1);
            background: @gray-6;
            .pxToRem(margin-right, -24);
            .pxToRem(margin-left, -24);
        }

        .monaco-editor {
            .pxToRem(margin-top, 24);
            height: 70vh;
        }
    }

    .footer {
        display: flex;
        position: absolute;
        box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.05);
        flex-direction: row-reverse;
        gap: 1rem;
        .pxToRem(bottom, 0);
        .pxToRem(padding, 16);
        right: 0;
        width: calc(100% - 2rem);

        .btn-disabled {
            opacity: 0.5;
        }

        .nit-button {
            background: transparent;
            color: @blue-primary;
            border: 1px solid @blue-primary;
            .pxToRem(border-radius, 4);
        }

        .nit-button2 {
            background: @blue-primary;
            color: @white;
            .pxToRem(border-radius, 4);
        }
    }
}
</style>