import { isBrowser } from "browser-or-node";

export const LocalStorageService = {
  
  removeAll() {
    if (isBrowser) {
      localStorage.clear();
      sessionStorage.clear();
    }
  },
  
 
};







