<template>
  <div class="loader">
    <img src="../../assets/loader-blue.gif" alt="image"/>
    <div v-if="helperText">{{ helperText }}</div>
  </div>
</template>
<script>
export default {
  name: "loader-full",
  props: {
    helperText: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.loader {
  img {
    height: 100px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255,255,255,.5);
}
</style>
