<template>
    <div class="most-wanted-products">
        <div class="title">
            <h6>Top Customer-Subscribed Products</h6>
            <p>
                List of products that are most subscribed by customers across the application
            </p>
        </div>
        <div class="content">
            <div class="products-table" v-if="mostWantedProducts && mostWantedProducts.length">
                <div class="product-row table-header table-bg">
                    <sh3 class="w-50 text-color-2">Product</sh3>
                    <sh3 class="w-25 bold">Size</sh3>
                    <sh3 class="w-25 bold">Subscribers</sh3>
                </div>
                <ul>
                    <li v-for="product in mostWantedProducts" :key="product.name">
                        <div class="product-row">
                            <div class="w-50 product-title">
                                <p><img class="product-icon" :src=product.media?.[0].url alt="image" /></p>
                                <p class="text-color text-color">{{ product.name }}</p>
                            </div>
                            <p class="w-25 text-color text-color">{{ product.variant }}</p>
                            <p class="w-25 text-color-2">{{ product.subscribers | formatCompactNumber }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <EmptyState v-else text="No Most Wanted Product Found" />
        </div>
    </div>
</template>
<script>
import EmptyState from './molecules/empty-state.vue';

export default {
    name: "most-wanted-products",
    components: {
        EmptyState
    },
    props: {
        mostWantedProducts: {
            type: Array,
            default: null,
            required: true,
        },
    },
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';

.most-wanted-products {
    border: 1px solid #E5E5E5;
    background-color: #fff;
    .pxToRem(padding, 24);
    .pxToRem(border-radius, 12);
    min-height: 320px;
    display: grid;
    .pxToRem(gap, 24);
    grid-template-rows: auto 1fr;

    .content {
        display: grid;
        place-items: stretch;
        align-self: stretch;
    }
    h6 {
        color: #41434C;
        font-family: Inter;
        .pxToRem(font-size, 18);
        font-style: normal;
        font-weight: 600;
        .pxToRem(line-height, 24);
    }

    p {
        color: rgba(102, 102, 102, 0.80);
        font-family: Inter;
        .pxToRem(font-size, 14);
        font-style: normal;
        font-weight: 400;
        .pxToRem(line-height, 17);
    }

    .products-table {
        border: 1px solid #e0e0e0;
        .pxToRem(border-radius, 4);

        .table-header {
            sh3 {
                color: #41434C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 600;
                .pxToRem(line-height, 18.2);
            }
        }

        .product-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e0e0e0;
            .pxToRem(padding, 16);
            .pxToRem(gap, 16);
            p {
                color: #5C5C5C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 400;
                .pxToRem(line-height, 18.2);
            }

            .product-title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .pxToRem(gap, 12);

                .product-icon {
                    .pxToRem(height, 40);
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }

        .table-bg {
            background: #f6f6f6;
        }

        .w-50 {
            width: 50%;
        }

        .w-25 {
            width: 25%;
        }
    }
}
</style>
