import Vue from 'vue'
import VueRouter from 'vue-router'
import Application from '../views/Application.vue'
import Analytics from '../views/pages/analytics-features.vue'
import Settings from '../views/pages/settings-new.vue'
import LimitedStock from '../views/pages/limited-stock.vue'
import BackInStock from '../views/pages/back-in-stock.vue'
import Chart from '../views/pages/chart.vue'
import Subscription from '../views/subscription-status.vue'
import CronData from '../views/pages/cron-data.vue'
import Pricing from '../views/pricing.vue'
import ShowDataInTableFormat from '../views/pages/show-data-in-table-format.vue'
import Webhooks from '../views/pages/webhooks.vue';
import WebhooksForm from '../components/webhooks/webhook-form.vue'
import { routeGuard } from './guard';
Vue.use(VueRouter)

const routes = [
  {
    path: '/company/:company_id/',
    name: 'Applications',
    beforeEnter: routeGuard,
    component: Application,
    meta: {
      breadcrumb: []
    }
  },

  {
    path: '/company/:company_id/subscription-status',
    name: 'Subscription',
    component: Subscription,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: `/company/:company_id/`
        },
        {
          name: 'Subscription'
        }
      ]
    }
  },

  {
    path: '/company/:company_id/pricing',
    name: 'Pricing',
    component: Pricing,
  },

  {
    path: '/company/:company_id/:application_id/analyticsFeatures',
    name: 'AnalyticsFeatures',
    beforeEnter: routeGuard,
    component: Analytics,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: '/company/:company_id'
        },
        {
          name: 'AnalyticsFeatures'
        },
      ]
    }
  },

  {
    path: '/company/:company_id/:application_id/limited-stock',
    name: 'Limited Stock',
    component: LimitedStock,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: '/company/:company_id'
        },
        {
          name: 'AnalyticsFeatures', link: '/company/:company_id/:application_id/limited-stock'
        },
        {
          name: 'Limited Stock'
        },
      ]
    }
  },

  {
    path: '/company/:company_id/:application_id/back-in-stock',
    name: 'Back In Stock',
    component: BackInStock,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: '/company/:company_id'
        },
        {
          name: 'AnalyticsFeatures', link: '/company/:company_id/:application_id/back-in-stock'
        },
        {
          name: 'Back In Stock'
        },
      ]
    }
  },

  {
    path: '/company/:company_id/:application_id/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: '/company/:company_id'
        },
        {
          name: 'AnalyticsFeatures', link: '/company/:company_id/:application_id/settings'
        },
        {
          name: 'Settings'
        },
      ]
    }
  },

  {
    path: '/company/:company_id/:application_id/webhooks',
    name: 'Webhooks',
    component: Webhooks,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: '/company/:company_id'
        },
        {
          name: 'AnalyticsFeatures', link: '/company/:company_id/:application_id/webhooks'
        },
        {
          name: 'Webhooks'
        },
      ]
    }
  },

  {
    path: '/company/:company_id/:application_id/webhooks/details',
    name: 'WebhooksForm',
    component: WebhooksForm,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: '/company/:company_id'
        },
        {
          name: 'AnalyticsFeatures', link: '/company/:company_id/:application_id/webhooks'
        },
        {
          name: 'Webhooks', link: '/company/:company_id/:application_id/webhooks/details'
        },
        {
          name: 'Subscriber Details'
        },
      ]
    }
  },

  {
    path: '/company/:company_id/:application_id/analytics',
    name: 'Analytics',
    component: Chart,
    meta: {
      breadcrumb: [
        {
          name: 'Applications', link: '/company/:company_id'
        },
        {
          name: 'AnalyticsFeatures', link: '/company/:company_id/:application_id/chart'
        },
        {
          name: 'Analytics'
        },
      ]
    }
  },
  {
    path: '/cron-status',
    name: 'CronData',
    component: CronData,
  },
  {
    path: '/:company_id/:application_id/out_stock_email',
    name: 'Out Stock Email',
    component: ShowDataInTableFormat,
  },
  {
    path: '/:company_id/:application_id/out_stock_email_archive',
    name: 'Out Stock Email Archive',
    component: ShowDataInTableFormat,
  },
  {
    path: '/:company_id/:application_id/limited_stock_success_email',
    name: 'Limited Stock Email Success',
    component: ShowDataInTableFormat,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
