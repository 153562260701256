import { Extension } from '@gofynd/fdk-extension-bridge-javascript';
import root from "window-or-global";
let key = '';
if (
    root &&
    root.process &&
    root.process.env &&
    root.process.NODE_ENV == "test"
  ) {
    key = '627b493bb255940f5ddd2826';
  }
  else
  {
    key= root.env.API_KEY
  }
let ext = new Extension({
    apiKey :key
});
export default ext;