<template>
    <div class="summary-card-wrapper">
        <p class="title">{{ title }}</p>
        <div class="row">
            <b class="count">{{ count | formatCompactNumber }}</b>
            <template v-if="!isNaN(percentChange) && isFinite(percentChange)">
                <inline-svg v-if="percentChange > 0" :src="'green-arrow'"></inline-svg>
                <inline-svg v-else src="red-arrow"></inline-svg>
                <span :class="percentCssClasses">
                    {{ Math.abs(percentChange) != 'Infinity' ? Math.round(Math.abs(percentChange)) :
                        Intl.NumberFormat("en", {
                            notation:
                                "compact"
                        }).format(1 / 0) }}%
                </span>
            </template>
        </div>
    </div>
</template>
<script>
import inlineSvg from "@/components/common/inline-svg.vue";
export default {
    name: "summary-card",
    components: {
        "inline-svg": inlineSvg,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        count: {
            type: Number,
            default: 0,
        },
        percentChange: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        percentCssClasses() {
            return {
                percentChange: true,
                green: this.percentChange > 0,
                red: this.percentChange < 0,
            }
        }
    },
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';

.summary-card-wrapper {
    padding: 1rem;
    .pxToRem(border-radius, 12);
    border: 1px solid #E5E5E5;
    background-color: #fff;
}

.title {
    color: #828282;
    .pxToRem(margin-bottom, 4);
    .pxToRem(font-size, 12);
}

.row {
    display: flex;
    align-items: baseline;
}

.count {
    color: #333;
    .pxToRem(font-size, 18);
    line-height: 1;
    .pxToRem(margin-right, 10);
}

.percentChange {
    .pxToRem(font-size, 12);
    .pxToRem(margin-left, 6);
}

.green {
    color: #219653;
}

.red {
    color: #C13C3C;
}
</style>
