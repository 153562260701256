<template>
  <div class="table-main">
    <div class="img" v-if="!emailData.length">
      <div class="no-screen-image">
        <img src="../../assets/pngs/empty-state.png" alt="image"/>
        <p>Select a date range to see Email data</p>
      </div>
    </div>
    <table>
      <tr v-if="emailData.length">
        <th id="email">Email</th>
        <th id="item_id">Item Id</th>
        <th id="name">Name</th>
        <th id="size">Size</th>
        <th id="article_id">Article Id</th>
        <th id="product_uid" v-if="$route.name == 'Out Stock Email'">Product UId</th>
        <th id="created_time">Created time</th>
      </tr>
      <tr v-for="item in emailData" :key="item">
        <td>{{ item.email }}</td>
        <td>{{ item._id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.size }}</td>
        <td>{{ item.article_id }}</td>
        <td v-if="$route.name == 'Out Stock Email'">
          {{ item.product_uid }}
        </td>
        <td>{{ modifiedDate(item.created_at) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "table",
  props: ["emailData"],
  methods: {
    modifiedDate(date) {
      return moment(date).format("hh:mm A, Do MMM YYYY");
    },
  },
};
</script>

<style lang="less" scoped>
.table-main{
  padding: 0px 15px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}
table{
  width: 100%;
}
table,
th,
td {
  border: 1px solid #ccc;
  text-align: center;
  border-collapse: collapse;
  padding: 10px;
  font-size: 12px;
}
td {
  color: #565656;
  font-weight: 500;
}
.no-screen-image p{
    padding-left: 40px;
}
</style>