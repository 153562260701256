<template>
    <div class="modal-wrapper">
        <Loader v-if="loader" />
        <div v-else class="add-customer-modal">
            <div class="close" @click="closeModal">
                <img :src="closeImg" />
            </div>
            <div class="header">
                <h2>Add Customer</h2>
            </div>
            <div class="body">
                <nitrozen-input class="customer-input" label="Select Product to Add The Customer" :value="product.name"
                    :showTooltip=true tooltipText="Select Product Select Product to Add The Customer"
                    :disabled=true></nitrozen-input>

                <nitrozen-input class="customer-input" label="Select Product Size" :value="product.size"
                    :showTooltip=true tooltipText="Select Size" :disabled=true></nitrozen-input>

                <nitrozen-input class="customer-input" label="Customer Email" :value="email" :showTooltip=true
                    @input="validate" tooltipText="Provide Customer Email" :required="true" />

                <div v-if="!isEmailValid && this.email" class="input-error-text">
                    <img :src="errorIcon" alt="Error" />
                    <p>Please Enter the Correct Email</p>
                    {{ errorText }}
                </div>

            </div>
            <div class="footer">
                <nitrozen-button :class="isEmailValid ? '' : 'btn-disabled'" v-flatBtn class="nit-button2" size="small"
                    theme="secondary" :disabled="!isEmailValid" @click="addUser">Add
                    Customer</nitrozen-button>
                <nitrozen-button v-flatBtn class="nit-button" size="small" theme="secondary"
                    @click="closeModal">Cancel</nitrozen-button>
            </div>
        </div>
    </div>
</template>
<script>
import {
    NitrozenButton,
    flatBtn,
    NitrozenInput
} from "@gofynd/nitrozen-vue";
import close from "../../../assets/svgs/close.svg";
import error_validator from '../../../assets/svgs/error_validator.svg';
import MainService from "@/services/main-service";
import Loader from '../../common/loader.vue';

export default {
    name: "add-customer-modal",
    components: {
        NitrozenButton,
        NitrozenInput,
        Loader
    },
    directives: {
        flatBtn
    },
    props: {
        product: {
            type: Object,
            default: null,
            required: true,
        },
        closeModal: {
            type: Function,
            required: true,
        },
        addCustomer: {
            type: Function,
            required: true
        },
        isExistingUser: {
            type: Function,
            required: true,
        }
    },
    computed: {
        closeImg() {
            return close;
        },
        errorIcon() {
            return error_validator;
        },
    },
    data() {
        return {
            email: '',
            isEmailValid: false,
            loader: false
        }
    },
    methods: {
        validate(e) {
            this.email = e;
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
                this.isEmailValid = true;
            } else {
                this.isEmailValid = false;
            }
        },
        async addUser() {
            try {
                this.loader = true;
                if (this.isExistingUser(this.email)) {
                    this.$snackbar.global.showError("Existing user");
                    return;
                }
                const response = await MainService.addCustomerToWaitList({ ...this.product, email: this.email });
                if (response.status === 200) {
                    this.$snackbar.global.showSuccess("Customer Added");
                    this.addCustomer(this.email);
                    this.closeModal();
                } else {
                    throw Error;
                }
            } catch (e) {
                this.$snackbar.global.showError("Something went wrong! please try again",);
            } finally {
                this.loader = false;
            }
        }
    },
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';
@import '../../../helper/colors.less';

.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.n-input-label {
    color: var(--gray-2, #4F4F4F) !important;
    font-family: Inter;
    .pxToRem(font-size, 12) !important;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.add-customer-modal {
    width: 25%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background: @white;
    .pxToRem(padding, 24);

    .close {
        position: absolute;
        .pxToRem(top, 25);
        .pxToRem(right, 20);
        cursor: pointer;
    }

    .header {
        border-bottom: 1px solid @gray-6;
        .pxToRem(margin-left, -24);
        .pxToRem(margin-right, -24);

        h2 {
            .pxToRem(margin-left, 24);
            color: @textPrimary;
            font-family: Inter;
            .pxToRem(font-size, 20);
            font-style: normal;
            font-weight: 700;
            line-height: 155%;
        }
    }

    .body {
        .customer-input {
            .pxToRem(margin-top, 24);

            .n-input-label-container {
                label {
                    color: var(--gray-2, #4F4F4F) !important;
                    font-family: Inter;
                    .pxToRem(font-size, 12) !important;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%;
                }
            }
        }

        .input-error-text {
            display: flex;
            .pxToRem(margin-top, 4);
            align-items: center;


            img {
                .pxToRem(height, 14);
                .pxToRem(width, 14);
            }

            p {
                .pxToRem(margin-left, 7);
                color: @error;
                font-family: Inter;
                .pxToRem(font-size, 12);
                font-style: normal;
                font-weight: 400;
                .pxToRem(line-height, 17);
            }
        }
    }

    .footer {
        display: flex;
        position: absolute;
        box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.05);
        flex-direction: row-reverse;
        gap: 1rem;
        .pxToRem(bottom, 45);
        .pxToRem(padding, 16);
        right: 0;
        width: calc(100% - 2rem);

        .btn-disabled {
            opacity: 0.5;
        }

        .nit-button {
            background: transparent;
            color: @blue-primary;
            border: 1px solid @blue-primary;
            .pxToRem(border-radius, 4);
        }

        .nit-button2 {
            background: @blue-primary;
            color: @white;
            .pxToRem(border-radius, 4);
        }
    }
}
</style>