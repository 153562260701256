<template>
  <section class="right">
    <div class="top">
      <div class="icons">
        <span @click="selectResponsiveIcons('laptop')" :active="{active: deviceType=='laptop'}" class="icon">
          <inline-svg class="arrow" :src="deviceType=='laptop' ? 'laptop-blue': 'laptop'"></inline-svg>
        </span>
        <span @click="selectResponsiveIcons('mobile')" :active="{active: deviceType=='mobile'}" class="icon">
          <inline-svg class="arrow" :src="deviceType=='mobile' ? 'mobile-blue': 'mobile'"></inline-svg>
        </span>
        <span @click="selectResponsiveIcons('tablet')" :active="{active: deviceType=='tablet'}">
          <inline-svg class="arrow" :src="deviceType=='tablet' ? 'tablet-blue': 'tablet'"></inline-svg>
        </span>
      </div>
      <div class="live-preview">
        <span class="live-preview">Live Preview</span>
      </div>
    </div>
    <div class="gray" :class="deviceType">
      <div class="products">
          <div class="images">
            <img v-if="deviceType == 'laptop' || deviceType == 'tablet'" class="image" src="../assets/pngs/product_img.png" alt="product-image">
            <img v-if="deviceType == 'laptop' || deviceType == 'tablet'" class="image" src="../assets/pngs/product_img.png" alt="product-image">
            <img class="image" src="../assets/pngs/product_img.png" alt="product-image">
            <img class="image" src="../assets/pngs/product_img.png" alt="product-image">
          </div>
          <div class="content">
            <p>Voi Jeans</p>
            <h5>Men Camo Printed Casual Shirt(VOSH1547)</h5>
            <h6>₹625</h6>
            <p class="red"  v-if="limitedStock"><span v-text="mapObj.reduce((f, s) => `${f}`.replace(Object.keys(s)[0], s[Object.keys(s)[0]]), limitedStock.storefront_appearance.limited_stock_text)"></span></p>
            <div class="cart-buttons">
               <div>
                <nitrozen-button
                  v-flatBtn
                  theme="secondary"
                  id="subscribe"
                  focused: Boolean
                >
                Add To Bag
                </nitrozen-button>
               </div>
                <div>
                <nitrozen-button v-flatBtn size="small" theme="secondary">Buy Now</nitrozen-button>
                </div>
            </div>
            <div class="notify-me" v-if="backStock">
              <p class="notify-me-para" v-if="backStock">{{ backStock.storefront_appearance.title_text }}</p>
              <div class="input">
                <nitrozen-input
                class="ellipses"
                  label="Your Email"
                  :placeholder="backStock.storefront_appearance.placeholder"
                ></nitrozen-input>
              </div>
              <div class="button">
                <nitrozen-button
                  v-bind:style="{ background: backStock.storefront_appearance.button_color }"
                  v-flatBtn
                  theme="secondary"
                  id="subscribe"
                >
                {{ backStock.storefront_appearance.button_text }}
                </nitrozen-button>
              </div>
              <p>We respect your privacy & do not share your email with anyone.</p>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import inlineSvg from "@/components/common/inline-svg.vue";
import { NitrozenButton, flatBtn, NitrozenInput } from "@gofynd/nitrozen-vue";
export default {
  name: 'storefront-preview',
  props: ["backStock", "limitedStock"],

  components: {
    NitrozenInput,
    "inline-svg": inlineSvg,
    NitrozenButton,
  },
  directives: {
    flatBtn,
  },
  data() {
    return {
      deviceType: "laptop",
      mapObj: [{ "{{product.quantity}}": "5" }],
    };
  },
  methods: {
    selectResponsiveIcons(value) {
      this.deviceType = value;
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}
.top {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.icon {
  margin-right: 10px;
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
}

h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #4f4f4f;
}
.content h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #4f4f4f;
  line-height: 46px;
}
.content p {
  margin-bottom: 12px;
  word-break: break-word; 
}
.live-preview {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #000;
}
.gray {
  background: #fafafa;
  padding: 24px;
}
.products {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 4px;
  padding: 16px 16px 16px 16px;
  box-sizing: border-box;
}
.images {
  width:calc(50% - 15px);
  margin-bottom: 15px;
  margin-right: 15px;
}
.images img {
  width: calc(50% - 6px);
  margin-bottom: 8px;
}
.image:nth-child(odd){
  
  margin-right: 12px;
}
.arrow {
  cursor: pointer;
}
.content {
  width: 50%;
}
.red {
  color: red;
}
.cart-buttons {
  margin-top: 10px;
}
.n-button {
  width: 100%;
  margin-bottom: 10px;
  background-color: #333333;
}
::v-deep .n-button-content {
  display: block;
  line-height: 39px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.color {
  background: #f2994a;
}
.notify-me {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  margin-top: 5px;
}
.notify-me-para {
  font-weight: 600;
  color: #4f4f4f;
  word-break: break-word; 
}
.input {
  margin: 25px 0px 15px 0px;
}
::v-deep .n-input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// for responsive
.mobile {
  .n-input
  {
    width: 100% !important;
  }
  .products {
    display: block;
    width: 68%;
    margin: auto;
  }
  .images {
    width: 100%;
    margin-right: 0px;
  }
  .content {
    width: 100%;
  }
}
.tablet {
  .n-input
  {
    width: 100% !important;
  }
  .products {
    width: 90%;
    margin: auto;
  }
}
</style>
