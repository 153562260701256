<template>
    <div class="pie-chart-label">
        <div class="label">{{ labelName }}</div>
        <div class="label-percentage">
            <label-fill-percentage :color="color" :percentage="labelPercentage" />
            <sh3>{{ labelPercentage }}%</sh3>
        </div>
    </div>
</template>
<script>
import labelFillPercentage from '../atoms/label-fill-percentage.vue';
export default {
    name: "pie-chart-label",
    props: {
        labelName: {
            type: String,
            default: '',
            required: true,
        },
        labelPercentage: {
            type: Number,
            default: 0,
            required: true
        },
        color: {
            type: String,
        }
    },
    components: {
        labelFillPercentage
    }
}
</script>
<style scoped lang="less">
@import '../../../helper/colors.less';
@import '../../../helper/utils.less';

.pie-chart-label {
    display: flex;
    color: @text-color-primary-and-secondary;
    .pxToRem(margin-top, 16);
    justify-content: space-between;
    white-space: nowrap;

    .label {
        color: @text-color-primary-and-secondary;
        font-family: Inter;
        .pxToRem(font-size, 14);
        font-style: normal;
        font-weight: 400;
        .pxToRem(line-height, 21);
    }

    .label-percentage {
        color: @text-color-primary-and-secondary;
        display: flex;
        align-items: center;
        .pxToRem(gap, 12);
        font-family: Inter;
        .pxToRem(font-size, 14);
        font-style: normal;
        font-weight: 600;
        .pxToRem(line-height, 18.2);
        .pxToRem(margin-left, 24);
    }
}
</style>