const formatUTCtoISTDate = (UTCDate) => {
    const date = new Date(UTCDate);

    const options = {
        hour12: false,
    };

    const formattedDate = date.toLocaleString("en-US", options).replaceAll(',', '');
    return formattedDate;
};

const getEndOfDate = (date) => {
    /** 
     * @TODO Change it next day and use $lt instead of $lte
     */
    const end_Time = new Date(date);
    //set time of date to 23hrs 59mins 59secs 999ms
    end_Time.setHours(23);
    end_Time.setMinutes(59);
    end_Time.setMinutes(59);
    end_Time.setSeconds(59);
    end_Time.setMilliseconds(999);

    return end_Time;
}

const getStartOfMinute = (day) => {
    const d = new Date(day);
    d.setSeconds(0);
    d.setMilliseconds(0);

    return d;
}

module.exports = { formatUTCtoISTDate, getEndOfDate, getStartOfMinute }
