<template>
  <div class="main">
    <div class="main-container">
      <div class="toggle-div">
        <div class="left">
          <h5>Limited Stock</h5>
          <p>
            Set up email template and storefront appearance for alert when products are available in few units
          </p>
        </div>
        <div class="right-button" v-if="applicationSettings?.limited_stock">
          <p>{{ applicationSettings.limited_stock.enabled ? 'Enabled' : 'Disabled' }}</p>
          <NitrozenToggleBtn
            :value="applicationSettings.limited_stock.enabled"
            @change="changeStatus()">
          </NitrozenToggleBtn>
        </div>
      </div>
      <div class="freeEmailLimitReached" v-if="freeSubscription && freeEmailLimitReached">
        <nitrozen-alert2 type="error">
          <template v-slot:message>
            <div class="message">
              <div>
                You've reached your monthly email limit of 100.
                Your customers are no longer receiving emails.
              </div>
              Upgrade your plan to continue sending unlimited emails.
            </div>
          </template>
          <template v-slot:action>
            <button class="action" @click="upgradeToPro()">
              Upgrade to PRO
            </button>
          </template>
        </nitrozen-alert2>
      </div>

      <div class="buttons">
        <div class="button1">
          <nitrozen-button
            class="color"
            :class="{ active: activeTab === 'email-template' }"
            @click="activeTab = 'email-template'"
            theme="secondary"
            >Email Template</nitrozen-button
          >
        </div>
        <div class="button2">
          <nitrozen-button
            class="color"
            :class="{ active: activeTab === 'storefront-appearance' }"
            @click="activeTab = 'storefront-appearance'"
            theme="secondary"
            >Storefront Appearance</nitrozen-button
          >
        </div>
        <div class="button3">
          <nitrozen-button
            class="color"
            :class="{ active: activeTab === 'settings' }"
            @click="activeTab = 'settings'"
            theme="secondary">
            Settings
            </nitrozen-button
          >
        </div>
      </div>
      <div class="components" v-if="limitedStockData">
        <template v-if="activeTab === 'storefront-appearance'">
          <limited-stock-storefront-customization
            :limitedStock="limitedStockData" 
            :errors="errors"
            :checkEmpty="checkEmpty"
            @save="patchLimitedStock()"
          />
          <storefront-preview
            :limitedStock="limitedStockData"
          />
        </template>

        <template>
          <email-template-customization
            v-if="activeTab === 'email-template'"
            :applicationSettings="applicationSettings"
            :limitedStock="limitedStockData"
            :errors="errors"
            :checkEmpty="checkEmpty"
            :validateForm="validateForm"
            :checkbuttonEmpty="checkbuttonEmpty"
            @save-html="onSaveHtmlEditor"
            @save="patchLimitedStock()"
          />

          <email-template-preview
            v-if="activeTab === 'email-template'"
            :limitedStock="limitedStockData"
          />
        </template>
        
        <limited-stock-settings
          v-if="activeTab === 'settings'"
          :limited_stock="applicationSettings.limited_stock"
          :saving="loader"
          @save="onSettingsSave"
        />
      </div>
    </div>
    <loader v-if="loader"></loader>
  </div>
</template>

<script>
import { NitrozenButton, flatBtn, NitrozenToggleBtn } from "@gofynd/nitrozen-vue";
import EmailTemplateCustomization from "../../components/email-template-customization.vue";
import loader from "@/components/common/loaderFullScreen";
import LimitedStockStorefrontCustomization from "../../components/limited-stock-storefront-customization.vue";
import StorefrontPreview from "../../components/storefront-preview.vue";
import EmailTemplatePreview from "../../components/email-template-preview.vue";
import LimitedStockSettings from "../../components/limited-stock-settings.vue";
import NitrozenAlert2 from "../../components/common/nitrozen-alert2.vue";

import MainService from "../../services/main-service";
import store from "../../store";

export default {
  name: "limited-stock",
  components: {
    EmailTemplateCustomization,
    NitrozenButton,
    NitrozenToggleBtn,
    loader,
    LimitedStockStorefrontCustomization,
    StorefrontPreview,
    EmailTemplatePreview,
    LimitedStockSettings,

    NitrozenAlert2,
  },
  data() {
    return {
      errors:{},
      applicationSettings:{},
      limitedStockData: null,
      /**
       * @type {'email-template' | 'storefront-appearance' | 'settings'}
       */
      activeTab: 'email-template',
      isActive: false,
      loader: false,
    };
  },
  directives: {
    flatBtn,
  },
  async mounted() {
    await this.postApplications();
    // await this.onClickEmailTemplate();
  },
  async created(){
    await this.getLimitedStock();
  },
  computed: {
    freeSubscription() {
      const subscription = store.getters.subscription;
      return subscription?.status !== 'active' || !subscription.platform_subscription_id
    },
    freeEmailLimitReached() {
      return store.getters.monthlyFreeEmailLimitReached
    }
  },
  methods: {
    validateForm() {
      let formValid = true;
      formValid = this.checkEmpty("storefront_appearance","limited_stock_text") && formValid;
      formValid = this.checkEmpty("email_template","subject") && formValid;
      formValid = this.checkbuttonEmpty("email_template","button_text") && formValid;
      return formValid;
    },
    checkEmpty(parentKey,key) {
      if (this.limitedStockData[parentKey][key] == "") {
        this.$set(this.errors, key, true);
        return false;
      }
      this.$set(this.errors, key, false);
      return true;
    },
    checkbuttonEmpty(parentKey,key) {
      if (this.limitedStockData[parentKey].button_customization[key] == "") {
        this.$set(this.errors, key, true);
        return false;
      }
      this.$set(this.errors, key, false);
      return true;
    },
    async postApplications() {
      const params = {
        company_id: this.$route.params.company_id,
        application_id: this.$route.params.application_id,
      };
      try {
        this.loader = true;
        let res = await MainService.postApplication(params);
        if (res.data.length) {
          this.applicationSettings["application_id"] = res.data[0].application_id;
          this.applicationSettings["company_id"] = res.data[0].company_id;
          this.applicationSettings["enabled"] = res.data[0].enabled;
          this.applicationSettings["limited_stock"] = res.data[0].limited_stock;
          this.applicationSettings["out_stock"] = res.data[0].out_stock;
          this.applicationSettings["tagId"] = res.data[0].tagId;
          this.applicationSettings["test_email"] = res.data[0].test_email;
        }
        else{
          this.applicationSettings["test_email"] = "";
        }
        this.loader = false;
      } catch (e) {
        this.loader = false;
        this.$snackbar.global.showError("Something went wrong! please try again");
      }
    },
    async getLimitedStock() {
      const params = {
        application_id: this.$route.params.application_id,
      };
      try {
        this.loader = true;
        let res = await MainService.getLimited_stock_config(params);
        if (res.data) {
          this.limitedStockData = res.data;
        }
        this.loader = false;
      } catch (e) {
        this.loader = false;
        this.$snackbar.global.showError("Something went wrong! please try again");
      }
    },
    async patchLimitedStock() {
      let validForm = this.validateForm();
      if (!validForm) {
        this.$snackbar.global.showError("Please fill all fields");
        return;
      }
      const body = this.limitedStockData;
      body.application_id = this.$route.params.application_id;
      try {
        this.loader = true;
        let res = await MainService.patchLimited_stock_config(body);
        if (res) {
          this.$snackbar.global.showSuccess("Saved successfully");
          this.loader = false;
        }
      } catch (e) {
        this.loader = false;
        this.$snackbar.global.showError("Something went wrong! please try again");
      }
    },
    // onClickEmailTemplate() {
    //   this.activeTab === 'email-template';
    // },
    // onClickStorefront() {
    //   this.activeTab === 'storefront-appearance';
    // },
    // onClickSettings() {
    //   this.activeTab === 'settings';

    // },
    onSaveHtmlEditor(value){
      this.limitedStockData.email_template.emailHtml = value;
    },
    changeStatus() {
      this.applicationSettings.limited_stock.enabled = !this.applicationSettings.limited_stock.enabled;
      this.onSettingsSave(this.applicationSettings.limited_stock);
    },
    async onSettingsSave(limited_stock) {
      const apiData = {
        ...this.applicationSettings,
        limited_stock
      };
      this.loader = true;
      try {
        this.applicationSettings = (await MainService.patchApplicationSettings(apiData)).data;
        this.$snackbar.global.showSuccess("Saved successfully");
      } catch (err) {
        console.error(err);
        this.$snackbar.global.showError("Something went wrong! please try again");
      } finally {
        this.loader = false;
      }
    },
    upgradeToPro() {
      this.$router.push(`/company/${this.$route.params.company_id}/pricing`);
    }
  },
};
</script>

<style lang="less" scoped>
@import 'src/helper/utils';

.main {
  margin: 20px;
  box-sizing: border-box;
}

.main-container {
  width: 100%;
  background-color: #fff;
  padding: 24px;
  justify-content: space-between;
  border-radius: 12px;
  box-sizing: border-box;
}

.toggle-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.freeEmailLimitReached {
  margin-bottom: 20px;
}

.left h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #41434c;
}

.left p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #666666;
}

.right-button {
  display: flex;
  align-items: center;
  p {
    color: #2E31BE;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    margin-right: 0.8rem;
  }
}

.toggle-btn {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.enable {
  color: #2e31be;
}

.toggle-btn h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
}

.components {
  display: grid;
  .pxToRem(gap, 24);
  grid-template-columns: 2fr 3fr;
}

.two-components {
  width: 50%;
}

.border {
  border: 1px solid #e0e0e0;
}
// for buttons
.buttons {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 24px;
  ::v-deep .n-button {
    letter-spacing: normal;
  }
}
.color {
  color: #4f4f4f;
  padding: 0px 25px;
}
.active {
  color: #2e31be;
  border-bottom: 2px solid #2e31be;
}
</style>
