<template>
    <div class="label-fill-percentage">
        <div class="colored" :style="coloredStyle"></div>
    </div>
</template>
<script>
export default {
    name: "label-fill-percentage",
    props: {
        percentage: {
            type: Number,
            default: 0,
            required: true,
        },
        color: {
            type: String,
            default: "blue",
            required: true
        }
    },
    computed: {
        coloredStyle() {
            return {
                width: `${this.percentage}%`,
                backgroundColor: this.color,

            };
        },
    },
}
</script>
<style scoped lang="less">
@import 'src/helper/utils.less';
@import '../../../helper/colors.less';

.label-fill-percentage {
    display: flex;
    .pxToRem(border-radius, 20);
    .pxToRem(width, 100);
    .pxToRem(height, 8);
    background-color: #E0E0E080;

    .colored {
        height: inherit;
        .pxToRem(border-radius, 20);
    }
}
</style>