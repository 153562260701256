<template>
    <div class="nitrozen-alert2-wrapper" :class="type">
        <div class="icon">
            <!-- @todo refactor error icon -->
            <svg data-v-e1c23dae=""
                    width="26.67"
                    height="26.67"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none">
                <path id="error_colored"
                    d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM15.71 14.29C15.8037 14.383 15.8781 14.4936 15.9289 14.6154C15.9797 14.7373 16.0058 14.868 16.0058 15C16.0058 15.132 15.9797 15.2627 15.9289 15.3846C15.8781 15.5064 15.8037 15.617 15.71 15.71C15.617 15.8037 15.5064 15.8781 15.3846 15.9289C15.2627 15.9797 15.132 16.0058 15 16.0058C14.868 16.0058 14.7373 15.9797 14.6154 15.9289C14.4936 15.8781 14.383 15.8037 14.29 15.71L12 13.41L9.71 15.71C9.61704 15.8037 9.50644 15.8781 9.38458 15.9289C9.26272 15.9797 9.13202 16.0058 9 16.0058C8.86799 16.0058 8.73729 15.9797 8.61543 15.9289C8.49357 15.8781 8.38297 15.8037 8.29 15.71C8.19628 15.617 8.12188 15.5064 8.07111 15.3846C8.02034 15.2627 7.99421 15.132 7.99421 15C7.99421 14.868 8.02034 14.7373 8.07111 14.6154C8.12188 14.4936 8.19628 14.383 8.29 14.29L10.59 12L8.29 9.71C8.1017 9.5217 7.99591 9.2663 7.99591 9C7.99591 8.7337 8.1017 8.4783 8.29 8.29C8.47831 8.1017 8.7337 7.99591 9 7.99591C9.26631 7.99591 9.5217 8.1017 9.71 8.29L12 10.59L14.29 8.29C14.4783 8.1017 14.7337 7.99591 15 7.99591C15.2663 7.99591 15.5217 8.1017 15.71 8.29C15.8983 8.4783 16.0041 8.7337 16.0041 9C16.0041 9.2663 15.8983 9.5217 15.71 9.71L13.41 12L15.71 14.29Z" fill="#F50031">
                </path>
            </svg>
        </div>
        <slot name="message">
            Some error has occurred
        </slot>
        <slot name="action"></slot>
    </div>
</template>
<script>
export default {
    name: "nitrozen-alert2",
    components: {
        
    },
    props: {
        /**
         * @type {'info' | 'error' | 'warning' }
         */
        type: {
            type: String,
            default: 'error',
            required: true,
        },
    },
    data() {
        return {
            // variableName: ''
        }
    },
    computed: {},
    methods: {},
    mounted() {
        // @ts-check
        
    },
    destroyed() {
        // @ts-check
        
    }
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';

.nitrozen-alert2-wrapper {
    display: grid;
    grid-template-columns: 26px 1fr auto;
    .pxToRem(gap, 4);
    padding: 0.5rem 1rem;
    .pxToRem(min-height, 48);
    .pxToRem(border-radius, 8);
    align-items: center;

    .message,
    .action {
        .pxToRem(font-size, 12);
    }
}

.message {
    color: var(--TypographyPrimaryColor, rgba(0, 0, 0, 0.65));
    display: flex;
    flex-wrap: wrap;
}

.action {
    display: flex;
    align-items: center;
    justify-content: center;
    .pxToRem(border-radius, 4);
    padding: 0.4rem 1.2rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
}
.error {
    background: var(--ColorFeedbackError20, #fee6ea);
    border: 0.1rem solid var(--ColorFeedbackError50, #f50031);

    .action {
        color: #ffffff;
        background-color: var(--ColorFeedbackError50, #f50031);
    }
}


</style>
