<template>
    <div class="empty-state-wrapper">
        <svg width="326" height="203" viewBox="0 0 326 203" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.109375" y="139.963" width="325.782" height="63.0282" fill="url(#paint0_radial_21510_10283)"/>
            <path d="M157.68 114.182V81.8491L215.387 86.7604L191.649 104.768L157.68 114.182Z" fill="#7EA0F9"/>
            <path d="M158.089 114.591V81.8491L106.52 86.8226L127.733 105.059L158.089 114.591Z" fill="#B5CAFD"/>
            <path d="M215.387 139.966L162.591 162.067V98.6292L215.387 86.7603V139.966Z" fill="#B5CAFD"/>
            <path d="M106.52 139.966L163 162.067V98.6292L106.52 86.7603V139.966Z" fill="#CCDBFF"/>
            <path d="M152.358 127.278L162.59 98.6292L106.519 86.7603L98.334 108.861L152.358 127.278Z" fill="#DCE6FF"/>
            <path d="M175.278 127.278L162.59 98.6292L215.386 86.7603L224.8 110.907L175.278 127.278Z" fill="#DCE6FF"/>
            <path d="M145.811 32.9429V38.4756L147.822 34.5236L145.811 32.9429Z" fill="#B5CAFD"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M173.725 54.7405C158.308 54.7405 145.81 47.3844 145.81 38.3101C145.81 29.2358 158.308 20.2031 173.725 20.2031C189.142 20.2031 201.64 29.2358 201.64 38.3101C201.64 47.3844 189.142 54.7405 173.725 54.7405ZM173.725 49.7119C161.826 49.7119 152.181 44.4575 152.181 37.9759C152.181 31.4943 161.826 26.2399 173.725 26.2399C185.623 26.2399 195.269 31.4943 195.269 37.9759C195.269 44.4575 185.623 49.7119 173.725 49.7119Z" fill="#B5CAFD"/>
            <path d="M189.987 51.7232V46.7773H197.532L212.931 53.6112V57.7039C212.931 59.0452 212.202 59.7708 210.442 60.9444C209.033 61.8833 206.949 61.3356 206.082 60.9444L189.987 51.7232Z" fill="#B4CAFF"/>
            <path d="M201.64 33.1177V38.3989L199.544 34.6266L201.64 33.1177Z" fill="#B5CAFD"/>
            <ellipse opacity="0.5" cx="173.232" cy="35.6053" rx="21.6915" ry="11.869" fill="#F1F4FB"/>
            <path d="M151.061 34.0519L150.722 34.7868C150.722 34.7868 150.823 34.5131 151.061 34.0519L155.633 24.1457L173.232 19.2344L189.603 21.69L196.97 29.057L195.333 36.8332C195.333 36.8332 196.155 23.7364 172.004 23.7364C157.52 23.7364 152.334 31.5823 151.061 34.0519Z" fill="#7EA0F9"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M173.725 49.7155C158.308 49.7155 145.81 42.4344 145.81 33.4527C145.81 24.471 158.308 17.1899 173.725 17.1899C189.142 17.1899 201.64 24.471 201.64 33.4527C201.64 42.4344 189.142 49.7155 173.725 49.7155ZM173.725 46.0284C161.795 46.0284 152.124 40.3987 152.124 33.4541C152.124 26.5095 161.795 20.8798 173.725 20.8798C185.654 20.8798 195.325 26.5095 195.325 33.4541C195.325 40.3987 185.654 46.0284 173.725 46.0284Z" fill="#C9D9FF"/>
            <path d="M212.035 52.3097L196.023 42.9209L189.485 46.4417C194.431 49.3198 204.658 55.2772 205.999 56.082C207.34 56.8867 208.737 56.8644 209.268 56.7526C209.911 56.6129 211.448 56.0317 212.454 54.8246C213.503 53.5659 212.594 52.645 212.035 52.3097Z" fill="#C9D9FF"/>
            <path d="M91.7861 64.6597C92.8775 67.661 97.1067 72.7633 105.292 69.1617C115.524 64.6597 145.81 54.8371 151.54 93.7181" stroke="#2E31BE" stroke-width="0.818548" stroke-linecap="round" stroke-dasharray="3.27 3.27"/>
            <path d="M207.201 79.3936C206.349 82.4372 200.653 84.7141 191.649 83.4863C182.531 82.243 169.139 77.3472 163 95.7644" stroke="#2E31BE" stroke-width="0.818548" stroke-linecap="round" stroke-dasharray="3.27 3.27"/>
            <circle cx="130.667" cy="47.0607" r="2.86492" fill="#2E31BE"/>
            <circle cx="187.147" cy="69.98" r="2.04637" fill="#EAF0FF"/>
            <circle cx="143.764" cy="2.04051" r="2.04637" fill="#EAF0FF"/>
            <circle cx="208.429" cy="76.5286" r="1.22782" fill="#2E31BE"/>
            <circle cx="121.664" cy="81.4399" r="1.6371" stroke="#B5CAFD" stroke-width="0.818548"/>
            <path d="M105.701 33.1479V38.4685" stroke="#B5CAFD" stroke-width="0.818548" stroke-linecap="round"/>
            <path d="M108.361 35.8081L103.041 35.8081" stroke="#B5CAFD" stroke-width="0.818548" stroke-linecap="round"/>
            <path d="M91.5312 59.8936L89.6056 63.4765" stroke="#2E31BE" stroke-width="0.818548" stroke-linecap="round"/>
            <path d="M92.3604 62.6431L88.7774 60.7174" stroke="#2E31BE" stroke-width="0.818548" stroke-linecap="round"/>
            <path d="M166.738 70.6172L164.582 75.4811" stroke="#2E31BE" stroke-width="0.818548" stroke-linecap="round"/>
            <path d="M168.092 74.1279L163.228 71.9714" stroke="#2E31BE" stroke-width="0.818548" stroke-linecap="round"/>
            <defs>
            <radialGradient id="paint0_radial_21510_10283" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(159.726 124.001) rotate(91.7899) scale(39.3095 203.184)">
            <stop stop-color="#9BB4FF"/>
            <stop offset="1" stop-color="#EFF3FF" stop-opacity="0"/>
            </radialGradient>
            </defs>
        </svg>

        <p class="em">{{ text }}</p>
    </div>
</template>
<script>
export default {
    name: "empty-state",
    props: {
        text: {
            type: String,
            default: 'Empty State',
            required: true,
        },
    }
}
</script>
<style scoped lang="less">
@import '../../../helper/colors.less';
@import '../../../helper/utils.less';

.empty-state-wrapper {
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    p {
        color: @gray-2;
        text-align: center;
        font-family: Inter;
        .pxToRem(font-size, 12);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        position: relative;
        .pxToRem(top, -16);
    }
}
</style>
