<template>
  <div class="breadcrumb" v-if="breadcrumbList && breadcrumbList.length">
    <ul>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{'linked': !!breadcrumb.link}">
        <span v-if="breadcrumb.name != 'AnalyticsFeatures'">{{ breadcrumb.name }}</span><span v-if="breadcrumb.name == 'AnalyticsFeatures'"> {{application_name}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: 'Breadcrumb',
  data () {
    return {
      breadcrumbList: [{}]
    }
  },
  created(){ 
    this.updateList() 
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
 computed: {
      ...mapState(["application_name"])
  },
  methods: {
    routeTo (pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) {
          this.$router.push({name:this.breadcrumbList[pRouteTo].name})
      }
    },
    updateList () {
         this.breadcrumbList = this.$route.meta.breadcrumb }
  }
}
</script>

<style scoped>
  .breadcrumb {
      margin: 24px 0px 24px 24px;
  }
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul > li {
    display: flex;
    float: left;
    height: 10px;
    width: auto;
    font-size: 14px;
    cursor: default;
    align-items: center;
  }
  ul > li:not(:last-child)::after {
    content: '>';
    float: right;
    font-size: .8em;
    margin: 0 .5em;
    cursor: default;
  }
  .linked {
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    color: #2e31be;
  }
</style>