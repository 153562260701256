<template>
    <div class="webhooks-form-wrapper">
        <div class="webhooks-form">
            <div class="header">
                <h5>Details</h5>
                <NitrozenToggleBtn :value="subscriber.enabled" @change = "(event)=>subscriber.enabled = event.target.checked"/>
            </div>
            <div class="sub-header">
                <p> To consume our webhooks, you must provide us a URL on which we can send an HTTP POST request containing
                    the information of the subscribed event.
                </p>
            </div>

            <form ref="webhookForm" class="form">
                <div>
                    <NitrozenInput label="Name" :required="true" v-model="subscriber.name"
                        tooltipText="Name - Title of subscriber" maxlength=30 class="nit-input" />
                </div>
                <div>
                    <NitrozenInput label="Webhook Url" ref="webhookUrlInput" type="url" :required="true"
                        v-model="subscriber.webhook_url" :showTooltip="true" @input="onWebhookUrlChange" />
                    <NitrozenError v-if="showWebhookUrlError"> Enter valid
                        Webhook Url</NitrozenError>
                </div>
                <div>
                    <NitrozenInput label="Alert Email" :required=true v-model="subscriber.alert_email" :showTooltip="true"
                        tooltipText="Alert Email- Email of webhook handler. e.g. It will be used in case webhook is disabled by system after multiple failed delivery attempts"
                        class="nit-input" type="email" ref="alertEmailRef" @input="onAlertEmailChange" />
                    <NitrozenError v-if="showAlertEmailError">Enter Valid Email</NitrozenError>
                </div>
                <div class="custom-headers req-headers">
                    <NitrozenCheckBox :value="customHeaderEnable" @change="onToggleCustomHeader" class="nit-checkbox">
                        <span class="checkbox-label">Custom
                            Headers</span>
                    </NitrozenCheckBox>
                    <p>Send any headers in your webhook request</p>
                    <div v-if="customHeaderEnable">
                        <div v-for="customHeader in subscriber.custom_headers" :key="customHeader.key"
                            class="custom-header">
                            <NitrozenInput label="Key" v-model="customHeader.key" class="nit-input" style="width: 50%"
                                maxlength=500 />
                            <NitrozenInput label="Value" v-model="customHeader.value" class="nit-input" style="width: 50%"
                                maxlength=500 />
                        </div>
                    </div>
                </div>
                <div class="Authentication req-headers">
                    <NitrozenCheckBox :value="authenticationEnable" @change="onToggleAuthentication" class="nit-checkbox">
                        <span class="checkbox-label">Authentication</span>
                    </NitrozenCheckBox>
                    <p>You can now authenticate the Webhook URL with a secret password</p>
                    <div v-if="authenticationEnable">
                        <NitrozenInput label="Secret"
                            :required=true
                            v-model="subscriber.authentication"
                            class="nit-input"
                            type="password"
                            style="width: 50%" />
                    </div>
                </div>
                <div class="events">
                    <div>
                        <h5>Events</h5>
                        <p>Select the events you wish to receive.</p>
                    </div>

                    <div class="event-group" v-for="(value, group) in subscriber.eventGroup" :key="group">
                        <!-- <div>
                            <h6> {{ subscriber.eventGroup[group].name }} </h6>
                            <p> {{ subscriber.eventGroup[group].description }} </p>
                        </div> -->
                        <div class="event-group-items">
                            <div class="event-type" v-for="(value, eType) in subscriber.eventGroup[group].eventType"
                                :key="eType">
                                <NitrozenCheckBox :value="subscriber.eventGroup[group].eventType[eType].enabled"> {{
                                    subscriber.eventGroup[group].eventType[eType].name }}</NitrozenCheckBox>
                                <NitrozenDropdown
                                    :items="subscriber.eventGroup[group].eventType[eType].events.map(event => ({ text: event.event, value: event.event }))"
                                    :value="subscriber.eventGroup[group].eventType[eType].events.filter((event => event.enabled)).map(event => event.event)"
                                    placeholder="Select Event Types" :multiple="true"
                                    @change="(e) => onEventChange(e, group, eType)" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <NitrozenButton
                v-if="subscription"
                v-flatBtn
                class="nit-button"
                size="small"
                theme="secondary"
                @click="onSave">
                Save
            </NitrozenButton>
            <NitrozenButton
                v-else
                v-flatBtn
                class="nit-button outline"
                size="small"
                theme="secondary"
                @click="upgradeToPro()">
                Upgrade to Pro
            </NitrozenButton>
        </div>
    </div>
</template>

<script>
import {
    NitrozenToggleBtn,
    NitrozenInput,
    NitrozenCheckBox,
    NitrozenButton,
    flatBtn,
    NitrozenDropdown,
    NitrozenError
} from '@gofynd/nitrozen-vue';
import MainService from '../../services/main-service';
import { eventGroupDefault } from '../../../common/defaults/webhooks';
import store from "../../store";

export default {
    name: "webhooks-form",
    components: {
        NitrozenToggleBtn,
        NitrozenInput,
        NitrozenCheckBox,
        NitrozenButton,
        NitrozenDropdown,
        NitrozenError
    },
    directives: {
        flatBtn,
    },
    props: {
        propName: {
            type: String,
            default: '',
            required: true,
        },
    },
    data() {
        return {
            subscriber: {
                name: '',
                enabled: true,
                webhook_url: '',
                alert_email: '',
                authentication: '',
                custom_headers: [
                    { key: '', value: '' }
                ],
                eventGroup: eventGroupDefault,
            },
            type: this.$route.query.type,
            customHeaderEnable: false,
            authenticationEnable: false,
            showWebhookUrlError: false,
            showAlertEmailError: false,
        }
    },
    computed: {
        subscription() {
            const subs = store.getters.subscription;
            return subs.status === 'active' && subs.platform_subscription_id;
        }
    },
    methods: {
        onWebhookUrlChange() {
            this.showWebhookUrlError = !this.$refs.webhookUrlInput.$el.querySelector('input').checkValidity();
        },
        onAlertEmailChange() {
            this.showAlertEmailError = !this.$refs.alertEmailRef.$el.querySelector('input').checkValidity();
        },
        onToggleCustomHeader() {
            this.customHeaderEnable = !this.customHeaderEnable;
        },
        onToggleAuthentication() {
            this.authenticationEnable = !this.authenticationEnable;
            // When unchecking checkbox removing secret
            if (this.subscriber.authentication.trim()) {
                this.subscriber.authentication = '';
            }
        },
        async onSave() {
            try {
                // if Custom Header are disabled, Removing saved custom headers too
                if (!this.customHeaderEnable) {
                    this.subscriber.custom_headers = [];
                }

                //check Validity of Custom Headers
                this.subscriber.custom_headers = this.subscriber.custom_headers.filter(customHeader => {
                    //if either of key value are not present throw error
                    if ((!customHeader.key.trim() && customHeader.value.trim()) || (customHeader.key.trim() && !customHeader.value.trim())) {
                        throw Error('Enter Key and Value of Custom Headers');
                    }
                    //if both the key & value are not present, Ignoring them
                    return customHeader.key.trim() && customHeader.value.trim();
                })

                //If Alert Email is not entered Throw Error
                if (!this.subscriber.alert_email.trim() || this.showAlertEmailError) {
                    throw Error('Enter Valid Email');
                }

                //If Name Or Subscriber is not entered Throw Error
                if (!this.subscriber.name.trim() || !this.subscriber.webhook_url.trim() || this.showWebhookUrlError) {
                    throw Error('Enter Name And Webhook Url');
                }

                if (this.$route.query.id) {
                    // Trigger Update Subscriber
                    await MainService.updateWebhookSubscriber(this.$route.query.id, this.subscriber);
                    this.$snackbar.global.showSuccess("Updated Successfully");
                } else {
                    // Create Subscriber
                    await MainService.addWebhookSubscriber(this.subscriber);
                    this.$snackbar.global.showSuccess("Added Successfully");
                }
            } catch (e) {
                console.log("error", e);
                if (e.message) {
                    this.$snackbar.global.showError(e.message);
                } else { 
                    this.$snackbar.global.showError("Enter correct details"); 
                }
            }
        },
        onEventChange(event, group, eType) {
            this.subscriber.eventGroup[group].eventType[eType].events.forEach((e, idx) => {
                if (event.includes(e.event)) {
                    this.subscriber.eventGroup[group].eventType[eType].events[idx].enabled = true;
                } else {
                    this.subscriber.eventGroup[group].eventType[eType].events[idx].enabled = false;
                }
            })
        },
        upgradeToPro() {
            this.$router.push(`/company/${this.$route.params.company_id}/pricing`);
        }
    },
    mounted() {
        if (this.type === 'edit') {
            MainService.getWebhookSubscriber(this.$route.query.id).then(res => {
                this.subscriber = res.data;
                this.subscriber.custom_headers.push({ key: '', value: '' });
                //set checkBox Values
                this.customHeaderEnable = Boolean(this.subscriber.custom_headers?.length);
                this.authenticationEnable = Boolean(this.subscriber.authentication?.trim());
            }).catch(err =>
                console.log('Error while Fetching Subscriber', err)
            );
        }
    }
}
</script>
<style scoped lang="less">
@import '../../helper/colors.less';
@import '../../helper/utils.less';

.webhooks-form-wrapper {
    width: 100%;
    display: flex;
    .pxToRem(padding, 24);
    justify-content: center;

    h5 {
        .pxToRem(font-size, 18);
        color: @textPrimary;
        font-weight: 700;
    }

    h6 {
        .pxToRem(font-size, 15);
        color: @textPrimary;
        font-weight: 700;
    }

    p {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        .pxToRem(font-size, 13);
        .pxToRem(line-height, 20);
        color: @textSecondary;
    }

    .webhooks-form {
        width: 66%;
        .pxToRem(border-radius, 4);
        .pxToRem(padding-top, 24);
        .pxToRem(padding-bottom, 24);
        .pxToRem(padding-left, 40);
        .pxToRem(padding-right, 40);
        background-color: @white;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .sub-header {
            .pxToRem(margin-top, 10);
        }

        .form {
            .pxToRem(margin-top, 35);
            display: flex;
            flex-direction: column;
            .pxToRem(gap, 30);

            // .nit-input {
            //     .pxToRem(margin-top, 35);
            // }

            .custom-header {
                display: flex;
                .pxToRem(gap, 12);
            }

            .req-headers {
                .pxToRem(padding, 16s);
                .pxToRem(border-radius, 4);
                border: 1px solid @gray-5;

                .nit-checkbox {
                    display: flex;
                    align-items: center;

                    .checkbox-label {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        .pxToRem(font-size, 14);
                        line-height: 140%;
                        color: @textPrimary;
                    }
                }
            }
        }

        .events {
            display: flex;
            flex-direction: column;
            .pxToRem(gap, 24);
        }

        .event-group-items {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .pxToRem(column-gap, 24);
            .pxToRem(row-gap, 36);

            .event-type {
                display: flex;
                flex-direction: column;
                .pxToRem(gap, 12);
            }
        }
    }

    .nit-button {
        .pxToRem(margin-top, 24);
    }

    .nit-button.outline {
        background: transparent;
        color: @blue-primary;
        border: 1px solid @blue-primary;
        padding: 9.5px 16px;
        .pxToRem(border-radius, 4);
        display: flex;

        ::v-deep .n-button-content {
            .pxToRem(gap, 4);
            white-space: nowrap;
        }
    }
    
}
</style>
