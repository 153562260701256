const eventGroupDefault = {
  sales_channel: {
    name: "Sales Channel",
    description: "Select channels and events you wish to receive for that channel.",
    eventType: {
      product: {
        name: "Product",
        description: "",
        enabled: true,
        events: [
          {
            event: "limited_stock",
            enabled: true,
          },
          {
            event: "out_of_stock",
            enabled: true,
          }
        ],
      }
    }
  },
}


module.exports = { eventGroupDefault }