import urlJoin from "url-join";
import root from "window-or-global";
import { getFormattedDate } from "../helper/utils";
let envVars = root.env || {};

envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL = `${root.location.protocol}//${root.location.hostname}`;
if (
  root &&
  root.process &&
  root.process.env &&
  root.process.NODE_ENV == "test"
) {
  envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL = 'https://api.xyz.com';
}

const Endpoints = {
  TEST_EMAIL(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/test_email')
  },
  PROXY_URL(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/proxy')
  },
  LIMITED_STOCK_CONFIG(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/limited_stock_config')
  },
  OUT_STOCK_CONFIG(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/out_stock_config')
  },
  THEME_BUTTON_COLOR() {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/theme/button_color')
  },
  GET_ALL_APPLICATIONS(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/sales_channels')
  },
  APPLICATIONS_SETTINGS(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/sales_channels/settings')
  },
  CRON_STATUS_POSTMAN(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/cron_status')
  },
  OUT_STOCK_EMAIL_POSTMAN(params){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/out_stock_email/${params.application_id}`)
  },
  OUT_STOCK_EMAIL_ARCHEIVE_POSTMAN(params){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/out_stock_email_archive/${params.application_id}`)
  },
  LIMITED_STOCK_SUCCESS_EMAIL_POSTMAN(params){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/limited_stock_success_email/${params.application_id}`)
  },
  PLANS() {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1/plans`)
  },
  SUBSCRIPTION() {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1/subscription`)
  },
  SUBSCRIPTION_BY_PLAN_ID(plan_id = '') {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1/subscription/${plan_id}`)
  },
  SUBSCRIPTION_STATUS(subscription_id) {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1/subscription/${subscription_id}/status/`)
  },
  ANALYTICS_DATA(dateRange){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1.0/analytics?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`)
  },
  MOST_WANTED_PRODUCTS(count){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/analytics/most_wanted_products?count=${count}`)
  },
  ITEMS_BOUGHT_VS_NOTIFIED(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/analytics/items_bought_notified`);
  },
  LIMITED_STOCK_PRODUCTS(pageOptions){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/analytics/limited_stock_products?page_size=${pageOptions.pageSize}&page_no=${pageOptions.pageNo}`)
  },
  BACK_IN_STOCK_PRODUCTS(pageOptions){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/out_stock_products_report?page_size=${pageOptions.pageSize}&page_no=${pageOptions.pageNo}`)
  },
  PRODUCT_WAIT_LIST(pageOptions){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/analytics/back_in_stock_product_wait_list?item_id=${pageOptions.item_id}&size=${pageOptions.size}&in_stock=${pageOptions.in_stock}`)
  },
  MONTHLY_EMAIL_COUNT() {
    const today = getFormattedDate(new Date());
    const thirtyDaysAgo = getFormattedDate(new Date(new Date() - (30 * 24 * 60 * 60 * 1000)))
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/analytics/monthly_email_count?start_date=${thirtyDaysAgo}&end_date=${today}`);
  },
  ADD_CUSTOMER_TO_WAIT_LIST(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/analytics/add_customer_to_wait_list`);
  },
  SEND_PRODUCT_REMINDER(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/analytics/send_product_reminder`);
  },
  PRODUCTS_LIST(pageOptions) {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1.0/sales_channels/products?page_no=${pageOptions.pageNo}&page_size=${pageOptions.pageSize}&search_text=${pageOptions.searchText}`);
  },
  WEBHOOK_SUBSCRIBER_LIST(pageOptions){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL,`/api/v1.0/webhooks_extension?page_no=${pageOptions.pageNo}&page_size=${pageOptions.pageSize}`);
  },
  WEBHOOK_SUBSCRIBER(id){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1.0/webhooks_extension/${id}`);
  },
  CREATE_WEBHOOK_SUBSCRIBER(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1.0/webhooks_extension`);
  },

  BACK_IN_STOCK_DEFAULT_EMAIL(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/out_stock_config/default_email');
  },
  LIMITED_STOCK_DEFAULT_EMAIL(){
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, '/api/v1.0/limited_stock_config/default_email');
  },
  ITEM_INVENTORY(item) {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1.0/platform_api/item_inventory?item_id=${item.item_id}&size=${item.size}`);
  },
  /**
   * 
   * @param {number} page_no 
   * @param {number} page_size 
   * @returns {string} url
   */
  EMAIL_PROVIDERS(page_no, page_size) {
    return urlJoin(envVars.PRODUCT_STOCK_NOTIFICATION_MAIN_URL, `/api/v1.0/platform_api/email_providers?page_no=${page_no}&page_size=${page_size}`);
  }
};

export default Endpoints;
