<template>
  <div class="cron-status-main">
    <div class="top">
      <div class="date">
        <span>From Date</span>
        <date-picker
          :disabled-date="
            (date) => date >= new Date().setDate(new Date().getDate() - 1)
          "
          v-model="fromDate"
          type="date"
          placeholder="Choose date"
        ></date-picker>
        <span>To Date</span>
        <date-picker
          @change="getCron"
          :disabled-date="(date) => date <= new Date(fromDate)"
          v-model="toDate"
          type="date"
          placeholder="Choose date"
          :disabled="fromDate == ''"
        ></date-picker>
      </div>
      <div class="dropdown-sorting">
        <nitrozen-dropdown
          v-if="cronStatus.length"
          v-model="status"
          :items="sorting"
          @change="resetData"
        >
        </nitrozen-dropdown>
      </div>
    </div>
    <div class="img" v-if="!cronStatus.length">
      <div class="no-screen-image">
        <img src="../../assets/pngs/empty-state.png" alt="image"/>
        <p>Select a date range to see Cron's data</p>
      </div>
    </div>
    <table style="width: 90%">
      <tr v-if="cronStatus.length">
        <th id="cron_type">Cron type</th>
        <th id="run_time">Run time</th>
        <th id="update_time">Update time</th>
        <th id="error">Error</th>
      </tr>
      <tr v-for="item in cronStatus" :key="item">
        <td>{{ item.cron_type }}</td>
        <td>{{ modifiedDate(item.created_at) }}</td>
        <td>{{ modifiedDate(item.updated_at) }}</td>
        <td v-if="item.error">{{ item.error }}</td>
        <td v-else>No Error</td>
      </tr>
    </table>
    <nitrozen-pagination
      v-if="cronStatus.length"
      v-model="PaginationConfig"
      :pageSizeOptions="pageSizeOptions"
      @change="paginationChange"
      class="pagination-config"
    >
    </nitrozen-pagination>
  </div>
</template>

<script>
import { NitrozenDropdown, NitrozenPagination } from "@gofynd/nitrozen-vue";
import MainService from "../../services/main-service";
import DatePicker from "vue2-datepicker";
import moment from "moment";

export default {
  name: "tabularData",
  components: {
    DatePicker,
    NitrozenDropdown,
    NitrozenPagination,
  },
  data() {
    return {
      cronStatus: [],
      fromDate: "",
      toDate: "",
      status: "Back in stock",
      sorting: [
        { text: "Back in stock", value: "Back in stock" },
        { text: "Limited stock", value: "Limited stock" },
      ],
      PaginationConfig: {
        limit: 10,
        total: 1,
        current: 1,
      },
      pageSizeOptions: [10, 20, 50, 100],
    };
  },
  methods: {
    async getCronStatus() {
      let params = {
        startOfday: moment(this.fromDate).format("YYYY-MM-DDTHH:mm:ss.SSS").toString(),
        endOfday: moment(this.toDate).format("YYYY-MM-DDTHH:mm:ss.SSS").toString(),
        cron_type: this.status,
        page_no: this.PaginationConfig.current || 1,
        page_size: this.PaginationConfig.limit || 10,
      };
      try {
        let res = await MainService.cronStatus(params);
        this.PaginationConfig.current = res.data.page.current;
          this.PaginationConfig.total = res.data.page.item_total;
        this.cronStatus = res.data.items;
      } catch (error) {
        this.$snackbar.global.showError("Something went wrong! please try again");
      }
    },
    getCron() {
      this.getCronStatus();
    },
    modifiedDate(date) {
      return moment(date).format("hh:mm A, Do MMM YYYY");
    },
    resetData() {
      this.getCronStatus();
    },
    paginationChange(e) {
      this.PaginationConfig = e;
      this.getCronStatus();
    },
  },
};
</script>

<style lang="less" scoped>
.cron-status-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.date {
  padding: 40px 40px 40px 0px;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    color: #41434c;
    margin-right: 8px;
    margin-left: 8px;
  }
}
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}
table,
th,
td {
  border: 1px solid black;
  text-align: center;
  border-collapse: collapse;
  padding: 10px;
}
td {
  font-size: 14px;
  color: #565656;
  font-weight: 500;
}
.no-screen-image p {
  padding-left: 40px;
}
.pagination-config {
  width: 90%;
  margin-top: 20px;
}
</style>