import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    application_name: '',
    domain:'',
    subscriptionData: {
      platform_subscription_id: "",
      status: "loading",
    },
    _monthlyEmailCount: 0,
    _monthlyFreeEmailLimit: 100
  },
  mutations: {
    setapplicationname(state,data) {
      state.application_name = data;
    },
    setDomain(state,data){
      state.domain = data
    },
    setSubscriptionData(state, data) {
      state.subscriptionData = data
    },
    setMonthlyEmailCount(state, emailCount) {
      state._monthlyEmailCount = emailCount;
    }
  },
  getters: {
    subscription: state => state.subscriptionData,
    monthlyEmailCount: state => state._monthlyEmailCount,
    monthlyFreeEmailLimit: state => state._monthlyFreeEmailLimit,
    monthlyFreeEmailLimitReached: state => state._monthlyEmailCount >= state._monthlyFreeEmailLimit,
  },
  actions: {
  },
  modules: {
  }
})
