<template>
    <div class="modal-wrapper">
        <div class="back-in-stock-waitlist">
            <div class="close" @click="closeModal">
                <img :src="closeImg" />
            </div>
            <div class="head">
                <h2>Back In Stock Waitlist</h2>
            </div>
            <div class="product">
                <div class="product-details">
                    <div class="product-detail">
                        <img class="product-icon" :src="this.product.media[0].url" alt="Product Image" />
                        <div class="details">
                            <h6>{{ this.product.name }}</h6>
                            <sh4>Size: {{ this.product.size }}</sh4>
                            <!-- If Product is out of stock, showing inventory as 0 -->
                            <sh4>Qty: {{ product_quantity }}</sh4>
                        </div>
                    </div>
                    <div class="product-status">
                        <div class=" status" :class="this.product.in_stock ? 'active' : 'inactive'">
                            {{ this.product.in_stock ? 'RESTOCK' : 'OUT OF STOCK' }}</div>
                    </div>
                </div>
                <div class="product-options">
                    <nitrozen-button class="nit-button" 
                                    :class = "!subscription? 'disabled-button': ''"
                                    v-flatBtn 
                                    size="small" 
                                    @click="addCustomer" 
                                    theme="secondary"
                                    :disabled="!subscription">Add
                        Customer</nitrozen-button>
                    <!-- @TODO exportToAudience -->
                    <!-- <nitrozen-button class="nit-button" v-flatBtn size="small" @click="exportToAudience"
                        theme="secondary">Export to
                        Audience</nitrozen-button> -->
                    <nitrozen-button class="nit-button" 
                                    v-flatBtn 
                                    size="small" 
                                    @click="exportCSV" 
                                    theme="secondary" 
                                    :class = "!subscription? 'disabled-button': ''"
                                    :disabled="!subscription">Export CSV</nitrozen-button>
                </div>
            </div>
            <div class="products-table">
                <div class="subscription-overlay" v-if="!subscription">
                    <nitrozen-button v-flatBtn 
                                    theme="secondary"
                                    @click = "gotoPricingPage"> Upgrade to PRO </nitrozen-button>
                </div>
                <ul>
                    <li class="product-row table-header table-bg table-grid">
                        <sh3>Customer Email</sh3>
                        <sh3>Subscribe Date</sh3>
                        <sh3>Last Email Sent</sh3>
                        <sh3>Order Status</sh3>
                        <sh3>Action</sh3>
                    </li>
                    <li v-for="customer in  customersList " :key="customer.email">
                        <div class="product-row table-grid"
                            :class = "{
                                'clicked-customer': clickedCustomer == customer.email,
                            }"
                            >
                            <p>{{ customer.email }}</p>
                            <p>{{ subscribedOn(customer) }}</p>
                            <p>{{ lastEmailSent(customer) }}</p>
                            <div>
                                <div class="status" :class="customer.bought_time ? 'active' : 'pending'">{{
                                    customer.bought_time ?
                                    'PLACED' : 'PENDING' }}</div>
                            </div>
                            <div class="action">
                                <div class="send-email-tooltip">{{ !product.in_stock ?
                                    "You can trigger reminder email once the item is back in stock" :
                                    customer.bought_time || customer.emails_sent_count >= 2 ?
                                        `You have sent ${customer.emails_sent_count} reminders to this customer already` :
                                        `You can send upto ${2 - customer.emails_sent_count} email reminders to the customer` }}
                                </div>
                                <nitrozen-button class="nit-button"
                                    :class="customer.bought_time || !product.in_stock || customer.emails_sent_count >= 2 ? 'disabled-button' : ''"
                                    v-flatBtn size="small" @click="sendReminder(customer)" theme="secondary"
                                    :disabled="!subscription || customer.bought_time || !product.in_stock || customer.emails_sent_count >= 2">
                                    <img :src="require('../../../assets/svgs/email_icon.svg')" />
                                    Send Email
                                </nitrozen-button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="showModal">
                <add-customer-modal :product="product" :closeModal="closeChildModal" :addCustomer="addCustomerDetails"
                    :isExistingUser=isExistingUser />
            </div>
        </div>
    </div>
</template>
<script>
import {
    NitrozenButton,
    flatBtn
} from "@gofynd/nitrozen-vue";
import MainService from '../../../services/main-service';
import { downloadCSVData, formatUTCtoISTDate } from "../../../helper/utils";
import addCustomerModal from "./add-customer-modal.vue";
import close from "../../../assets/svgs/close.svg";
import store from "../../../store";

export default {
    name: "back-in-stock-waitlist",
    components: {
        NitrozenButton,
        addCustomerModal,
    },
    directives: {
        flatBtn
    },
    props: {
        product: {
            type: Object,
            default: null,
            required: true,
        },
        closeModal: {
            type: Function,
            required: true,
        },
    },
    computed: {
        closeImg() {
            return close;
        },
        subscription() {
            const subs = store.getters.subscription;
            return subs.status == 'active' && subs.platform_subscription_id;
        }
    },
    data() {
        return {
            customersList: [],
            showModal: false,
            paginationConfig: {
                limit: 20,
                current: 0,
                total: 0,
            },
            pageSizeOptions: [20, 50, 100, 500],
            product_quantity: 0,
            clickedCustomer: '',
        }
    },
    methods: {
        async getProductDetails() {
            const { data } = await MainService.getProductWaitList({
                item_id: this.product.item_id,
                pageSize: this.paginationConfig.limit,
                pageNo: this.paginationConfig.current,
                in_stock: this.product.in_stock,
                size: this.product.size,
            });
            this.customersList = data.docs;
            this.paginationConfig.current = data.page;
            this.paginationConfig.total = data.total;
        },
        addCustomer() {
            this.showModal = true;
        },
        /**
         * @todo implement after getting the details 
         */
        // exportToAudience() {
        //     console.log("EXPORT TO AUDIENCE");
        // },
        exportCSV() {
            try {
                const csvHead = 'Customer Email,Subscribe Date,Last Email Sent,Order Status';
                const data = this.customersList.map((customer) => {
                    return [
                        customer.email,
                        this.subscribedOn(customer),
                        this.lastEmailSent(customer),
                        customer.status,
                    ]
                })
                downloadCSVData(csvHead, data, 'backInStock');
                this.$snackbar.global.showSuccess("CSV Downloaded");
            } catch (e) {
                this.$snackbar.global.showError("Something went wrong! please try again");
            }
        },
        async sendReminder(customer) {
            try {
                this.clickedCustomer = customer.email;
                const data = await MainService.sendProductReminder({ ...customer, ...this.product });
                if (data.status === 200) {
                    customer.emails_sent_count += 1;
                    this.$snackbar.global.showSuccess("Email Sent");
                } else {
                    throw Error;
                }
            } catch (err) {
                this.$snackbar.global.showError("Something went wrong! please try again");
            } finally {
                this.clickedCustomer = '';
            }
        },
        getISTDate(date) {
            return formatUTCtoISTDate(date);
        },
        async closeChildModal() {
            this.showModal = false;
        },
        subscribedOn(customer) {
            return customer.subscribed_on ? this.getISTDate(customer.subscribed_on) : '-';
        },
        lastEmailSent(customer) {
            if (this.product.in_stock) {
                return this.getISTDate(customer.last_email_sent);
            } else {
                return '-';
            }
        },
        async addCustomerDetails(email) {
            this.customersList.push({
                email,
                emails_sent_count: 1,
                ...(this.product.in_stock && { last_email_sent: new Date(), }),
                subscribed_on: new Date(),
            });
        },
        async getInventory() {
            if (!this.product.in_stock) {
                return;
            }
            const { data } = await MainService.getItemInventory({ item_id: this.product.item_id, size: this.product.size });

            this.product_quantity = data.inventory?.items.reduce((sum, item) => sum + item.sellable_quantity, 0);
        },
        isExistingUser(email) {
            return this.customersList.find(customer => customer.email === email);
        },
        gotoPricingPage() {
            this.$router.push(
                `/company/${this.$route.params.company_id}/pricing`
            );
        }
    },
    mounted() {
        this.getProductDetails();
        this.getInventory();
    },
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';
@import '../../../helper/colors.less';

.modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.back-in-stock-waitlist {
    width: 75%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background: @white;
    .pxToRem(padding, 24);

    .close {
        position: absolute;
        .pxToRem(top, 25);
        .pxToRem(right, 20);
        cursor: pointer;
    }

    .nit-button {
        background: transparent;
        color: @blue-primary;
        border: 1px solid @blue-primary;
        padding: 9.5px 16px;
        .pxToRem(border-radius, 4);
        display: flex;

        ::v-deep .n-button-content {
            .pxToRem(gap, 4);
            white-space: nowrap;
        }
    }

    .action {
        position: relative;

        .send-email-tooltip {
            position: absolute;
            right: 120%;
            visibility: hidden;
            background-color: #3D3D3D;
            color: @white;
            .pxToRem(padding, 10);
            .pxToRem(border-radius, 4);
            .pxToRem(font-size, 12);
            .pxToRem(line-height, 19.2);
            .pxToRem(width, 180);
        }
    }

    .action:hover {
        .send-email-tooltip {
            visibility: visible;
        }

        .send-email-tooltip::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -7px;
            /* Half the height of the arrow */
            border-width: 7px;
            border-style: solid;
            border-color: transparent transparent transparent #3D3D3D;
        }
    }

    .disabled-button {
        opacity: 0.5;
    }

    .status {
        border-radius: 2px;
        padding: 1px 4px;
        width: fit-content;
        .pxToRem(font-size, 12)
    }

    .active {
        border: 1px solid @success;
        color: @success;
    }

    .inactive {
        border: 1px solid @error;
        color: @error;
    }

    .pending {
        border: 1px solid @warning;
        color: @warning;
    }

    .head {
        border-bottom: 1px solid @gray-6;
        .pxToRem(margin-left, -24);
        .pxToRem(margin-right, -24);

        h2 {
            color: @textPrimary;
            font-family: Inter;
            .pxToRem(font-size, 18);
            font-style: normal;
            font-weight: 600;
            .pxToRem(line-height, 24);
            margin: unset;
            .pxToRem(padding-left, 24);
            .pxToRem(padding-right, 24);
            .pxToRem(padding-bottom, 24);
        }
    }

    .product {
        display: flex;
        align-items: center;
        .pxToRem(gap, 24);
        .pxToRem(padding-top, 24);
        justify-content: space-between;

        .product-details {
            width: 100%;
            display: grid;
            grid-template-columns: 2fr 1fr;
            border-right: 1px solid @gray-5;
            align-items: center;

            .product-detail {
                display: flex;
                .pxToRem(gap, 12);

                .product-icon {
                    .pxToRem(height, 66);
                    .pxToRem(width, 66);
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    .pxToRem(gap, 4);

                    h6 {
                        color: @textPrimary;
                        font-family: Inter;
                        .pxToRem(font-size, 14);
                        font-style: normal;
                        font-weight: 600;
                        line-height: 140%;
                        /* 19.6px */
                    }

                    sh4 {
                        color: @sh4-color;
                        font-family: Inter;
                        .pxToRem(font-size, 12);
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }
            }
        }

        .product-options {
            width: 100%;
            display: flex;
            .pxToRem(gap, 16);
            justify-content: flex-end;
        }
    }

    .products-table {
        position: relative;

        ul {
            max-height: 70vh;
            overflow: scroll;
        }

        .subscription-overlay {
            position: absolute;
            inset: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            backdrop-filter: blur(2px);
            z-index: 2;
        }

        border: 1px solid #e0e0e0;
        .pxToRem(border-radius, 4);
        .pxToRem(margin-top, 20);

        .table-grid {
            display: grid;
            grid-template-columns: 1.5fr 1.2fr 1.2fr 1fr 1fr;
            .pxToRem(column-gap, 16);
            align-items: center;
        }

        .table-header {
            sh3 {
                color: #41434C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 600;
                .pxToRem(line-height, 18.2);
            }
        }

        .product-row {
            border-bottom: 1px solid #e0e0e0;
            .pxToRem(padding, 16);

            p {
                color: #5C5C5C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 400;
                .pxToRem(line-height, 18.2);
            }

            .product-title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .pxToRem(gap, 12);
            }

            &.clicked-customer,
            &.disabled {
                pointer-events: none;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

        }

        .table-bg {
            background: #f6f6f6;
        }
    }

    .pagination {
        .pxToRem(margin-top, 16);
    }
}
</style>
