<template>
  <section class="limited-stock">
    <h5>Limited Stock Text</h5>
    <form class="limited-text-content"
          name="limited-text-content"
          @submit.prevent="save()">
      <!-- <p class="limited-text-content-para">Text*</p> -->
      <div class="note">
        <nitrozen-input
          type="textarea"
          label="Text"
          required
          v-model="limitedStock.storefront_appearance.limited_stock_text" 
          @input="checkEmpty('storefront_appearance','limited_stock_text')"
          @change="removeSpace('limitedStock','storefront_appearance','limited_stock_text')"
          placeholder="Type limited stock text here">
        </nitrozen-input>

        <nitrozen-error v-if="errors['limited_stock_text']">Field is required</nitrozen-error>
        <p class="para"><strong>Note:</strong> Only following variable(s) are available, you can use a variable once in each field <br> <strong>{{ productQuantity }}</strong></p>
      </div>
      <nitrozen-button
        type="submit"
        :disabled="saving"
        :showProgress="saving"
        v-flatBtn
        size="small"
        theme="secondary"
      >
        Save
      </nitrozen-button>
    </form>
  </section>
</template>
<script>
import inlineSvg from "@/components/common/inline-svg.vue";
import {
  NitrozenInput,
  NitrozenError,
  NitrozenButton,
  flatBtn
} from "@gofynd/nitrozen-vue";
export default {
  name: "limited-stock-storefront-customization",
  props: ["limitedStock","errors","checkEmpty"],
  data() {
    return {
      productQuantity: "{{product.quantity}}",
    }
  },
  components: {
    "inline-svg": inlineSvg,
    NitrozenInput,
    NitrozenError,
    NitrozenButton
  },
  directives: {
    flatBtn
  },
  methods: {
    removeSpace(i,p,s){
      this[i][p][s] = this[i][p][s].trim();
    },
    save() {
      this.$emit('save');
    }
  }
};
</script>

<style lang="less" scoped>
@import 'src/helper/utils';

h5 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.limited-text-content {
  margin-top: 12px;
}
.limited-text-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #828282;
}
.note {
  .para {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #828282;
    .pxToRem(margin-top, 4);
    .pxToRem(margin-bottom, 20);
  }
}

.custom-text {
  margin-top: 25px;
}
</style>
