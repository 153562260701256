/* eslint-disable */
"use strict";

import URLS from './endpoint.service';
import ApiService from "./api.service";
import axios from 'axios';
import { getCompany,getApplication}  from '../helper/utils';

axios.interceptors.request.use(config => {
    config.headers['x-company-id'] = getCompany();
    config.headers['x-application-id'] = getApplication();
    return config;
});

const MainService = {
    test_email(body) {
        const options = {
            data: body
        }
        return ApiService.post(URLS.TEST_EMAIL(), options);
    },
    post_proxy_url(body) {
        const options = {
            data: body
        }
        return ApiService.post(URLS.PROXY_URL(), options);
    },
    getOut_stock_config(params = {}) {
        const options = {
            params: params
        }
        console.log('options',options);
        return ApiService.get(URLS.OUT_STOCK_CONFIG(), options);
    },
    patchOut_stock_config(body) {
        const options = {
            data: body
        }
        return ApiService.patch(URLS.OUT_STOCK_CONFIG(), options);
    },
    getLimited_stock_config(params = {}) {
        const options = {
            params: params
        }
        return ApiService.get(URLS.LIMITED_STOCK_CONFIG(), options);
    },
    patchLimited_stock_config(body) {
        const options = {
            data: body
        }
        return ApiService.patch(URLS.LIMITED_STOCK_CONFIG(), options);
    },
    getAllApplications(params = {}) {
        const options = {
            params: params
        }
        return ApiService.get(URLS.GET_ALL_APPLICATIONS(), options);
    },
    cronStatus(params = {}) {
        const options = {
            params: params
        }
        return axios.get(URLS.CRON_STATUS_POSTMAN(), options);
    },
    outStockEmail(params = {}) {
        const options = {
            params: params
        }
        return axios.get(URLS.OUT_STOCK_EMAIL_POSTMAN(params), options);
    },
    outStockEmailArcheive(params = {}) {
        const options = {
            params: params
        }
        return axios.get(URLS.OUT_STOCK_EMAIL_ARCHEIVE_POSTMAN(params), options);
    },
    limitedStockSuccessEmail(params = {}) {
        const options = {
            params: params
        }
        return axios.get(URLS.LIMITED_STOCK_SUCCESS_EMAIL_POSTMAN(params), options);
    },
    postApplication(body) {
        const options = {
            data: body
        }
        return ApiService.post(URLS.GET_ALL_APPLICATIONS(), options);
    },
    setThemeColor(company_id, application_id) {
        const options = {
            data: {
                company_id,
                application_id,
            }
        }
        return ApiService.post(URLS.THEME_BUTTON_COLOR(), options);
    },
    patchApplication(body) {
        const options = {
            data: body
        }
        return ApiService.patch(URLS.GET_ALL_APPLICATIONS(), options);
    },
    patchApplicationSettings(body) {
        const options = {
            data: body
        }
        return ApiService.patch(URLS.APPLICATIONS_SETTINGS(), options);
    },
    getPlans() {
        const options = {};
        return ApiService.get(URLS.PLANS(), options);
    },
    getSubscription() {
        const options = {};
        return ApiService.get(URLS.SUBSCRIPTION(), options);
    },
    subscribePlan(plan_id, body) {
        const options = {
            data: body
        }
        return ApiService.post(URLS.SUBSCRIPTION_BY_PLAN_ID(plan_id), options);
    },
    updateSubscriptionStatus(subscription_id) {
        const options = {};
        return ApiService.post(URLS.SUBSCRIPTION_STATUS(subscription_id), options);
    },
    getAnalyticsData(dateRange){
        return ApiService.get(URLS.ANALYTICS_DATA(dateRange));
    },
    getMostWantedProducts(count=3){
        return ApiService.get(URLS.MOST_WANTED_PRODUCTS(count));
    },
    getItemsBoughtVsNotified(){
        return ApiService.get(URLS.ITEMS_BOUGHT_VS_NOTIFIED());
    },
    getLimitedStockProducts(pageOptions){
        return ApiService.get(URLS.LIMITED_STOCK_PRODUCTS(pageOptions));
    },
    getBackInStockProducts(pageOptions){
        return ApiService.get(URLS.BACK_IN_STOCK_PRODUCTS(pageOptions));
    },
    getProductWaitList(pageOptions){
        return ApiService.get(URLS.PRODUCT_WAIT_LIST(pageOptions));
    },
    getMonthlyEmailCount() {
        return ApiService.get(URLS.MONTHLY_EMAIL_COUNT());
    },
    addCustomerToWaitList(body){
        const options = {
            data:body
        }
        return ApiService.post(URLS.ADD_CUSTOMER_TO_WAIT_LIST(),options);
    },
    sendProductReminder(body){
        const options = {
            data:body
        }
        return ApiService.post(URLS.SEND_PRODUCT_REMINDER(),options);
    },
    getProductsList(pageOptions){
        return ApiService.get(URLS.PRODUCTS_LIST(pageOptions));
    },
    getAllWebhookSubscribers(pageOptions){
        return ApiService.get(URLS.WEBHOOK_SUBSCRIBER_LIST(pageOptions));
    },
    getWebhookSubscriber(id){
        return ApiService.get(URLS.WEBHOOK_SUBSCRIBER(id));
    },
    addWebhookSubscriber(body){
        const options = {
            data:body
        }
        return ApiService.post(URLS.CREATE_WEBHOOK_SUBSCRIBER(),options);
    },
    updateWebhookSubscriber(id, body){
        const options = {
            data:body
        }
        return ApiService.put(URLS.WEBHOOK_SUBSCRIBER(id), options);
    },
    getBackInStockDefaultEmail(){
        return ApiService.get(URLS.BACK_IN_STOCK_DEFAULT_EMAIL());
    },
    getLimitedStockDefaultEmail(){
        return ApiService.get(URLS.LIMITED_STOCK_DEFAULT_EMAIL());
    },
    getItemInventory(item){
        return ApiService.get(URLS.ITEM_INVENTORY(item));
    },
    getUnpaginatedEmailProviders() {
        return ApiService.get(URLS.EMAIL_PROVIDERS(1, 100))
                        .then(response => response.data.items);
    }
}

export default MainService;
