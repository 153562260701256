<template>
    <div class="progress-bar-wrapper">
        <div class="background"></div>
        <div class="progress-value" :style="{width: (value/max)*100 + '%'}"></div>
    </div>
</template>
<script>
export default {
    name: "progress-bar",
    components: {
        
    },
    props: {
        max: {
            type: Number,
            default: 100,
        },
        min: {
            type: Number,
            default: 0,
        },
        value: {
            type: Number,
            required: true,
        }
    },
    data() {
        return {
            variableName: ''
        }
    },
    computed: {},
    methods: {},
    mounted() {
        // @ts-check
        
    },
    destroyed() {
        // @ts-check
        
    }
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';
@import '../../helper/colors';

.progress-bar-wrapper {
    width: 250px;
    position: relative;
    .pxToRem(height, 8);
}

.progress-value,
.background {
    position: absolute;
    inset: 0;
    .pxToRem(border-radius, 8);
}
.progress-value {
    background: @blue-primary;
}
.background {
    background: rgba(224, 224, 224, 0.50);
}

</style>
