<template>
  <div class="show-data-main">
    <div class="date">
      <span>From Date</span>
      <date-picker
        @change="outStockEmail"
        :disabled-date="
          (date) => date >= new Date().setDate(new Date().getDate() - 1)
        "
        v-model="fromDate"
        type="date"
        placeholder="Choose date"
      ></date-picker>
      <span>To Date</span>
      <date-picker
        @change="outStockEmail"
        :disabled-date="(date) => date <= new Date(fromDate)"
        v-model="toDate"
        type="date"
        placeholder="Choose date"
        :disabled="fromDate == ''"
      ></date-picker>
    </div>
    <table-list :emailData="emailData" />
    <nitrozen-pagination
      v-model="PaginationConfig"
      v-if="emailData.length"
      :pageSizeOptions="pageSizeOptions"
      @change="paginationChange"
      class="pagination-config"
    >
    </nitrozen-pagination>
  </div>
</template>

<script>
import moment from "moment";
import { NitrozenPagination } from "@gofynd/nitrozen-vue";
import TableList from "../../components/table-data/table.vue";
import DatePicker from "vue2-datepicker";
import MainService from "../../services/main-service";
export default {
  components: {
    DatePicker,
    TableList,
    NitrozenPagination,
  },
  data() {
    return {
      emailData: [],
      fromDate: "",
      toDate: "",
      PaginationConfig: {
        limit: 10,
        total: 1,
        current: 1,
      },
      pageSizeOptions: [10, 20, 50, 100],
    };
  },
  methods: {
    async outStockEmail(){
      let params = {
        application_id: this.$route.params.application_id,
        startOfday: moment(this.fromDate).format("YYYY-MM-DDTHH:mm:ss.SSS").toString(),
        endOfday: moment(this.toDate).format("YYYY-MM-DDTHH:mm:ss.SSS").toString(),
        page_no: this.PaginationConfig.current || 1,
        page_size: this.PaginationConfig.limit || 10,
      };
      try {
        const routerName = this.$route.name;
        if (routerName == "Out Stock Email") {
          let res = await MainService.outStockEmail(params);
          this.PaginationConfig.current = res.data.page.current;
          this.PaginationConfig.total = res.data.page.item_total;
          this.emailData = res.data.items;
        } else if (routerName == "Out Stock Email Archive") {
          let res = await MainService.outStockEmailArcheive(params);
          this.PaginationConfig.current = res.data.page.current;
          this.PaginationConfig.total = res.data.page.item_total;
          this.emailData = res.data.items;
        } else {
          let res = await MainService.limitedStockSuccessEmail(params);
          this.PaginationConfig.current = res.data.page.current;
          this.PaginationConfig.total = res.data.page.item_total;
          this.emailData = res.data.items;
        }
      } catch (error) {
        this.$snackbar.global.showError("Something went wrong! please try again");
      }
    },
    paginationChange(e) {
      this.PaginationConfig = e;
      this.outStockEmail();
    },
  },
};
</script>

<style lang="less" scoped>
.show-data-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.date {
  padding: 40px 40px 40px 0px;
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 23px;
    color: #41434c;
    margin-right: 8px;
    margin-left: 8px;
  }
}
.pagination-config {
  padding: 0px 15px;
  margin-top: 20px;
  box-sizing: border-box;
}
</style>