import { render, staticRenderFns } from "./email-template-preview.vue?vue&type=template&id=918e5cde&scoped=true"
import script from "./email-template-preview.vue?vue&type=script&lang=js"
export * from "./email-template-preview.vue?vue&type=script&lang=js"
import style0 from "./email-template-preview.vue?vue&type=style&index=0&id=918e5cde&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "918e5cde",
  null
  
)

export default component.exports