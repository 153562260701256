<template>
    <div class="limited-stock-products">
        <div class="head">
            <div class="head-left">
                <h6>Limited Stock Products</h6>
                <p>
                    List of products that are below the quantity threshold and showing limited Stock
                </p>
            </div>
            <div class="head-right" >
                <nitrozen-button
                    theme="secondary"
                    @click="exportCSV"
                >
                    <img :src="require('../../assets/svgs/export_icon.svg')" alt="export icon" />
                    Export
                </nitrozen-button>
            </div>
        </div>
        <div class="content">
            <div v-if="products && products.length" class="table">
                <div class="products-table">
                    <div class="product-row table-header table-bg">
                        <sh3 class="w-40 text-color-2">Product</sh3>
                        <sh3 class="w-12 bold">Size</sh3>
                        <sh3 class="w-16 bold">Low Inventory Count</sh3>
                        <sh3 class="w-20 bold">Date Time</sh3>
                        <sh3 class="w-12 bold">Status</sh3>
                    </div>
                    <ul>
                        <li v-for="product in products" :key="product.name">
                            <div class="product-row">
                                <div class="w-40 product-title">
                                    <p><img class="product-icon" :src=product?.media?.[0]?.url alt="image" /></p>
                                    <p class="text-color text-color">{{ product.name }}</p>
                                </div>
                                <p class="w-12 text-color text-color">{{ product.size }}</p>
                                <p class="w-16">{{ product.inventory_count }}</p>
                                <p class="w-20">{{ getISTDate(product.updated_at) }}</p>
                                <div class="w-12">
                                    <div class=" status" :class="product.is_excluded ? 'inactive' : 'active'">{{
                                        product.is_excluded ?
                                        'INACTIVE' : 'ACTIVE' }}</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <nitrozen-pagination class="pagination" v-model="paginationConfig" name="Products"
                    @change="getLimitedStockProducts" :pageSizeOptions="pageSizeOptions"> </nitrozen-pagination>
            </div>
            <empty-state v-else text="No Limited Stock Products Found" />
        </div>
    </div>
</template>
<script>
import {
    NitrozenButton,
    NitrozenPagination,
    flatBtn
} from "@gofynd/nitrozen-vue";
import MainService from '../../services/main-service';
import { downloadCSVData, formatUTCtoISTDate } from '../../helper/utils'
import EmptyState from "./molecules/empty-state.vue";
import store from "../../store";

export default {
    name: "limited-stock-products",
    components: {
        NitrozenButton,
        NitrozenPagination,
        EmptyState
    },
    directives: {
        flatBtn
    },
    data() {
        return {
            products: [],
            paginationConfig: {
                limit: 5,
                current: 0,
                total: 0,
            },
            pageSizeOptions: [5, 10, 15, 20]
        }
    },
    computed: {
        subscription() {
            return store.getters.subscription
        }
    },
    methods: {
        getLimitedStockProducts() {
            return MainService.getLimitedStockProducts({
                pageSize: this.paginationConfig.limit,
                pageNo: this.paginationConfig.current
            })
                .then(({ data }) => {
                    this.products = data.docs;
                    this.paginationConfig.current = parseInt(data.page);
                    this.paginationConfig.total = parseInt(data.total);
                })
                .catch(err => {
                    console.error(err);
                    this.$snackbar.global.showError("Failed to load Products list");
                })
        },
        getISTDate(date) {
            return formatUTCtoISTDate(date)
        },
        exportCSV() {
            try {
                const csvHead = 'Product, Size, Low Inventory Count, Date/Time ,Status';
                const data = this.products.map((product) => {
                    return [
                        product.name,
                        product.size,
                        product.inventory_count,
                        this.getISTDate(product.updated_at),
                        product.is_excluded ? 'INACTIVE' : 'ACTIVE'
                    ]
                })
                downloadCSVData(csvHead, data, 'limited_stock');
                this.$snackbar.global.showSuccess("CSV Downloaded");
            } catch (e) {
                this.$snackbar.global.showError("Something went wrong! please try again");
            }
        },
    },
    mounted() {
        this.getLimitedStockProducts()
    }
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';
@import '../../helper/colors.less';

.limited-stock-products {
    border: 1px solid #E5E5E5;
    background-color: #fff;
    .pxToRem(padding, 24);
    .pxToRem(border-radius, 12);
    grid-column: 1/span 2;
    display: grid;
    .pxToRem(gap, 24);

    .content {
        min-height: 335px;
        display: grid;
        place-items: stretch;
        align-self: stretch;
    }
    .table {
        position: relative;
        // sum of heights of table, pagination and the gap
        min-height: 335px;
        display: grid;
        place-items: center;
        align-self: stretch;
        justify-self: stretch;
    }

    .head {
        display: flex;
        justify-content: space-between;

        h6 {
            color: #41434C;
            font-family: Inter;
            .pxToRem(font-size, 18);
            font-style: normal;
            font-weight: 600;
            .pxToRem(line-height, 24);
        }

        p {
            color: rgba(102, 102, 102, 0.80);
            font-family: Inter;
            .pxToRem(font-size, 14);
            font-style: normal;
            font-weight: 400;
            .pxToRem(line-height, 17);
        }

        .nit-button {
            background: transparent;
            color: @blue-primary;
            border: 1px solid @blue-primary;
            .pxToRem(border-radius, 4);
        }

        .head-right {
            .pxToRem(font-size, 14);

            img {
                .pxToRem(width, 24);
                .pxToRem(height, 24);
            }
        }
    }

    .products-table {
        border: 1px solid #e0e0e0;
        .pxToRem(border-radius, 4);
        justify-self: stretch;
        .table-header {
            sh3 {
                color: #41434C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 600;
                .pxToRem(line-height, 18.2);
            }
        }
        .product-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e0e0e0;
            .pxToRem(padding, 16);

            p {
                color: #5C5C5C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 400;
                .pxToRem(line-height, 18.2);
            }

            .product-title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .pxToRem(gap, 12);

                .product-icon {
                    .pxToRem(height, 40);
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .status {
                border-radius: 2px;
                padding: 1px 4px;
                width: fit-content;
            }

            .active {
                border: 1px solid @success;
                color: @success;
            }

            .inactive {
                border: 1px solid @error;
                color: @error;
            }
        }

        .table-bg {
            background: #f6f6f6;
        }

        .w-40 {
            width: 40%;
        }

        .w-12 {
            width: 12%;
        }

        .w-16 {
            width: 17%;
        }

        .w-20 {
            width: 20%;
        }
    }

    .pagination {
        .pxToRem(margin-top, 16);
    }
}
</style>
