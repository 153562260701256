<template>
  <section class="main">
    
      <div class="features">
        <header class="title">
          <h5>Features</h5>
          <div class="statusDisplay">
            <p>{{ status ? 'Enabled' : 'Disabled' }}</p>
            <NitrozenToggleBtn :value="status" @change="changeApplicationStatus()">
            </NitrozenToggleBtn>
          </div>
        </header>
        <!-- <free-email-limit-alert /> -->
        <nitrozen-alert2 v-if="freeSubscription && freeEmailLimitReached"
          type="error">
          <template v-slot:message>
            <div class="message">
              <div>
                You've reached your monthly email limit of 100.
                Your customers are no longer receiving emails.
              </div>
              Upgrade your plan to continue sending unlimited emails.
            </div>
          </template>
          <template v-slot:action>
            <button class="action" @click="upgradeToPro()">
              Upgrade to PRO
            </button>
          </template>
        </nitrozen-alert2>
        <div class="card-list">
          <div class="card-innerbox">
            <div class="card-box">
              <div class="card-logo">
                <img src="../../assets/pngs/icon1.png" alt="card-logo" />
              </div>
              <div class="card-content">
                <h4>Analytics</h4>
                <p>Dashboard to track mails and customer actions</p>
              </div>
              <div class="card-box-margin">
                <div class="button-container" @click="onClickAnalytics">
                  <div class="select-widget float-right" id="subscribe">
                    <inline-svg class="arrow" :src="'big-arrow'"></inline-svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-innerbox">
            <div class="card-box">
              <div class="card-logo">
                <img src="../../assets/pngs/icon.png" alt="card-logo" />
              </div>
              <div class="card-content">
                <h4>Limited Stock</h4>
                <p>
                  Email alert for customers when product is available in few
                  units
                </p>
              </div>
              <div class="card-box-margin">
                <div class="button-container" @click="onClickLimitedStock()">
                  <div class="select-widget float-right" id="subscribe">
                    <inline-svg class="arrow" :src="'big-arrow'"></inline-svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-innerbox">
            <div class="card-box">
              <div class="card-logo">
                <img src="../../assets/pngs/QnA.png" alt="card-logo" />
              </div>
              <div class="card-content">
                <h4>Back In Stock</h4>
                <p>Email alert for customers when product is restocked</p>
              </div>
              <div class="card-box-margin">
                <div class="button-container" @click="onClickBackStock()">
                  <div class="select-widget float-right" id="subscribe">
                    <inline-svg class="arrow" :src="'big-arrow'"></inline-svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-innerbox">
            <span class="pro" v-if="proSubscription">
              PRO
            </span>
            <div class="card-box">
              <div class="card-logo">
                <img src="../../assets/svgs/integrations_icon.svg" alt="card-logo" />
              </div>
              <div class="card-content">
                <h4>Integration</h4>
                <p>
                  Broadcast all your extension events to your webhook endpoint
                </p>
              </div>
              <div class="card-box-margin">
                <div class="button-container" @click="onClickWebhooks()">
                  <div class="select-widget float-right" id="subscribe">
                    <inline-svg class="arrow" :src="'big-arrow'"></inline-svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right-side">
          <div class="learning-links">
            <h5>Learning Links</h5>
            <a href="https://platform.fynd.com/help/docs/extensions/available-extensions/product-stock-customer-alerts" target="_blank">
              <div class="learning-links-item">
                  <img :src="require('../../assets/svgs/learning_link.svg')" alt="learning links image" />
                <div class="learning-links-item-title-container">
                  <h6>How to setup and use this extension?</h6>
                  <p>READ: 2min 30sec</p>
                </div>
              </div>
            </a>
          </div>
      </div>
    
  </section>
</template>

<script>
import inlineSvg from "@/components/common/inline-svg.vue";
import { NitrozenToggleBtn } from "@gofynd/nitrozen-vue";
import MainService from "../../services/main-service";
import { getFormattedDate } from "@/helper/utils";
// import FreeEmailLimitAlert from "../../components/common/free-email-limit-alert.vue"
import NitrozenAlert2 from "../../components/common/nitrozen-alert2.vue";
import store from "../../store";

export default {
  name: 'analytics-features',
  components: {
    "inline-svg": inlineSvg,
    NitrozenToggleBtn,
    NitrozenAlert2,
    // FreeEmailLimitAlert,
  },
  data() {
    return {
      applicationData: {},
      dropdownData: [
        { text: "Today", value: 1 },
        { text: "Last Two Days", value: 2 },
        { text: "Last Week", value: 3 },
      ],
      selectedType: 1,
      status: false
    };
  },
  computed: {
    freeSubscription() {
        const subscription = store.getters.subscription;
        return subscription?.status !== 'active' || !subscription.platform_subscription_id
    },
    freeEmailLimitReached() {
        return store.getters.monthlyFreeEmailLimitReached
    }
  },
  mounted() {
    this.postApplication();
  },
  methods: {
    async changeApplicationStatus() {
      const newStatus = !this.status;
      
      try {
        await Promise.allSettled([
          this.post_proxy_url(newStatus),
          this.patchApplication(newStatus),
        ]);
        this.status = newStatus;
      } catch (err) {
        this.$snackbar.global.showError("Something went wrong! please try again");
      } finally {
        if (newStatus) {
          try {
            this.setThemeColor()
          } catch (err) {
            console.error(err);
          }
        }
      }
    },

    /**
     * adds/removes proxy path
     * @param {boolean} enabled 
     */
    async post_proxy_url(enabled) {
      const body = {
        application_id: this.$route.params.application_id,
        company_id: this.$route.params.company_id,
        enabled
      };
      try {
        let res = await MainService.post_proxy_url(body);
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    postApplication() {
      const apidata = {
        company_id: this.$route.params.company_id,
        application_id: this.$route.params.application_id,
      };
      MainService.postApplication(apidata)
        .then(({ data }) => {
          this.status = data[0]?.enabled;
          if (data.length) {
            this.applicationData.application_id = data[0].application_id
            this.applicationData.company_id = data[0].company_id
            this.applicationData.enabled = data[0].enabled
            this.applicationData.limited_stock = data[0].limited_stock
            this.applicationData.out_stock = data[0].out_stock
            this.applicationData.tagId = data[0].tagId
            this.applicationData.test_email = data[0].test_email
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    setThemeColor() {
      return MainService.setThemeColor(this.$route.params.company_id, this.$route.params.application_id)
    },

    /**
     * adds/removes tags
     * @param {boolean} value 
     */
    patchApplication(value) {
      const apidata = this.applicationData;
      apidata.company_id = this.$route.params.company_id;
      apidata.application_id = this.$route.params.application_id;
      apidata.enabled = value;
      return MainService.patchApplication(apidata)
        .then(({ data }) => {
          if (data.enabled == true) {
            this.applicationData = data;

            this.$snackbar.global.showSuccess("Extension active successfully");
          } else {
            this.$snackbar.global.showSuccess(
              "Extension inactive successfully"
            );
          }
        })
    },
    onClickLimitedStock() {
      this.$router.push(
        `/company/${this.$route.params.company_id}/${this.$route.params.application_id}/limited-stock`
      );
    },
    onClickBackStock() {
      this.$router.push(
        `/company/${this.$route.params.company_id}/${this.$route.params.application_id}/back-in-stock`
      );
    },
    onClickAnalytics() {
      this.$router.push({
        path: `/company/${this.$route.params.company_id}/${this.$route.params.application_id}/analytics`,
        query: {
          startDate: getFormattedDate(new Date(new Date() - (30 * 24 * 60 * 60 * 1000))),
          endDate: getFormattedDate(new Date()),
        }
      }
      );
    },
    onClickSettings() {
      this.$router.push(
        `/company/${this.$route.params.company_id}/${this.$route.params.application_id}/settings`
      );
    },
    onClickWebhooks() {
      this.$router.push(
        `/company/${this.$route.params.company_id}/${this.$route.params.application_id}/webhooks`
      )
    },
    upgradeToPro() {
      this.$router.push(`/company/${this.$route.params.company_id}/pricing`);
    }
  },
};
</script>

<style lang="less" scoped>
@import '../../helper/utils.less';
@import '../../helper/colors.less';

.main {
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 3fr 1fr;
  .pxToRem(gap, 24);
}
.features {
  background: white;
  .pxToRem(padding, 24);
  .pxToRem(border-radius, 12);
  border: 1px solid var(--stroke-highlighter-highliter, #EDECE9);
  display: grid;
  .pxToRem(gap, 20);
}

header {
  display: grid;
  grid-template-columns: 1fr auto;
}

.statusDisplay {
  display: flex;
  align-items: center;

  p {
    color: #2E31BE;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    margin-right: 0.8rem;
  }
}

h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #41434c;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.analytics {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.drop-down {
  width: 16%;
}

.cards {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.card {
  width: 20%;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 16px;
}

.d-flex {
  display: flex;
}

.customers-number {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
  margin-right: 8px;
}

.green {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgb(0, 128, 0);
  margin-left: 8px;
}



// for featuresx
.nitrozen-form-input {
  width: 49%;
}

.float-right {
  float: right;
}

.card-list {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.card-box {
  border: 1px solid #e4e5e6;
  border-radius: 10px;
  overflow: hidden;
  .pxToRem(padding, 24);
  background-color: #fff;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.card-box.fade {
  opacity: .3;
}

.card-logo {
  min-height: 35px;
}

.card-logo img {
  max-width: 52px;
}

.select-widget {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.select-widget .inline-svg {
  float: right;
  height: 35px;
  width: 35px;
  font-size: 15px;
  transform: rotate(180deg);
  padding: 6px 6px;
  border-radius: 20%;
  border: 1px solid #dedede;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card-content {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
}

.card-content h4 {
  .pxToRem(margin-top, 20);
  .pxToRem(margin-bottom, 20);
  font-weight: 600;
  font-size: 15px;
  color: #41434c;
}

.card-content p {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  color: #828282;
  font-family: Inter, sans-serif;
}

.card-innerbox {
  position: relative;
}


.card-innerbox .pro {
  position: absolute;
  z-index: 1;
  .pxToRem(right, 24);
  .pxToRem(top, 24);
  filter: none;
  background-color: rgb(231, 238, 255);
  color: rgb(46, 49, 190);
  border-color: rgb(231, 238, 255);
  width: 40px;
  height: 20px;
  padding: 1px 4px;
  .pxToRem(border-radius, 4);
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.8rem;
  text-transform: uppercase;
  max-width: 7.9rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}



.button-container {
  width: 100%;
  float: left;
}

.card-box-margin {
  margin-top: 60px;
}

.learning-links {
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #e8e8e8;

  a {
    text-decoration: none;

    .learning-links-item {
      display: flex;
      flex-direction: row;
      .pxToRem(margin-top, 27);

      .learning-links-item-title-container {
        margin-left: 0.4rem;

        h6 {
          color: @gray-1;
          font-family: Inter;
          .pxToRem(font-size, 12);
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
        }

        p {
          color: @gray-3;
          font-family: Inter;
          .pxToRem(font-size, 10);
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
      }
    }
  }
}

.learning-links h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.how-to-use {
  display: flex;
  padding: 15px 5px 15px 5px;
  border-bottom: 1px solid #f2f2f2;
  align-items: center;
}

.how-to-use .img {
  width: 65px;
  height: 40px;
  border-radius: 10px;
  margin-right: 12px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.how-to-use img {
  width: 100%;
  border-radius: 10px;
}

.setup-heading h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.setup-heading p {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
}

.view-more {
  display: flex;
  align-items: center;
}

.view-more p {
  margin-top: 15px;
}

.view-more p span {
  transform: rotate(180deg);
}

.view-more p a {
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding-right: 5px;
  color: #2e31be;
}

.right-arrow {
  transform: rotate(180deg);
  font-size: 12px;
  color: #2e31be;
}

.arrow {
  margin-left: 8px;
}
</style>
