<template>
  <!-- <div class="price-container"> -->
    <!-- <div class="top-banner">
      <div>
        <div class="banner-title">Pricing</div>
        <div class="banne-desc">Subscribe to help your business grow.</div>
      </div>
    </div> -->
    <!-- <loader v-if="loader"></loader> -->
    <!-- <div class="plan-section">
      <div v-for="plan in plans"
        :key="plan.id"
        class="plan-data"
        :class="{ active: plan.id == sub_plan_id, 'most-popular': plan.meta?.most_popular }">
        <p class="current-plan" v-if="plan.id === sub_plan_id">Current Plan</p>
        <div class="plan-title cl-Mako">{{ plan.name }}</div>
        <div class="plan-price-data">
          <div class="plan-price">Rs.{{ plan.price.amount }}</div>
          <div class="plan-price-desc cl-DustyGray2">/{{ plan.interval }}</div>
        </div>
        <div class="plan-features">
          <div v-for="(feature, index) in plan.features" :key="`feature-${index}`" class="feature-line">
            <div class="feature-text cl-Mako">{{ feature }}</div>
          </div>
        </div>
        <nitrozen-button class="subscribe-btn" v-if="plan.id != sub_plan_id" v-strokeBtn theme="secondary" id="subscribe"
          @click="subscribeToPlan(plan)">
          Select Plan
        </nitrozen-button>
      </div>
    </div> -->
  <!-- </div> -->
  <div class="page-wrapper">
    <div class="payment-pricing-container">
      <div class="payment-pricing_heading">
        Pricing
      </div>
      <div class="payment-pricing_description">
        Select plan suitable for you and your business need.
      </div>
      <loader v-if="loader"></loader>
      <div v-else
          class="payment-pricing_card_list" >
        <div v-for="plan in plans"
            :key="plan.id"
            class="pricing-card"
            :class="{'pricing-card_popular': plan.meta?.most_popular}">
          <div class="pricing-card_card_heading">
            {{ plan.name }}
          </div>
          <div class="pricing-card_card_tagline">
            {{ plan.tagline }}
          </div>
          <div class="pricing-card_card_text">
            <span class="pricing-card_card_pricing">
              {{ '₹' + plan.price.amount }}
            </span>
            /
            {{ plan.interval }}
          </div>
          <div class="pricing-card_card_details_list">
            <div v-for="(feature, featureIndex) in plan.features"
                :key="feature">
              <div class="pricing-card_card_text">
                {{ feature }}
              </div>
              <div v-if="featureIndex <= plan.features.length -1"
                  class="pricing-card_card_text plus">
                +
              </div>
            </div>
          </div>
          <div class="pricing-card_card_button">
            <nitrozen-button v-if="plan.meta?.most_popular"
              v-flatBtn
              theme="secondary"
              @click="subscribeToPlan(plan)"
            >
              Select Plan
            </nitrozen-button>
            <nitrozen-button v-else
              v-strokeBtn
              theme="secondary"
              @click="subscribeToPlan(plan)"
            >
              Select Plan
            </nitrozen-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import loader from "@/components/common/loaderFullScreen";
import MainService from "@/services/main-service";
import { NitrozenButton, strokeBtn, flatBtn } from "@gofynd/nitrozen-vue";
export default {
  name: "pricing-page",
  components: {
    NitrozenButton,
    loader,
  },
  directives: {
    strokeBtn,
    flatBtn
  },
  data() {
    return {
      loader: false,
      plans: [],
      sub_plan_id: null,
      subscription_id: null,
    };
  },
  beforeMount() {
    this.getPlans();
    this.subscribe();
  },
  methods: {
    subscribeToPlan(plan) {
      this.loader = true;
      MainService.subscribePlan(plan.id)
        .then(({ data }) => {
          if(plan.price.amount === 0) {
            this.$router.push(`/company/${this.$route.params.company_id}`);
            return;
          }
          if (data?.redirect_url) {
            window.location.href = data.redirect_url;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPlans() {
      this.loader = true;
      MainService.getPlans()
        .then(({ data }) => {
          this.plans = data.plans
                          .plans
                          .sort((highIndexPlan, lowIndexPlan) =>
                            highIndexPlan.meta?.index - lowIndexPlan.meta?.index);
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    subscribe() {
      this.loader = true;
      MainService.getSubscription()
        .then((data) => {
          if (data.data) {
            this.sub_plan_id = data.data.plan_id;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
@import 'src/helper/utils';

.page-wrapper {
    background-color: #fff;
    .pxToRem(border-radius, 8);
    .pxToRem(padding, 24);
    .pxToRem(margin, 24);
}

.payment-pricing-container {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .pxToRem(margin-bottom, 44);
}
.payment-pricing_heading {
  color: #41434c;
  font-family: Inter;
  .pxToRem(font-size, 24);
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin: 32px 0 8px;
}

.payment-pricing_description {
  color: #41434c;
  font-family: Inter;
  .pxToRem(font-size, 12);
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  .pxToRem(margin-bottom, 44);
}

.payment-pricing_card_list {
  display: flex;
  .pxToRem(gap, 32);
}
.pricing-card {
  align-items: center;
  background: #fff;
  border: 1px solid #f3f3f3;
  .pxToRem(border-radius, 8);
  box-shadow: 0 2.56px 12px rgba(80,80,80, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.5rem;
  position: relative;
  .pxToRem(width, 248);
  box-sizing: border-box;
}

.pricing-card_popular {
  border: 2px solid #2e31be;
  &::before {
    content: "Most Popular";
    align-items: center;
    background: #e7eeff;
    color: #2e31be;
    .pxToRem(font-size, 10);
    border: 1.5px solid #2e31be;
    .pxToRem(border-radius, 4);
    display: flex;
    .pxToRem(height, 24);
    justify-content: center;
    position: absolute;
    text-transform: uppercase;
    .pxToRem(top, -12);
    .pxToRem(width, 100);
    height: 27px;
    box-sizing: border-box;
  }
}

.pricing-card_card_heading {
  color: #41434c;
  .pxToRem(font-size, 18);
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  .pxToRem(margin-bottom, 8);
  .pxToRem(margin-top, 32);
  text-align: center;
  text-transform: uppercase;
}

.pricing-card_card_tagline {
  color: #7a7a7a;
  .pxToRem(font-size, 12);
  line-height: 160%;
  .pxToRem(margin-bottom, 12);
}

.pricing-card_card_tagline,
.pricing-card_card_text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  text-align: center;
}

.pricing-card_card_text {
  color: #5c5c5c;
  .pxToRem(font-size, 11);
  line-height: 140%;
  &.plus {
    .pxToRem(margin-top, 8);
  }
}

.pricing-card_card_pricing {
  color: #41434c;
  font-family: Inter;
  .pxToRem(font-size, 24);
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.pricing-card_card_details_list {
  align-items: center;
  display: flex;
  flex-direction: column;
  .pxToRem(gap, 10);
  justify-content: center;
  margin: 24px 0;
}

.pricing-card_card_button {
  .pxToRem(margin-bottom, 24);
}




// .top-banner {
//   width: 100%;
//   height: 180px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #e7eefe;
//   color: #41434c;
//   padding-bottom: 100px;
//   .banner-title {
//     text-align: center;
//     font-size: 32px;
//     font-weight: 700;
//   }

//   .banne-desc {
//     margin-top: 10px;
//     font-size: 15px;
//     line-height: 20px;
//     max-width: 300px;
//   }
// }

// .plan-section {
//   display: flex;
//   justify-content: center;
//   padding: 24px;

//   .plan-data {
//     padding: 40px 24px 50px;
//     border-radius: 10px;
//     margin: 15px;
//     background: #fff;
//     width: 260px;
//     text-align: center;
//     margin-top: -110px;
//     box-shadow: 0 0 20px #f1f1f1;
//     display: flex;
//     flex-direction: column;

//     &.most-popular {
//       border: 2px solid var(--brand-color-accent-or-primary, #2E31BE);
//       .subscribe-btn {
//         background: var(--brand-color-accent-or-primary, #2E31BE);
//         color: white;
//       }
//     }

//     .subscribe-btn {
//       margin-top: auto;
//       align-self: center;
//     }

//     .plan-title {
//       width: 100%;
//       font-size: 25px;
//       font-weight: 400;
//       padding-bottom: 24px;
//     }

//     .plan-price-data {
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       .plan-price {
//         font-size: 30px;
//         font-weight: 700;
//       }

//       .plan-price-desc {
//         margin-left: 4px;
//         font-size: 12px;
//         font-weight: 500;
//         margin-top: 9px;
//       }
//     }

//     .plan-features {
//       padding: 20px 0px;
//       margin-bottom: 5px;

//       .feature-line {
//         text-align: center;
//         align-items: center;
//         padding: 14px 0 14px;

//         &:after {
//           content: "+";
//           position: relative;
//           bottom: -12px;
//         }

//         &:last-child:after {
//           display: none;
//         }
//       }
//     }
//   }
// }

// .active {
//   border: 2px solid #2e31be;

//   .current-plan {
//     border: 1px solid #2e31be;
//     border-radius: 4px;
//     position: absolute;
//     transform: translate(60%, -210%);
//     padding: 8px 15px 8px 15px;
//     background-color: #2e31be;
//     color: white;
//   }
// }
</style>
  
