<template>
    <div class="back-in-stock-products">
        <div class="head">
            <div>
                <h6>Back in Stock Subscribers</h6>
                <p>
                    List of subscribers against each product and their specific size
                </p>
            </div>
            <div class="head-right">
                <NitrozenButton theme="secondary" @click="openScheduleDialog">
                    <img :src="require('../../assets/svgs/calender_icon.svg')" alt="export icon" />
                    Schedule Daily Report
                </NitrozenButton>
                <div class="schedule-dialog">
                    <NitrozenDialog title="Get Daily Subscriber's Report" ref="dialog">
                        <template slot="body">
                            <div class="dialog-body">
                                <div>
                                    <NitrozenInput label="Enter Email to Get The Report" placeholder="Email"
                                        :value="dailyReportSettings.email" :showTooltip="true"
                                        tooltipText="At what time in a day should be mail sent" type="email"
                                        @input="onChangeReportEmail" ref="dailyReportEmail" />
                                    <NitrozenError v-if="showReportEmailError">
                                        Enter Valid Email
                                    </NitrozenError>
                                </div>
                                <NitrozenDropdown label="Send Email At" placeholder="Select Time"
                                    tooltip="Report will be sent to this mail everyday" :items="timesOfDay"
                                    v-model="dailyReportSettings.currentReportTime" />
                                <NitrozenCheckBox class="dialog-checkbox" v-model="dailyReportSettings.enabled">I want to
                                    receive
                                    daily subscriber's
                                    report</NitrozenCheckBox>
                            </div>
                        </template>
                        <template slot="footer">
                            <NitrozenButton v-flatBtn size="small" theme="secondary" @click="saveSettings"> Save
                            </NitrozenButton>
                        </template>
                    </NitrozenDialog>
                </div>
            </div>
        </div>
        <div class="table">
            <div v-if="products && products.length">
                <div class="products-table">
                    <div class="product-row table-header table-bg table-grid">
                        <sh3>Product</sh3>
                        <sh3>Size</sh3>
                        <sh3>Subscribers</sh3>
                        <sh3>Last Email Sent</sh3>
                        <sh3>Last Customer Added</sh3>
                        <sh3>Status</sh3>
                        <sh3>Action</sh3>
                    </div>
                    <ul>
                        <li v-for="product  in products" :key="product.name">
                            <div class="product-row table-grid">
                                <div class="product-title">
                                    <p><img class="product-icon" :src=product.media[0]?.url alt="image" /></p>
                                    <p class="text-color text-color">{{ product.name }}</p>
                                </div>
                                <p>{{ product.size }}</p>
                                <p>{{ product.subscriber_count | formatCompactNumber }} </p>
                                <p>{{ product.last_email_sent ? getISTDate(product.last_email_sent) : '-' }}</p>
                                <p>{{ product.last_customer_added ? getISTDate(product.last_customer_added) : '-' }}</p>
                                <div>
                                    <div class=" status" :class="product.in_stock ? 'active' : 'inactive'">{{
                                        product.in_stock ?
                                        'RESTOCK' : 'OUT OF STOCK' }}</div>
                                </div>
                                <nitrozen-button class="nit-button" v-flatBtn size="small" @click="openModal(product)"
                                    theme="secondary">View</nitrozen-button>
                            </div>
                        </li>
                    </ul>
                </div>
                <nitrozen-pagination class="pagination" v-model="paginationConfig" name="Products"
                    @change="getBackInStockProducts" :pageSizeOptions="pageSizeOptions"> </nitrozen-pagination>
                <div v-if="showModal">
                    <back-in-stock-waitlist :product="selectedProduct" :closeModal="closeModal" />
                </div>
            </div>
            <empty-state v-else text="No Back in stock subscriber found" />
        </div>
    </div>
</template>
<script>
import { formatUTCtoISTDate } from '../../helper/utils';
import MainService from '../../services/main-service';
import {
    NitrozenButton,
    NitrozenCheckBox,
    NitrozenDialog,
    NitrozenDropdown,
    NitrozenError,
    NitrozenInput,
    NitrozenPagination,
    flatBtn
} from "@gofynd/nitrozen-vue";
import backInStockWaitlist from './molecules/back-in-stock-waitlist.vue';
import EmptyState from './molecules/empty-state.vue';
import { generateTimesOfDay } from '../../helper/utils';

export default {
    name: "back-in-stock-products",
    components: {
        NitrozenButton,
        NitrozenPagination,
        backInStockWaitlist,
        EmptyState,
        NitrozenDialog,
        NitrozenInput,
        NitrozenDropdown,
        NitrozenCheckBox,
        NitrozenError
    },
    directives: {
        flatBtn
    },
    data() {
        return {
            products: [],
            paginationConfig: {
                limit: 5,
                current: 0,
                total: 0,
            },
            pageSizeOptions: [5, 10, 15, 20],
            selectedProduct: null,
            showModal: false,
            timesOfDay: generateTimesOfDay(),
            dailyReportSettings: {
                enabled: false,
                email: '',
                currentReportTime: {}
            },
            showReportEmailError: false,
        }
    },
    methods: {
        async getBackInStockProducts() {
            const { data } = await MainService.getBackInStockProducts({
                pageSize: this.paginationConfig.limit,
                pageNo: this.paginationConfig.current
            })

            this.paginationConfig.current = parseInt(data.page);
            this.paginationConfig.total = parseInt(data.total);
            this.products = data.docs;
        },
        getISTDate(date) {
            return formatUTCtoISTDate(date)
        },
        openModal(product) {
            this.selectedProduct = product;
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        async getDailyReportSettings() {
            try {
                const params = {
                    company_id: this.$route.params.company_id,
                    application_id: this.$route.params.application_id,
                };
                const res = await MainService.postApplication(params);
                if (!res.data.length) {
                    return;
                }
                const data = res.data[0];

                this.dailyReportSettings.enabled = data.daily_report?.enabled ?? false;
                this.dailyReportSettings.email = data.daily_report?.email ?? '';
                this.dailyReportSettings.currentReportTime = this.timesOfDay.find(time => time.value.hours == data.daily_report.send_email_at?.hours && time.value.mins == data.daily_report.send_email_at?.mins)?.value;
            } catch (e) {
                console.error(e);
                this.$snackbar.global.showError("Error Getting the Daily Report Settings");
            }
        },
        async openScheduleDialog() {
            await this.getDailyReportSettings();
            this.$refs["dialog"].open({
                showCloseButton: true,
                dismissible: false,
                neutralButtonLabel: false
            });
        },
        onChangeReportEmail(value) {
            if (!value.trim() || !this.$refs.dailyReportEmail?.$el.querySelector('input').checkValidity()) {
                this.showReportEmailError = true;
            } else {
                this.showReportEmailError = false;
                this.dailyReportSettings.email = value;
            }
        },
        async saveSettings() {
            if (!this.dailyReportSettings?.email.trim() || this.showReportEmailError) {
                this.$snackbar.global.showError("Enter valid Email");
                return;
            }
            try {
                const apiData = {
                    daily_report: {
                        enabled: this.dailyReportSettings.enabled,
                        email: this.dailyReportSettings.email,
                        send_email_at: this.dailyReportSettings.currentReportTime
                    },
                    application_id: this.$route.params.application_id,
                    company_id: this.$route.params.company_id,
                };
                const res = await MainService.patchApplicationSettings(apiData);
                if (res) {
                    this.$snackbar.global.showSuccess("Saved successfully");
                    this.$refs['dialog'].close();
                }
            } catch (e) {
                this.$snackbar.global.showError("Error while saving data");
            }
        }
    },
    async mounted() {
        await this.getBackInStockProducts();
    },
}
</script>
<style scoped lang="less">
@import 'src/helper/utils';
@import '../../helper/colors.less';

.back-in-stock-products {
    border: 1px solid #E5E5E5;
    background-color: #fff;
    .pxToRem(padding, 24);
    .pxToRem(border-radius, 12);
    grid-column: 1/span 2;
    display: grid;
    .pxToRem(gap, 24);

    .table {
        position: relative;
        min-height: 320px;
        display: grid;
        place-items: stretch;
        align-self: stretch;
    }

    .nit-button {
        background: transparent;
        color: @blue-primary;
        border: 1px solid @blue-primary;
        .pxToRem(border-radius, 4);
        .pxToRem(font-size, 12);
        line-height: 160%;
        padding: 6px 12px;
    }

    .head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h6 {
            color: #41434C;
            font-family: Inter;
            .pxToRem(font-size, 18);
            font-style: normal;
            font-weight: 600;
            .pxToRem(line-height, 24);
        }

        p {
            color: rgba(102, 102, 102, 0.80);
            font-family: Inter;
            .pxToRem(font-size, 14);
            font-style: normal;
            font-weight: 400;
            .pxToRem(line-height, 17);
        }

        .head-right {
            img {
                .pxToRem(width, 24);
                .pxToRem(height, 24);
            }

            .pxToRem(font-size, 24);

            .schedule-dialog .nitrozen-dialog .nitrozen-dialog-body {
                overflow: hidden !important;
            }

            ::v-deep .nitrozen-dialog {
                .pxToRem(border-radius, 12) !important;
            }

            ::v-deep .nitrozen-dialog-body {
                overflow: unset !important;
            }

            ::v-deep .nitrozen-dialog-footer-button-margin {
                margin-right: 0px !important;
            }

            ::v-deep .n-button {
                width: 100%;
            }


            .dialog-body {
                display: flex;
                flex-direction: column;
                .pxToRem(gap, 12);
            }

        }
    }

    .products-table {
        border: 1px solid #e0e0e0;
        .pxToRem(border-radius, 4);

        .table-grid {
            display: grid;
            grid-template-columns: 1.5fr 1fr 1fr 1.2fr 1.2fr 1fr .5fr;
            .pxToRem(column-gap, 16);
            align-items: center;
        }

        .table-header {
            sh3 {
                color: #41434C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 600;
                .pxToRem(line-height, 18.2);
            }
        }

        .product-row {
            border-bottom: 1px solid #e0e0e0;
            .pxToRem(padding, 16);

            p {
                color: #5C5C5C;
                font-family: Inter;
                .pxToRem(font-size, 14);
                font-style: normal;
                font-weight: 400;
                .pxToRem(line-height, 18.2);
            }

            .product-title {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                .pxToRem(gap, 12);

                .product-icon {
                    .pxToRem(height, 40);
                }
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .status {
                border-radius: 2px;
                padding: 1px 4px;
                width: fit-content;
            }

            .active {
                border: 1px solid @success;
                color: @success;
            }

            .inactive {
                border: 1px solid @error;
                color: @error;
            }

        }

        .table-bg {
            background: #f6f6f6;
        }
    }

    .pagination {
        .pxToRem(margin-top, 16);
    }

}
</style>
