import Vue from "vue";

let company_id = null;
let application_id = null;
export const transformRequestOptions = (params) => {
  let options = "";
  for (const key in params) {
    if (typeof params[key] !== "object" && params[key]) {
      const encodeVal = encodeURIComponent(params[key]);
      options += `${key}=${encodeVal}&`;
    } else if (Array.isArray(params[key])) {
      params[key].forEach((el) => {
        const encodeVal = encodeURIComponent(el);
        options += `${key}=${encodeVal}&`;
      });
    } else if (typeof params[key] === "object" && params[key]) {
      options += transformRequestOptions(params[key]);
    }
  }
  return options ? options.slice(0, -1) : options;
};
export const getCompanyBasePath = (route) => {
  return `/company/${route.params.company_id || getCompany()}`;
};
export const setCompany = (companyId) => {
  company_id = companyId;
};
export const setApplication = (applicationId) => {
  application_id = applicationId;
};

export const getCompany = () => {
  return company_id;
};

export const getApplication = () => {
  return application_id;
};

/**
 * Converts js date object to date string
 * @param {Date} date
 * @returns {string} in the format '2023-12-23'
 * @example
 * const todayDateString = getFormattedDate(new Date());
 */
export const getFormattedDate = (date) => {
  // @ts-check
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();
  const year = date.getFullYear();
  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  return [year, month, day].join("-");
};

export const formatUTCtoISTDate = (UTCDate) => {
  const date = new Date(UTCDate);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };

  const formattedDate = date.toLocaleString("en-US", options).replaceAll(',', '');
  return formattedDate;
};

Vue.filter("formatCompactNumber", (value) => {
  // @ts-check
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(value);
});

export const downloadCSVData = (csvHead, data, csvName) => {
  let csv = `${csvHead}\n`;
  data.forEach((row) => {
    csv += row.join(",");
    csv += "\n";
  });

  const anchor = document.createElement("a");
  anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
  anchor.target = "_blank";
  anchor.download = `${csvName}.csv`;
  anchor.click();
};

export function generateHoursOfDay() {
  return Array(24)
          .fill()
          .map((item, idx) =>({
              text: `${idx}:00`,
              value: { hours: idx, mins: 0 } 
          })
  )
}
export const generateTimesOfDay = () => {
  return Array(24).fill(0).map((item, idx) =>
    ({ text: `${idx}:00`, value: { hours: idx, mins: 0 } })
  )
};

