<template>
    <div class="dropdown-modal" @click="openModal">
        <NitrozenDropdown :label=inputField.label :placeholder="value" :value="value" :tooltip=inputField.toolTip
            :disabled="disabled" />
        <ExcludeProductsModal v-if="showModal" :closeModal="closeModal" :values="inputField.value"
            :getItems="inputField.getItems" :onSave="inputField.onChange" />
    </div>
</template>
<script>
import { NitrozenDropdown } from '@gofynd/nitrozen-vue';
import ExcludeProductsModal from './exclude-products-modal.vue';

export default {
    name: "dropdown-modal",
    components: {
        NitrozenDropdown,
        ExcludeProductsModal
    },
    props: {
        inputField: {
            type: {},
            required: true,
        },
        disabled: {
            type: Boolean
        },
    },
    computed: {
        value() {
            return !this.inputField?.value?.length ? 'Select Products' : `${this.inputField.value.length} Selected`;
        }
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        openModal() {
            if (!this.disabled)
                this.showModal = true
        },
        closeModal(event) {
            event.stopPropagation();
            this.showModal = false;
        },
    },
}
</script>
<style scoped lang="less">
.dropdown-modal {
    width: 100%;
    height: 100%;
}
</style>