<template>
    <div class="email-error">
        <img :src="errorImg" alt="Error" />
        <p>Please Enter the Correct Email</p>
    </div>
</template>
<script>
import errorImg from '../../assets/svgs/error_validator.svg';

export default {
    name: "email-error",
    computed: {
        errorImg() {
            return errorImg;
        }
    },
}
</script>
<style scoped lang="less">
@import '../../helper/colors.less';
@import '../../helper/utils.less';

.email-error {
    display: flex;
    .pxToRem(margin-top, 4);
    align-items: center;


    img {
        .pxToRem(height, 14);
        .pxToRem(width, 14);
    }

    p {
        .pxToRem(margin-left, 7);
        color: @error;
        font-family: Inter;
        .pxToRem(font-size, 12);
        font-style: normal;
        font-weight: 400;
        .pxToRem(line-height, 17);
    }
}
</style>