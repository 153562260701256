<template>
  <div class="button-main">
    <form class="button-customization"
      name="button-customization"
      @submit.prevent="save()">
      <div class="group">
        <h5>Sender</h5>
        <div class="sender-card">
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="17" r="17" fill="#E7EEFF"/>
            <path d="M16.9999 11.667C18.0388 11.667 18.8888 12.517 18.8888 13.5559C18.8888 14.5947 18.0388 15.4447 16.9999 15.4447C15.961 15.4447 15.111 14.5947 15.111 13.5559C15.111 12.517 15.961 11.667 16.9999 11.667ZM16.9999 20.167C19.5499 20.167 22.4777 21.3853 22.6666 22.0559V23.0003H11.3332V22.0653C11.5221 21.3853 14.4499 20.167 16.9999 20.167ZM16.9999 9.77808C14.9127 9.77808 13.2221 11.4686 13.2221 13.5559C13.2221 15.6431 14.9127 17.3336 16.9999 17.3336C19.0871 17.3336 20.7777 15.6431 20.7777 13.5559C20.7777 11.4686 19.0871 9.77808 16.9999 9.77808ZM16.9999 18.2781C14.4782 18.2781 9.44434 19.5436 9.44434 22.0559V24.8892H24.5554V22.0559C24.5554 19.5436 19.5216 18.2781 16.9999 18.2781Z" fill="#2E31BE"/>
          </svg>
          <div>
            <div class="name">{{ selectedEmailProvider?.name ?? 'Default' }}</div>
            <div class="email">
              {{ selectedEmailProvider?.from_address?.[0]?.email?? 'hey@gofynd.com' }}
            </div>
          </div>
        </div>
        <NitrozenDropdown
            label="Choose Default Provider"
            placeholder="Select email provider..."
            id="email-provider"
            :items="emailProviders"
            v-model="selectedEmailProvider"
            @input="changeEmailProvider()"
            :disabled="saving">
        </NitrozenDropdown>
      </div>
      <div class="group">
        <h5>Message Customisation</h5>
        <div v-if="backStock" class="button-text styling">
          <div class="note">
            <nitrozen-input type="text"
              label="Subject"
              v-model="backStock.email_template.subject"
              @input="checkEmpty('email_template', 'subject')"
              @change="removeSpace('backStock', 'email_template', 'subject')"
              placeholder="Type subject text here"
              required></nitrozen-input>
            <nitrozen-error v-if="errors['subject']">Field is required</nitrozen-error>
          </div>
        </div>
        <div v-if="backStock" class="button-text styling">
          <p>Title</p>
          <nitrozen-input type="textarea" v-model="backStock.email_template.title"
            @change="removeSpace('backStock', 'email_template', 'title', '')" class="heading-width"
            placeholder="Type title text here">
          </nitrozen-input>
        </div>
        <div v-if="backStock" class="button-text styling">
          <p>Message</p>
          <div class="note">
            <nitrozen-input type="textarea" v-model="backStock.email_template.message"
              @change="removeSpace('backStock', 'email_template', 'message')" placeholder="Type message text here">
            </nitrozen-input>
            <span class="para">
              <strong>Note:</strong> Only following variable(s) are available
              <br> <strong>{{ productQuantity }}, {{ productName }}, {{ productSize }}</strong>
            </span>
          </div>
        </div>
        <div v-if="limitedStock" class="button-text styling">
          <!-- <p>Subject*</p> -->
          <div class="note">
            <nitrozen-input type="text"
              v-model="limitedStock.email_template.subject"
              label="Subject"
              @input="checkEmpty('email_template', 'subject')"
              @change="removeSpace('limitedStock', 'email_template', 'subject')"
              placeholder="Type subject text here"></nitrozen-input>
            <nitrozen-error v-if="errors['subject']">Field is required</nitrozen-error>
          </div>
        </div>
        <div v-if="limitedStock" class="button-text styling">
          <!-- <p>Title</p> -->
          <nitrozen-input
            type="textarea"
            label="Title"
            v-model="limitedStock.email_template.title"
            class="heading-width"
            @change="removeSpace('limitedStock', 'email_template', 'title')" placeholder="Type title text here">
          </nitrozen-input>
        </div>
        <div v-if="limitedStock" class="button-text styling">
          <!-- <p>Message</p> -->
          <div class="note">
            <nitrozen-input
              type="textarea"
              label="Message"
              v-model="limitedStock.email_template.message"
              @change="removeSpace('limitedStock', 'email_template', 'message')"
              placeholder="Type message text here">
            </nitrozen-input>
            <span class="para">
              <strong>Note:</strong> Only following variable(s) are available
              <br> <strong>{{ productQuantity }}, {{ productName }}, {{ productSize }}</strong>
            </span>
          </div>
        </div>
      </div>

      <div class="group">
        <h5 class="heading">Button Customisation</h5>
        <div class="button-text styling">
          <div class="note">
            <nitrozen-input v-if="limitedStock"
                      label="Button Text"
                      required
                      onkeypress="return /^[a-zA-Z ]*$/i.test(event.key)"
                      @input="checkbuttonEmpty('email_template', 'button_text')"
                      @change="removebuttonSpace('limitedStock', 'email_template', 'button_customization', 'button_text')"
                      v-model="limitedStock.email_template.button_customization.button_text"
                      placeholder="Type button text here" />

            <nitrozen-input v-if="backStock"
                            label="Button Text"
                            required
                            onkeypress="return /^[a-zA-Z ]*$/i.test(event.key)"
                            @input="checkbuttonEmpty('email_template', 'button_text')"
                            @change="removebuttonSpace('backStock', 'email_template', 'button_customization', 'button_text')"
                            v-model="backStock.email_template.button_customization.button_text"
                            placeholder="Type button text here" />

            <nitrozen-error v-if="errors['button_text']">Field is required</nitrozen-error>
          </div>
        </div>
  
        <div class="customization-footer">
          <div class="buttons">
            <nitrozen-button
              v-strokeBtn
              theme="secondary"
              class="nit-button"
              size="small"
              id="subscribe"
              @click="openDialog()">
              Send Test Email
            </nitrozen-button>
            <nitrozen-button v-strokeBtn theme="secondary" class="nit-button" size="small" @click="openModal">Edit
              EmailHTML</nitrozen-button>
            <HtmlEditor v-if="showModal" @closeModal="closeModal" :htmlData="htmlData" @getDefault="getDefault"
              @saveHtml="saveHtml" @change = "onChange"/>
          </div>
        </div>

      </div>

      <nitrozen-button
        type="submit"
        :disabled="saving"
        :showProgress="saving"
        v-flatBtn
        size="small"
        theme="secondary"
      >
        Save
      </nitrozen-button>
    </form>
    <NitrozenDialog title="Enter email to test" ref="dialog">
      <template slot="body">
        <div class="dialog-body">
          <nitrozen-input
            type="email"
            required
            label="Email"
            v-model="test_email"
            id="test_email"
          />
        </div>
      </template>
      <template slot="footer">
        <NitrozenButton type="button"
                        v-flatBtn
                        size="small"
                        theme="secondary"
                        :disabled="!test_email"
                        @click="sendTestEmail()">
          Send
        </NitrozenButton>
      </template>
    </NitrozenDialog>
  </div>
</template>

<script>
import inlineSvg from "@/components/common/inline-svg.vue";
import {
  NitrozenInput,
  NitrozenButton,
  strokeBtn,
  NitrozenError,
  NitrozenDialog,
  flatBtn,
  NitrozenDropdown
} from "@gofynd/nitrozen-vue";
import MainService from "../services/main-service";
import root from "window-or-global";
import HtmlEditor from './html-editor/html-editor.vue';

export default {
  name: 'email-template-customization',
  props: ["limitedStock", "backStock", "applicationSettings", "errors", "checkEmpty", "checkbuttonEmpty", "validateForm"],
  components: {
    "inline-svg": inlineSvg,
    NitrozenInput,
    NitrozenButton,
    NitrozenError,
    NitrozenDialog,
    NitrozenDropdown,

    HtmlEditor
  },
  directives: {
    strokeBtn,
    flatBtn
  },
  data() {
    return {
      switchlayout: false,
      selectedindex: 0,
      hidden: false,
      productQuantity: "{{product.quantity}}",
      productName: "{{product.name}}",
      productSize: "{{product.size}}",
      selectLayout: [
        {
          img: require("../assets/pngs/Group 31091.png"),
          title: "Vertical",
          para: "Upload graphics, and set background & text colours of various form elements and alerts",
        },
        {
          img: require("../assets/pngs/Group 31091.png"),
          title: "Horizontal",
          para: "Upload graphics, and set background & text colours of various form elements and alerts",
        },
      ],
      showModal: false,
      htmlData: this.backStock ?
        this.backStock.email_template.emailHtml
        : this.limitedStock.email_template.emailHtml,
      test_email: '',
      emailProviders: [],
      selectedEmailProvider: {}
    };
  },
  async mounted() {
    await this.fetchEmailProviders();
  },
  methods: {
    removeSpace(i, p, s) {
      this[i][p][s] = this[i][p][s].trim();
    },
    removebuttonSpace(i, p, s, o) {
      this[i][p][s][o] = this[i][p][s][o].trim();
    },
    openDialog() {
      this.$refs["dialog"].open({
          showCloseButton: true,
          dismissible: false,
          neutralButtonLabel: false
      });
    },
    async sendTestEmail() {
      let validForm = this.validateForm();
      if (!validForm) {
        this.$snackbar.global.showError("Please fill all fields");
        return;
      }
      if (this.limitedStock) {
        await this.sendEmail(
          this.limitedStock,
          "limited stock",
          this.test_email
        );
      } else {
        await this.sendEmail(this.backStock, "Back in stock", [
          this.test_email,
        ]);
      }
    },
    async sendEmail(data, email_type, email) {
      let emaildata = {
        email_type: email_type,
        quantity: 5,
        template_type: data,
        email: email,
        size: "Red",
        domains: "",
        application_id: this.$route.params.application_id,
        product: {
          data: {
            slug: "maybelline-new-york-color-sensational-creamy-matte-lipstick---612-cherry-chic-39g-vyquocckwkg",
            media: [{
              // Use a URL instead of require as EMAIL troubling with ngrok domain.
              url: "https://cdn.tirabeauty.com/v2/billowing-snowflake-434234/tira-p/wrkr/products/pictures/item/free/original/1113506/0f1VG-YTUu-6902395472636_1.jpg?dpr=1",
            }],
            brand: { name: 'Maybelline New York' },
            name: "Maybelline New York Color Sensational Creamy Matte Lipstick",
            description: 'Choose to look bold with the Maybelline New York Color Sensational Creamy Matte Lipstick - 612 Cherry Chic (3.9g',
            url: "https://www.tirabeauty.com/product/maybelline-new-york-color-sensational-creamy-matte-lipstick---612-cherry-chic-39g-vyquocckwkg",
          },
        }
      };
      try {
        let res = await MainService.test_email(emaildata);
        if (res) {
          this.$snackbar.global.showSuccess("Sent successfully");
          this.$refs["dialog"].close();
        }
      } catch (e) {
        this.$snackbar.global.showError("Something went wrong! please try again");
      }
    },
    onClickSettings() {
      this.$router.push(
        `/company/${this.$route.params.company_id}/${this.$route.params.application_id}/settings`
      );
    },
    swapLayout(i, layout) {
      this.selectedindex = i;
      this.onClickArrow();
      if (this.$route.name == "Limited Stock") {
        if (layout.title == "Horizontal") {
          this.limitedStock.email_template.layout = "Horizontal";
          this.switchlayout = true;
        } else if (layout.title == "Vertical") {
          this.limitedStock.email_template.layout = "Vertical";
          this.switchlayout = false;
        }
      } else {
        if (this.$route.name == "Back In Stock") {
          if (layout.title == "Horizontal") {
            this.backStock.email_template.layout = "Horizontal";
            this.backStock.email_template.emailHtml = emailHtml.BackInStock.Horizontal;
            this.switchlayout = true;
          } else if (layout.title == "Vertical") {
            this.backStock.email_template.layout = "Vertical";
            this.backStock.email_template.emailHtml = emailHtml.BackInStock.Vertical;
            this.switchlayout = false;
          }
        }
      }
      this.$root.$emit("switchHorizontalLayout", this.switchlayout);
    },
    onClickArrow() {
      this.hidden = !this.hidden;
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async getDefault() {
      const { data } = await (this.backStock ? MainService.getBackInStockDefaultEmail() : MainService.getLimitedStockDefaultEmail());
      this.htmlData = data.defaultEmailHtml;
      // If it should be shown in preview automatically, uncomment
      // this.saveHtml(this.htmlData);
    },
    saveHtml(newHtml) {
      this.$emit('saveHtml', newHtml);
    },
    onChange(newHtml){
      this.htmlData = newHtml;
    },
    save() {
      this.$emit('save')
    },
    async fetchEmailProviders() {
      const items = await MainService.getUnpaginatedEmailProviders()
      this.emailProviders = items.map(provider => ({
        value: provider,
        text: provider.name
      }));
      if (this.backStock) {
        if (this.backStock.email_template.emailProviderId) {
          this.selectedEmailProvider = items.find(provider => provider._id === this.backStock.email_template.emailProviderId)
        } else {
          this.selectedEmailProvider = items[0];
          this.backStock.email_template.emailProviderId = this.selectedEmailProvider._id;
        }
      } else if(this.limitedStock) {
        if (this.limitedStock.email_template.emailProviderId) {
          this.selectedEmailProvider = items.find(provider => provider._id === this.limitedStock.email_template.emailProviderId)
        } else {
          this.selectedEmailProvider = items[0];
          this.limitedStock.email_template.emailProviderId = this.selectedEmailProvider._id
        }
      }
    },
    changeEmailProvider() {
      if (this.backStock) {
        this.backStock.email_template.emailProviderId = this.selectedEmailProvider._id;
        return;
      } else if (this.limitedStock) {
        this.limitedStock.email_template.emailProviderId = this.selectedEmailProvider._id;
      }
    }
  },
  
  watch: {
    backStock: {
      handler: function () {
        this.htmlData = this.backStock.email_template.emailHtml
      },
      deep: true
    },
    limitedStock: {
      handler: function () {
        this.htmlData = this.limitedStock.email_template.emailHtml;
      },
      deep: true
    }
  }
};
</script>

<style lang='less' scoped>
@import '../helper/colors.less';
@import '../helper/utils.less';

.button-main {
  ::v-deep .nitrozen-dialog-footer .n-button {
    width: 100%;
  }

  ::v-deep .nitrozen-dialog-body .nitrozen-form-input {
    .pxToRem(width, 320);
  }
}

.group {
  display: grid;
  gap: 12px;
  margin-bottom: 20px;

}

.vertical-layout {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.select {
  background-color: red;
}

.vertical {
  display: flex;
  padding: 20px 15px 20px 15px;
  position: relative;
}

h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

h5 {
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.sender-card {
  display: flex;
  gap: 8px;
  padding: 10px 12px 10px 10px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #D4E2FF;
  background: #F6F9FF;

  .name {
    color: var(--accent-d-4, #2E2C27);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
  .email {
    color: var(--text-color-primary-and-secondary, #41434C);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}


p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #828282;
  padding-top: 5px;
}

.icon {
  width: 18%;
}

.rotate {
  transform: rotate(-90deg);
}

.layout {
  border: 1px solid #828282;
  border-radius: 4px;
  padding: 2px;
  background-color: #fff;
  width: 50px;
  height: 50px;
}

.content {
  width: 71%;
}

.arrow {
  width: 8%;
  transform: rotate(90deg);
  position: absolute;
  top: 32px;
  right: 10px;
}

.top-arrow {
  width: 8%;
  transform: rotate(270deg);
  position: absolute;
  top: 32px;
  right: 10px;
}

.note {

  .para {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #828282;
  }
}

// .title-width,
// .heading-width {
//   width: 65%;
// }

.styling p {
  width: 35%;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #828282;
}

.color {
  display: flex;
  justify-content: space-between;
}


.color-code {
  border: 1px solid #e0e0e0;
  padding: 9px;
  width: 100%;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #828282;
  }
}

.hash {
  border-right: 1px solid;
  padding-right: 8px;
  margin-right: 8px;
}

.input-width {
  margin-left: 10px;
  width: 20%;
  border-radius: 2px;
}

.input-color {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 48px;
}

.input-color::-webkit-color-swatch {
  border-radius: 2px;
  border: none;
}

.send-test-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  // margin-top: 25px;
}


.email-text {
  // .pxToRem(margin-top, 12);
  width: 100%;
}

.customization-footer {
  // .pxToRem(margin-top, 16);

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nit-button {
    background: transparent;
    color: @email-button;
    border: 1px solid @email-button;
    padding: 5.5px 12px;
    height: min-content;
    .pxToRem(border-radius, 4);
    color: @email-button;
    font-family: Inter;
    .pxToRem(font-size, 12);
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
}
</style>
