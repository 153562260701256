<template>
  <div id="app">
    <bread-crumb />
    <router-view />
  </div>
</template>

<script>
import Vue from "vue";
import EXT from "./extension_bridge";
import BreadCrumb from "./components/BreadCrumb.vue";
import { components } from "@gofynd/fdk-extension-bridge-javascript";
import MainService from "./services/main-service";
import store from "./store";

export default {
  components: {
    BreadCrumb,
  },
  name: "App",

  watch: {
    $route: {
      deep: true,
      handler() {
        EXT.destroy();
        this.extensionBridge();
      },
    },
  },

  methods: {
    extensionBridge() {
      let currentPlan = new components.ContextMenuItem(EXT, {
        label: "Plan",
      });
      currentPlan.subscribe("click", () => {
        this.$router.push(`/company/${this.$route.params.company_id}/pricing`);
      });
      currentPlan.dispatch();
    },
    getMonthlyEmailCount() {
      MainService
        .getMonthlyEmailCount()
        .then(({data}) => store.commit('setMonthlyEmailCount', data));
    }
  },

  mounted() {
    import("@gofynd/nitrozen-vue/src/components/NSnackbar").then(
      (NitrozenSnackbar) => {
        Vue.use(NitrozenSnackbar.default);
        Vue.snackbar.register("show", (message) => message, {
          position: "top-center",
          duration: 3000,
        });
        Vue.snackbar.register("showSuccess", (message) => message, {
          position: "top-center",
          duration: 3000,
          type: "success",
        });
        Vue.snackbar.register("showError", (message) => message, {
          position: "top-center",
          duration: 3000,
          type: "error",
        });
        Vue.snackbar.register("showWarning", (message) => message, {
          position: "top-center",
          duration: 3000,
          type: "warning",
        });
        Vue.snackbar.register("showInfo", (message) => message, {
          position: "top-center",
          duration: 3000,
          type: "info",
        });
      }
    );
  },

  created() {
    EXT.destroy();
    let context = new components.ContextMenuItem(EXT, {
      label: "Plan",
    });
    context.subscribe("click", () => {
      this.$router.push(`/company/${this.$route.params.company_id}/pricing`);
    });
    context.dispatch();
    this.getMonthlyEmailCount();
  },
};
</script>

<style lang="less">
@import url("./assets/css/style.css");
@import "./../node_modules/@gofynd/nitrozen-vue/dist/nitrozen.css";
@import './helper/utils.less';

.custom-input {
  label {
    color: #4F4F4F !important;
    font-family: Inter !important;
    .pxToRem(font-size, 12) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    .pxToRem(line-height, 19.2) !important;
  }
}
</style>
