<template>
    <div class="close" @click="close">
        <img :src="closeImg" />
    </div>
</template>
<script>
import close from '../../assets/svgs/close.svg';
export default {
    name: "close-img",
    computed: {
        closeImg() { return close }
    },
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>
<style scoped lang="less">
@import '../../helper/utils.less';

.close {
    position: absolute;
    .pxToRem(top, 25);
    .pxToRem(right, 20);
    cursor: pointer;
}
</style>