<template>
  <div class="main" >
    <div class="cards span2">
      <summary-card title="Total Customers Subscribed" :count=analytics.subscribers.total
        :percentChange=analytics.subscribers.change></summary-card>
      <summary-card title="Total Notification Email" :count=analytics.email.total :percentChange=analytics.email.change></summary-card>
      <summary-card title="Total Orders Placed" :count=analytics.customerConverted.total
        :percentChange=analytics.customerConverted.change></summary-card>
      <summary-card title="Total Revenue Collected" :count=analytics.revenue.total
        :percentChange=analytics.revenue.change></summary-card>
      <summary-card title="Total Out of Stock Products" :count=analytics.outOfStock.total
        :percentChange=analytics.outOfStock.change></summary-card>
    </div>
    <template v-if="freeSubscription">
      <div class="span2"
        v-if="freeEmailLimitReached">
        <nitrozen-alert2 type="error">
          <template v-slot:message>
            <div class="message">
              <div>
                You've reached your monthly email limit of 100.
                Your customers are no longer receiving emails.
              </div>
              Upgrade your plan to continue sending unlimited emails.
            </div>
          </template>
          <template v-slot:action>
            <button class="action" @click="upgradeToPro()">
              Upgrade to PRO
            </button>
          </template>
        </nitrozen-alert2>
      </div>
      <div v-else
        class="card subscription span2">
        <div class="subscription-left">
          <p>You have used {{ analytics.email.total }} out of 100 emails of this month</p>
          <div class="progress">
            <progress-bar :value="analytics.email.total" />
            <span>{{ analytics.email.total }}/100</span>
          </div>
        </div>
        <nitrozen-button
          @click="gotoPricingPage"
          v-flatBtn
          size="small"
          theme="secondary" >
          Upgrade To Pro
        </nitrozen-button>
      </div>
    </template>

    <!-- line chart -->
    <div class="card span2">
      <div class="line-chart">
        <div class="top">
          <h5>Revenue Generated</h5>
          <div class="input">
            <date-picker v-model="dateRange" :value="dateRange" type="date" valueType="format" range :clearable="false">
            </date-picker>
          </div>
        </div>
        <div class="chart">
          <div class="line-chart-bottom" v-show="dayWiseRevenue.labels.length">
            <canvas ref="myChart" width="400" height="100"> </canvas>
          </div>
          <EmptyState v-show="!dayWiseRevenue.labels.length" text="No Revenue Found" />
        </div>
      </div>
    </div>

    <!-- Most wanted Products-->
    <mostWantedProductsCard :mostWantedProducts=mostWantedProducts />

    <!-- Item Bought Vs Item Notified Pie Chart-->
    <items-bought-vs-notified />

    <!-- Limited Stock Products-->
    <limited-stock-products />

    <!-- Back In Stock Products-->
    <back-in-stock-products />

  </div>
</template>

<script>
import Chart from "chart.js/auto";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  NitrozenButton,
  flatBtn
} from "@gofynd/nitrozen-vue";
import summaryCard from "@/components/analytics/summary-card.vue";
import ProgressBar from "@/components/common/progress-bar.vue";
import mostWantedProductsCard from "../../components/analytics/most-wanted-products.vue";
import itemsBoughtVsNotified from "../../components/analytics/items-bought-vs-notified.vue"
import { getFormattedDate } from "@/helper/utils";
import MainService from "../../services/main-service";
import limitedStockProducts from "../../components/analytics/limited-stock-products.vue";
import backInStockProducts from "../../components/analytics/back-in-stock-products.vue";
import EmptyState from "../../components/analytics/molecules/empty-state.vue";
import store from "../../store";
import NitrozenAlert2 from "../../components/common/nitrozen-alert2.vue";


export default {
  name: 'display-chart',
  components: {
    NitrozenButton,
    DatePicker,
    summaryCard,
    ProgressBar,
    mostWantedProductsCard,
    itemsBoughtVsNotified,
    limitedStockProducts,
    backInStockProducts,
    EmptyState,
    NitrozenAlert2,
  },
  directives: {
    flatBtn
  },
  data() {
    return {
      dateRange: [],
      analytics: {
        subscribers: {
          change: 0,
          total: 0
        },
        email: {
          change: 0,
          total: 0
        },
        customerConverted: {
          change: 0,
          total: 0,
        },
        revenue: {
          change: 0,
          total: 0
        },
        outOfStock: {
          change: 0,
          total: 0
        }
      },
      dayWiseRevenue: { labels: [], chartData: [] },
      currentDate: null,
      chartInstance: null,
      mostWantedProducts: [],
      itemsBoughtNotified: {
        bought: null,
        notified: null,
      },
    };
  },
  watch: {
    '$route.query'(newQuery) {
      // @ts-check
      if (newQuery && newQuery.startDate && newQuery.endDate) {
        this.analyticsData(newQuery)
      }
    },
    /**
     * whenever date changes make api call with `newDateRange`
     * could not add anything like v-onChange on dateRange component
     */
    dateRange(newDateRange) {
      const newQuery = { startDate: newDateRange[0], endDate: newDateRange[1] }
      //Checking if the old query and new Query are same for resolving duplicate routing
      if (Array.isArray(newDateRange) && newDateRange.length == 2 && (this.$route.query.startDate != newQuery.startDate || this.$route.query.endDate != newQuery.endDate)) {
        this.$router.push({ query: newQuery });
      }
    }
  },
  computed: {
    subscription() {
      return store.getters.subscription
    },
    freeSubscription() {
      const subscription = store.getters.subscription;
      return subscription?.status !== 'active' || !subscription.platform_subscription_id
    },
    freeEmailLimitReached() {
      return store.getters.monthlyFreeEmailLimitReached
    }
  },
  async mounted() {
    this.dateRange = [
      this.$route.query.startDate || getFormattedDate(new Date(new Date() - (30 * 24 * 60 * 60 * 1000))),
      this.$route.query.endDate || getFormattedDate(new Date()),
    ];
    this.currentDate = new Date();

    // Get Analytics data And Store it in state
    await this.analyticsData({ startDate: this.dateRange[0], endDate: this.dateRange[1] });

    // Get Most Wanted Products
    await this.mostWantedProductsData();
  },

  methods: {

    gotoPricingPage() {
      this.$router.push(
        `/company/${this.$route.params.company_id}/pricing`
      );
    },
    lineChart() {
      /**
       * Incase of date range update first we destroy existing graph
       */
      if (this.chartInstance) this.chartInstance.destroy();

      const ctx = this.$refs.myChart;

      const customGradient = (context) => {
        const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, context.chart.height);
        gradient.addColorStop(0, "#E7EEFF");
        gradient.addColorStop(1, "rgba(231, 238, 255, 0.00)");
        return gradient;
      };

      this.chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.dayWiseRevenue.labels,
          datasets: [
            {
              fill: true,
              backgroundColor: customGradient,
              data: this.dayWiseRevenue.chartData,
              borderColor: "#2E31BE",
              borderWidth: 2,
              pointHoverBackgroundColor: "#2E31BE",
              pointHoverRadius: 5,
              pointRadius: 0
            },
          ],
        },
        options: {
          plugins: {
            legend: { display: false },
          },
          tension: 0.4,
          scales: {
            x: {
              grid: {
                borderDash: [10, 10], // Dashed vertical lines in the grid
              },
              ticks: {
                color: "#BDBDBD",
              },
            },
            y: {
              min: 0,
              grid: {
                display: false, // Hide horizontal lines in the grid
              },
              ticks: {
                color: "#BDBDBD",
              },
            },
          },
        },
      });
      this.chartInstance.render();
    },

    async analyticsData(dateRange) {
      const { data } = await MainService.getAnalyticsData(dateRange);

      [this.analytics.subscribers.change, this.analytics.subscribers.total] = this.getChangeAndTotal(data.subscriber.in_range, data.subscriber.till_start_date);
      [this.analytics.email.change, this.analytics.email.total] = this.getChangeAndTotal(data.email.in_range, data.email.till_start_date);
      [this.analytics.customerConverted.change, this.analytics.customerConverted.total] = this.getChangeAndTotal(data.customer_converted.in_range, data.customer_converted.till_start_date);
      [this.analytics.revenue.change, this.analytics.revenue.total] = this.getChangeAndTotal(data.revenue.in_range, data.revenue.till_start_date);
      [this.analytics.outOfStock.change, this.analytics.outOfStock.total] = this.getChangeAndTotal(data.out_of_stock.in_range, data.out_of_stock.till_start_date);

      //Line Chart Data
      let labels = [], chartData = [];
      data.day_wise_revenue.forEach(dayRevenue => {
        const date = new Date(dayRevenue.day);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        }).format(date);
        labels.push(formattedDate);
        chartData.push(dayRevenue.revenue_generated);
      });

      this.dayWiseRevenue = { labels: labels, chartData: chartData }
      this.lineChart()
    },

    getChangeAndTotal(in_range, till_start_date) {
      const change = parseInt(in_range) / parseInt(till_start_date);
      const total = parseInt(in_range) + parseInt(till_start_date);
      return [change, total]
    },

    async mostWantedProductsData() {
      const { data } = await MainService.getMostWantedProducts();
      if (data?.products) {
        this.mostWantedProducts = data.products;
      }
    }
  },
};
</script>

<style lang="less" scoped>
@import 'src/helper/utils';

.main {
  padding: 0 20px 20px 20px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .pxToRem(gap, 24);
  ::v-deep h6 {
    .pxToRem(margin-bottom, 6);
  }

  ::v-deep .products-table{
    li:last-child {
      .product-row  {
          border-bottom: none;
      }
    }
  }

  ::v-deep .status {
    .pxToRem(font-size, 12);
  }
}

.span2 {
  grid-column: 1 / span 2;
}

h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #41434c;
}

P {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 23px;
}

.line-chart {
  .chart {
    min-height: 320px;
    display: grid;
    place-items: stretch;
  }

  .top {
    // .pxToRem(padding-inline, 12);
    .pxToRem(margin-bottom, 24);
    display: flex;
    // align-items: center;
    color: #41434c;
    justify-content: space-between;
  }
}

.card {
  border: 1px solid #E5E5E5;
  background-color: #fff;
  .pxToRem(padding, 24);
  .pxToRem(border-radius, 12);

  &.subscription {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.subscription {
  .subscription-left{
    display: flex;
    .pxToRem(gap, 24);
  }
  .progress {
    display: flex;
    .pxToRem(gap, 12);
    align-items: center;
  }
}

.input {
  ::v-deep .mx-datepicker-range {
    width: 200px;
    .mx-input {
      box-shadow: none;
      border: 1px solid var(--colour-border, #E0E0E0);
      .pxToRem(border-radius, 4);
      .pxToRem(height, 40);
      background: #FFF;
      .pxToRem(font-size, 12);
      color: var(--Text-Color, #41434C);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      padding: 9px 15px;
    }
  }

}

.cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.customers-number {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #333333;
  margin-right: 8px;
}

// for csv
.csv-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.left h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  color: #41434c;
}

.left p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #666666;
}

.right {
  display: flex;
}

.products {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 25px;
}

.product-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px;
}

.w-16 {
  width: 16%;
}

.text-color {
  color: #666666;
}

.text-color-2 {
  color: #41434c;
}

.bold {
  font-weight: 600;
  color: #41434c;
}

.w-22 {
  width: 22%;
}

.w-8 {
  width: 8%;
}

.bg {
  background: #f6f6f6;
}

//for pie chart
.pie-chart {
  margin-top: 25px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}


.items-bought .most-wanted-products {
  h6 {
    margin-bottom: 8px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #41434c;
  }
}

.pie {
  width: 68%;
  margin: auto;
}
</style>
