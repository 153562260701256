import { render, staticRenderFns } from "./show-data-in-table-format.vue?vue&type=template&id=dece0f4e&scoped=true"
import script from "./show-data-in-table-format.vue?vue&type=script&lang=js"
export * from "./show-data-in-table-format.vue?vue&type=script&lang=js"
import style0 from "./show-data-in-table-format.vue?vue&type=style&index=0&id=dece0f4e&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dece0f4e",
  null
  
)

export default component.exports