<template>
    <form ref="settingsForm" class="settings-component">
        <loader v-if="loader"> </loader>
        <h3>Settings</h3>
        <h5>Set up appearance of limited stock and back in stock notification window for various pages of the website</h5>
        <div class="email-details">
            <nitrozen-input class="custom-input" label="Sender Email ID" :value="senderEmail" :showTooltip=true
                tooltipText="Sender Email" :disabled="true" type="email" />
            <div>
                <nitrozen-input ref="testEmailRef" class="custom-input" label="Send Test Email To" :value=testEmail
                    @input="onChangeTestEmail" :showTooltip=true tooltipText="Email for testing is sent to provided email"
                    type="email" />
                <EmailError v-if="showTestEmailError" />
            </div>
        </div>
        <div class="options">
            <card :data="limitedStockData" />
            <card :data="backInStockData" />
            <card :data="dailyReportData" ref="dailyReportEmail"/>
        </div>
        <NitrozenButton class="save-button" v-flatBtn size="small" theme="secondary" @click="saveSettings">Save
        </NitrozenButton>
    </form>
</template>
<script>
import { NitrozenInput, flatBtn } from '@gofynd/nitrozen-vue';
import Card from '../../components/settings/card.vue';
import MainService from '../../services/main-service';
import Loader from '../../components/common/loader.vue';
import EmailError from '../../components/molecules/email-error.vue';

export default {
    name: "settings-component",
    components: {
        NitrozenInput,
        Card,
        Loader,
        EmailError
    },
    directives: {
        flatBtn
    },
    data() {
        return {
            variableName: '',
            senderEmail: 'hey@gofynd.com',
            showTestEmailError: false,
            testEmail: '',
            generateTimesOfDay: [],
            limitedStockData: {},
            backInStockData: {},
            dailyReportData: {},
            loader: false,
        }
    },
    methods: {
        onChangeTestEmail(value) {
            if (this.$refs.testEmailRef?.$el.querySelector('input').checkValidity()) {
                this.showTestEmailError = false;
                this.testEmail = value
            } else {
                this.showTestEmailError = true;
            }
            this.testEmail = value
        },

        async postApplications() {
            const params = {
                company_id: this.$route.params.company_id,
                application_id: this.$route.params.application_id,
            };
            try {
                this.loader = true;
                let res = await MainService.postApplication(params);

                if (res.data.length) {
                    const data = res.data[0];

                    //test email
                    this.testEmail = data.test_email ?? '';

                    //limited Stock
                    let values = [];
                    this.limitedStockData.enabled = data.limited_stock.enabled;
                    if (data.limited_stock.pdp_pages) values.push('PDP');
                    this.limitedStockData.inputFields[0].value = values;
                    const currentTime = this.generateTimesOfDay.find(time => time.value.hours == data.limited_stock.send_email_at.hours && time.value.mins == data.limited_stock.send_email_at.mins);
                    this.limitedStockData.inputFields[1].value = currentTime?.value;
                    this.limitedStockData.inputFields[2].value = data.limited_stock.excluded_products;
                    this.limitedStockData.inputFields[3].value = data.limited_stock.quantity_threshold;

                    //Back in stock
                    values = [];
                    this.backInStockData.enabled = data.out_stock.enabled;
                    if (data.out_stock.pdp_pages) values.push('PDP');
                    this.backInStockData.inputFields[0].value = values;
                    this.backInStockData.inputFields[1].value = data.out_stock.min_quantity;
                    this.backInStockData.inputFields[2].value = data.out_stock.excluded_products;

                    //Daily Report 
                    this.dailyReportData.enabled = data.daily_report.enabled;
                    this.dailyReportData.inputFields[0].value = data.daily_report.email;
                    const currentReportTime = this.generateTimesOfDay.find(time => time.value.hours == data.limited_stock.send_email_at.hours && time.value.mins == data.limited_stock.send_email_at.mins);
                    this.dailyReportData.inputFields[1].value = currentReportTime?.value;
                }
            } catch (e) {
                this.$snackbar.global.showError("Error Loading");
            } finally {
                this.loader = false;
            }
        },

        //limited stock Methods
        onChangeToggleValue(value) {
            this.limitedStockData.enabled = value
        },
        onChangeFeaturePage(value) {
            this.limitedStockData.inputFields[0].value = value;
        },
        onChangeSendEmailAt(value) {
            this.limitedStockData.inputFields[1].value = value;
            console.log("this.limitedStockData", this.limitedStockData);
        },
        onChangeExcludeProducts(value) {
            this.limitedStockData.inputFields[2].value = value;
        },
        onChangeThreshold(value) {
            this.limitedStockData.inputFields[3].value = value;
        },

        //Back in Stock
        onChangeToggle(value) {
            this.backInStockData.enabled = value
        },
        onChangeFeature(value) {
            this.backInStockData.inputFields[0].value = value;
        },
        onChangeMinQuantity(value) {
            this.backInStockData.inputFields[1].value = value;
        },
        onChangeExcludeProduct(value) {
            this.backInStockData.inputFields[2].value = value;
        },

        //Daily Subscriber Report
        onChangeReportToggle(value) {
            this.dailyReportData.enabled = value;
        },
        onChangeReportEmail(value) {
            if (this.$refs.dailyReportEmail?.$el.querySelector('input[type=email]').checkValidity()) {
                this.dailyReportData.inputFields[0].showError = false;
                this.dailyReportData.inputFields[0].value = value
            } else if (this.dailyReportData.enabled) {
                this.dailyReportData.inputFields[0].showError = true;
            }
        },
        onChangeReportTime(value) {
            this.dailyReportData.inputFields[1].value = value;
        },

        convertData() {
            return {
                test_email: this.testEmail,
                limited_stock: {
                    enabled: this.limitedStockData.enabled,
                    pdp_pages: this.limitedStockData.inputFields[0].value.includes('PDP') ? true : false,
                    send_email_at: this.limitedStockData.inputFields[1].value,
                    excluded_products: this.limitedStockData.inputFields[2].value,
                    quantity_threshold: this.limitedStockData.inputFields[3].value,
                },
                out_stock: {
                    enabled: this.backInStockData.enabled,
                    pdp_pages: this.backInStockData.inputFields[0].value.includes('PDP') ? true : false,
                    min_quantity: this.backInStockData.inputFields[1].value,
                    excluded_products: this.backInStockData.inputFields[2].value,
                },
                daily_report: {
                    enabled: this.dailyReportData.enabled,
                    email: this.dailyReportData.inputFields[0].value,
                    send_email_at: this.dailyReportData.inputFields[1].value
                }
            }
        },
        //saving Data
        async saveSettings() {
            if (!this.$refs.settingsForm.checkValidity()) {
                this.$snackbar.global.showError("Enter Valid Data");
                return
            }

            //if daily email report is enabled and email is not entered then it should not be saved
            if(this.dailyReportData.enabled && (!this.dailyReportData.inputFields[0].value?.trim() || !this.$refs.dailyReportEmail?.$el.querySelector('input[type=email]').checkValidity())){
                this.$snackbar.global.showError("Enter Valid Email for Daily Report");
                return;
            }

            const apidata = this.convertData();
            apidata.application_id = this.$route.params.application_id;
            try {
                this.loader = true;
                let res = await MainService.patchApplicationSettings(apidata);
                if (res) {
                    this.$snackbar.global.showSuccess("Saved successfully");
                }
                this.loader = false;
            } catch (e) {
                this.loader = false;
            }
        }

    },
    async mounted() {
        this.generateTimesOfDay = Array(24).fill(0).map((item, idx) =>
            ({ text: `${idx}:00`, value: { hours: idx, mins: 0 } })
        )
        this.limitedStockData = {
            head: 'Limited Stock',
            toggleOnChange: this.onChangeToggleValue,
            enabled: true,
            description: "Set up the threshold value and select the pages on which you want the limited stock feature",
            inputFields: [
                {
                    label: 'Feature to appear?',
                    value: ['PDP'],
                    placeholder: 'Select Pages',
                    type: 'dropdown',
                    toolTip: 'On which pages is this feature required',
                    items: [{ text: 'PDP', value: 'PDP' }],
                    onChange: this.onChangeFeaturePage,
                    multiple: true,
                },
                {
                    label: 'Send Email At',
                    value: null,
                    placeholder: 'Select Time',
                    type: 'dropdown',
                    toolTip: 'At what time in a day should be mail sent',
                    items: this.generateTimesOfDay,
                    onChange: this.onChangeSendEmailAt
                },
                {
                    label: 'Exclude Specific Products',
                    value: [],
                    placeholder: 'Select Product',
                    type: 'modal-dropdown',
                    toolTip: 'Low stock wont be shown in excluded products',
                    onChange: this.onChangeExcludeProducts,
                    getItems: MainService.getProductsList
                },
                {
                    label: 'Quantity Threshold',
                    value: null,
                    placeholder: 'Enter Threshold',
                    type: 'Number',
                    toolTip: 'Low stock will be only shown if quantity goes less than this',
                    onChange: this.onChangeThreshold
                },
            ]
        };
        this.backInStockData = {
            head: 'Back In Stock',
            toggleOnChange: this.onChangeToggle,
            enabled: true,
            description: "Set up the threshold value and select the pages on which you want the back in stock feature",
            inputFields: [
                {
                    label: 'Feature to appear?',
                    value: ['PDP'],
                    placeholder: 'Select Pages',
                    type: 'dropdown',
                    toolTip: 'On which pages is this feature required',
                    items: [{ text: 'PDP', value: 'PDP' }],
                    onChange: this.onChangeFeature,
                    multiple: true,
                },
                {
                    label: 'Min. Quantity to Send Alerts',
                    value: null,
                    placeholder: 'Enter Threshold',
                    type: 'dropdown',
                    items: [{ text: '10', value: 10 }, { text: '50', value: 50 }, { text: '100', value: 100 }],
                    toolTip: 'If only these quantity is restocked emails will be triggered',
                    onChange: this.onChangeMinQuantity
                },
                {
                    label: 'Exclude Specific Products',
                    value: [],
                    placeholder: 'Select Product',
                    type: 'modal-dropdown',
                    toolTip: 'Low stock wont be shown in excluded products',
                    onChange: this.onChangeExcludeProduct,
                    getItems: MainService.getProductsList
                },
            ]
        };
        this.dailyReportData = {
            head: 'Send Daily Subscriber Report',
            toggleOnChange: this.onChangeReportToggle,
            enabled: true,
            description: "Enable daily email updates about subscribers at a specific time.",
            inputFields: [{
                label: 'Enter Email to Get The Report',
                value: '',
                placeholder: 'mydomainemail@gmail.com',
                type: 'email',
                toolTip: 'Report will be sent to this mail everyday',
                onChange: this.onChangeReportEmail,
                showError: false,
            },
            {
                label: 'Send Email At',
                value: null,
                placeholder: 'Select Time',
                type: 'dropdown',
                toolTip: 'At what time in a day should be mail sent',
                items: this.generateTimesOfDay,
                onChange: this.onChangeReportTime
            },
            ]
        }
        await this.postApplications();
    },
}
</script>
<style scoped lang="less">
@import '../../helper/colors.less';
@import '../../helper/utils.less';

.settings-component {
    .pxToRem(padding, 24);
    margin: 1.5rem;
    background: white;
    .pxToRem(border-radius, 12);
    height: 82vh;
    overflow: overlay;

    h3 {
        color: @textPrimary;
        font-family: Inter;
        .pxToRem(font-size, 18);
        font-style: normal;
        font-weight: 600;
        .pxToRem(line-height, 24);
    }

    h5 {
        color: rgba(102, 102, 102, 0.80);
        ;
        font-family: Inter;
        .pxToRem(font-size, 14);
        font-style: normal;
        font-weight: 400;
        .pxToRem(line-height, 17);
    }

    h6 {
        color: var(--Gray-2, #4F4F4F);
        font-family: Inter;
        .pxToRem(font-size, 12);
        font-style: normal;
        font-weight: 400;
        .pxToRem(line-height, 19.2);
    }

    .email-details {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .pxToRem(gap, 16);
        .pxToRem(margin-top, 24);

        // .custom-input {
        //     width: 50%;
        // }
    }

    .options {
        .pxToRem(margin-top, 16);
        display: grid;
        grid-template-columns: 1fr 1fr;
        .pxToRem(column-gap, 16);
        .pxToRem(row-gap, 16);
        margin-bottom: 1.5rem;
    }

    .save-button {
        background: #2E31BE;
        color: white;
        border: 1px solid #2E31BE;
        .pxToRem(border-radius, 4);
        padding: 10px 30px;
        cursor: pointer;
    }

    .disable-btn {
        opacity: 0.5;
    }

}
</style>