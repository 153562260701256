import { render, staticRenderFns } from "./limited-stock.vue?vue&type=template&id=162f78bd&scoped=true"
import script from "./limited-stock.vue?vue&type=script&lang=js"
export * from "./limited-stock.vue?vue&type=script&lang=js"
import style0 from "./limited-stock.vue?vue&type=style&index=0&id=162f78bd&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "162f78bd",
  null
  
)

export default component.exports